// @flow

import React, { useEffect, useState } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { Animation } from '../Components/index'
import { messages } from '../i18n/messages/index'

import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/NativeText'
import { useLocation } from 'react-router-dom'

const changeEmailPasswordSuccessMessages = defineMessages({
  emailSuccess: {
    id: 'changeEmailPassword.emailSuccess',
    defaultMessage: 'Email changed successfully'
  },
  passwordSuccess: {
    id: 'changeEmailPassword.passwordSuccess',
    defaultMessage: 'Password changed successfully'
  }
})

const TextContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
`
const AnimationContainer: StyledComponent<{}> = styled.div`
  width: 100%;
  z-index: 3;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 20px;
  margin-top: 60px;
`

// add for testing purpose
TextContainer.displayName = 'TextContainer'
AnimationContainer.displayName = 'AnimationContainer'

function ChangeEmailPasswordSuccess ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const location = useLocation()

  const [callbackUrl, setCallbackUrl] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    const callbackUrl = location.state.callbackUrl
    const type = location.state.type

    setCallbackUrl(callbackUrl)
    setType(type)
    // eslint-disable-next-line
  }, [])

  const redirect = () => {
    if (callbackUrl) window.location.href = callbackUrl
  }

  const getHeaderMessage = () => {
    let message
    if (type === 'email') {
      message = formatMessage(messages.changeEmail)
    } else if (type === 'password') {
      message = formatMessage(messages.changePassword)
    }
    return (
      <HeaderContainer>
        <Text align='center' textSize='h1' fontColor={color.lightDefaultTheme} text={message} />
      </HeaderContainer>
    )
  }

  const getSuccessMessage = () => {
    let message
    if (type === 'email') {
      message = formatMessage(changeEmailPasswordSuccessMessages.emailSuccess)
    } else if (type === 'password') {
      message = formatMessage(changeEmailPasswordSuccessMessages.passwordSuccess)
    }
    return (
      <TextContainer>
        <Text align='center' textSize='h2' fontColor={color.success} text={message} />
      </TextContainer>
    )
  }

  const headerMessage = getHeaderMessage()
  const successMessage = getSuccessMessage()
  return (
    <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        {headerMessage}
        <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
          <Column classNames={['is-paddingless', 'is-one-fifth', 'is-centered']}>
            <AnimationContainer>
              {/* eslint-disable-next-line react/jsx-handler-names */}
              <Animation animationType='success' onAnimationFinish={redirect} />
            </AnimationContainer>
          </Column>
        </Row>
        {successMessage}
      </Column>
    </Row>
  )
}

export default (injectIntl(ChangeEmailPasswordSuccess))
export { ChangeEmailPasswordSuccess as UnwrappedChangeEmailSuccess }
