// @flow
import { includes } from 'lodash'
import { env, whiteListDomains, genericSubdomain } from './config'
// To address those who want the "root domain," use this function:
function extractHostname (callbackUrl) {
  let hostname
  // find & remove protocol (http, ftp, etc.) and get hostname
  if (callbackUrl.indexOf('//') > -1) {
    hostname = callbackUrl.split('/')[2]
  } else {
    hostname = callbackUrl.split('/')[0]
  }
  // find & remove port number
  hostname = hostname.split(':')[0]
  // find & remove "?"
  hostname = hostname.split('?')[0]
  return hostname
}
export default function validateDomain (callbackUrl: string, subdomain: ?string) {
  // if it's development, don't validate
  if (env === 'dev' || env === 'development' || env === 'test') return true
  let domain = extractHostname(callbackUrl)
  const splitArr = domain.split('.')
  const arrLen = splitArr.length
  /**
   * Grab subdomain
   * Check for beta env
   */
  // const betaEnv = splitArr[1] === 'beta' ? ('.' + splitArr[1]) : ''
  // const callbackSudomain = splitArr[0] + betaEnv
  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1]
  }
  /**
   * This will cover our mobile case
   * ${subdomain}'://home Android Extremis
   * ${subdomain}:// IOS Extremis
   */
  let whiteListMobileArray = []
  if (subdomain) {
    whiteListMobileArray = [
      `${genericSubdomain}://home`,
      `${genericSubdomain}://`,
      `${subdomain}://home`,
      `${subdomain}://`
    ]
  }
  let isWhiteListedUrl = false
  if (includes(callbackUrl, 'https')) {
    /**
     * As long as the subdomains match then check domain
     */
    isWhiteListedUrl = includes(whiteListDomains, domain)
  } else {
    const mobileAppCallbackUrlSubdomain = callbackUrl.split(':')[0]
    if (mobileAppCallbackUrlSubdomain === genericSubdomain) isWhiteListedUrl = true
    else if (mobileAppCallbackUrlSubdomain === subdomain) isWhiteListedUrl = includes(whiteListMobileArray, callbackUrl)
  }
  return isWhiteListedUrl
}
