// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/Components/Text'

const Container: StyledComponent<{}> = styled.div`
  text-align: center;
  padding: 20px;
  margin-top: 80px;
  width: 100%;
  height: 100%;
  background-color: white;
`
const CenterContainer: StyledComponent<{}> = styled.div`
  transform:scale(0.7);
  -webkit-transform:scale(0.7);
  transform-origin: center;
  -webkit-transform-origin:center;
  display: flex;
  justify-content: center;
`
class ErrorPage extends Component<{}> {
  render () {
    return (
      <Container>
        <CenterContainer>
          <Icon iconName='warningCircle' size={200} color={color.grey600} />
        </CenterContainer>
        <Text align='center' textSize='h1' text='App is no longer available.' fontColor={color.grey800} />
        <Text align='center' textSize='h1' text='If you think this is incorrect, please contact your system administrator.' fontColor={color.grey800} />
      </Container>
    )
  }
}

export default ErrorPage
