// @flow

// privacy policy
import ar from './ar.json'
import bg from './bg.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import hu from './hu.json'
import ja from './ja.json'
import ms from './ms.json'
import pl from './pl.json'
import ptBR from './pt-br.json'
import pt from './pt.json'
import zhCN from './zh-cn.json'
import zhTW from './zh-tw.json'

export default {
  ar,
  bg,
  de,
  en,
  es,
  fr,
  hu,
  ja,
  ms,
  pl,
  pt,
  'pt-br': ptBR,
  'pt-pt': pt,
  'zh-cn': zhCN,
  'zh-tw': zhTW
}
