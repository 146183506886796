import { isEmpty, isString } from 'lodash'
import { higginsDomain, luciusDomain, env } from './utils/config'
import moment from 'moment'

export default function registerIFrameListener () {
  window.onmessage = function (e) {
    if (!e || isEmpty(e) || isEmpty(e.data) || !isString(e.data)) {
      return
    }
    let access = window.localStorage.getItem('access')
    if (access) {
      access = JSON.parse(access)
      if (access.subdomain !== e.data) {
        access = {}
      } else {
        // check to see if the access is expired
        const createdMoment = moment(access.created)
        const nowMoment = moment(Date.now())
        if (nowMoment.diff(createdMoment, 'hours') >= 4) {
          // if the created time of the token is longer than the expiration time of the token (4 hours), do not give it back to the client and remove it from storage
          access = {}
          window.localStorage.removeItem('access')
        }
      }
    } else {
      access = {}
    }
    // only allow the message to be posted to higgins and lucius application
    if (env === 'development' || env === 'dev') window.parent.postMessage(access, '*')
    else {
      window.parent.postMessage(access, 'https://' + e.data + '.' + higginsDomain)
      window.parent.postMessage(access, 'https://' + luciusDomain)
      window.parent.postMessage(access, 'https://theemployeeappdev.sharepoint.com')
    }
  }
}
