// @flow
import React, { useReducer, useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import Icon from 'michelangelo/dist/Components/NativeIcon'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Input from 'michelangelo/dist/SharedComponents/Inputs/Input'
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import type { InjectIntlProvidedProps } from 'react-intl'
import { messages } from './i18n/messages'
import styled, { StyledComponent } from 'styled-components'
import { RequirementSteps, Animation, NewButton } from './Components'
import { useNavigate } from 'react-router-dom'
import * as Actions from './AlfredApp/actions'
import isEmail from 'validator/lib/isEmail'
import { CHANGE_PASSWORD_ERROR_RESET } from './AlfredApp/actions'

const TextIcon: StyledComponent<{}> = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`
const ErrorMsg: StyledComponent<{}> = styled.div`
  background-color: ${color.danger}20;
  border: 1px solid ${color.danger};
  border-radius: 6px;
  color: ${color.danger};
  padding: 8px;
  margin-bottom: 8px;
`
const CustomText: StyledComponent<{}> = styled.h5`
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.fontcolor};
`
const CustomTextLink: StyledComponent<{}> = styled.span`
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.fontcolor};
  cursor: pointer;
`
const PaddingSpace: StyledComponent<{}> = styled.span`
  padding-left: 3px;
`

const ButtonSpacing: StyledComponent<{}> = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  cursor: pointer
`
const AnimationContainer: StyledComponent<{}> = styled.div`
  z-index: 3;
`
const FormItemContainer: StyledComponent<{}> = styled.div`
  padding: 32px 0px 10px 0px;
  input {
    cursor: pointer !important;
  }
  `
const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: 10px;
  padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  padding: 24px 24px 0px 24px;
`
const CloseIconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  @media (max-width: 370px) {
    margin-right: 8px;
    margin-top: 8px;
  }
`
FormItemContainer.displayName = 'FormItemContainer'
RequirementSteps.displayName = 'RequirementSteps'
NewButton.displayName = 'NewButton'

function UserData ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const [passwordType, setPasswordType] = useState('password')
  const [displayValidator, setDisplayValidator] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(true)
  const [errors, setErrors] = useState([])
  const [isCheckingPassword, setIsCheckingPassword] = useState(false)
  const userRedux = useSelector(state => state.user)
  let fName = ''
  let lName = ''
  if (userRedux.userName && userRedux.userName.length > 0) {
    const fullNameArray = userRedux.userName.replace(/\s+/g, ' ').trim().split(' ')
    fName = fullNameArray[0] || ''
    lName = fullNameArray[1] || ''
  }
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: userRedux.userEmail,
      firstName: fName,
      lastName: lName,
      password: ''
    }
  )
  const { email, firstName, lastName, password } = userInput
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const accountDetails = useSelector(state => state.account.account)
  const subdomain = useSelector(state => state.account.subdomain)
  const userUpdated = useSelector(state => state.user.userUpdated)
  const requirePasswordUpdate = useSelector(state => state.user.requirePasswordUpdate)
  const fetchingError = useSelector(state => state.errorFetching.isErrorFetchingRegistration)
  const emailStatus = useSelector(state => state.status.emailStatus)
  const language = useSelector(state => state.account.language)

  const customerCareText = language ? '/customer-care?language=' + language : '/customer-care'

  useEffect(() => {
    document.cookie = 'access=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }, [])

  const getSuccessAnimation = () => {
    return (
      <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
        <Column classNames={['is-paddingless', 'is-one-fifth']}>
          <AnimationContainer>
            <Animation onAnimationFinish={handleOnAnimationFinish} animationType='success' />
            <Text
              align='center' textSize='h1' text={formatMessage(messages.registrationSuccess)}
              fontColor={color.success}
            />
          </AnimationContainer>
        </Column>
      </Row>
    )
  }

  const getErrorMessage = () => {
    const errorDiv = (
      <ErrorMsg>
        <CustomText fontcolor={color.danger}>
          {formatMessage(messages.registrationErrorMsg)}
          <PaddingSpace />
          <CustomTextLink textSize='h5' onClick={() => navigate(customerCareText)} fontcolor={color.info}>
            {formatMessage(messages.registrationErrorMsgHere)}
          </CustomTextLink>
        </CustomText>
      </ErrorMsg>
    )
    return fetchingError ? errorDiv : null
  }

  const getInvalidEmailErrorMessage = () => {
    const errorDiv = (
      <ErrorMsg>
        <CustomText fontcolor={color.danger}>
          {formatMessage(messages.invalidEmail)}
        </CustomText>
      </ErrorMsg>
    )
    return emailStatus === 'EMAIL_TAKEN' ? errorDiv : null
  }

  const getTheme = () => {
    const registrationButtonTheme = !validInputs() ? color.disabled : color.success
    const passwordInputTheme = !password ? color.lightTextDefault : (!isValidPassword ? color.danger : color.info)
    const firstNameTheme = fetchingError ? color.danger : (firstName.length > 0 ? color.info : color.lightTextDefault)
    const lastNameTheme = fetchingError ? color.danger : (lastName.length > 0 ? color.info : color.lightTextDefault)
    const emailTheme = fetchingError || (email && !isEmail(email)) ? color.danger : (email ? color.info : color.lightTextDefault)

    return { emailTheme, passwordInputTheme, firstNameTheme, lastNameTheme, registrationButtonTheme }
  }

  const handleOnAnimationFinish = () => {
    dispatch(Actions.goingToPrivacyPolicy(true))
    navigate('/privacy')
  }
  const handlePrePage = () => {
    dispatch(Actions.setAccessToken(''))
    navigate('/')
  }

  const handleOnSubmitClick = async (e) => {
    e.preventDefault()
    if (validInputs()) {
      const result = await dispatch(Actions.updateUserData(email, firstName, lastName, password))
      if (result && result.errors) {
        setErrors(result.errors)
        setIsCheckingPassword(true)
      }
    }
  }

  const handleInputChange = (name: string, value) => {
    if (name === 'email' && emailStatus === 'EMAIL_TAKEN') dispatch(Actions.resetEmailStatus())
    if (name !== 'password') {
      setUserInput({ [name]: value })
    } else {
      // $FlowFixMe
      if (fetchingError) dispatch({ type: CHANGE_PASSWORD_ERROR_RESET })

      // $FlowFixMe
      setUserInput({ password: value })
    }
  }

  /**
     * On focus will show the password validator
     */
  const handleOnFocus = () => {
    setDisplayValidator(true)
  }

  /**
     * On blur will hide the password validator
     */
  const handleOnBlur = () => {
    if (!password) {
      setDisplayValidator(false)
    }
  }

  const handleOnPasswordValid = (isValid: boolean) => {
    setIsValidPassword(isValid)
    setIsCheckingPassword(false)
  }
  const handleDisplayPassword = (e: any) => {
    e.preventDefault()
    const isTextOrHide = (passwordType === 'password')
    const newType = (isTextOrHide) ? 'text' : 'password'
    setPasswordType(newType)
  }
  const validInputs = () => {
    const result = (isEmail(email) &&
        firstName !== null &&
        firstName !== '' &&
        lastName !== null &&
        password &&
        lastName !== '')
    return result
  }
  const getLabels = () => {
    const customTexts = accountDetails.customTexts
    let emailLabel = formatMessage(messages.email)
    const firstNameLabel = formatMessage(messages.firstName)
    const LastNameLabel = formatMessage(messages.lastName)
    const passwordLabel = formatMessage(messages.password)
    const registerButtonLabel = formatMessage(messages.registrationRegister)
    if (customTexts) {
      emailLabel = customTexts.registerRegCodeEmailPlaceholder
    }
    return { emailLabel, firstNameLabel, LastNameLabel, passwordLabel, registerButtonLabel }
  }

  const errorMessage = getErrorMessage()
  const invalidEmail = getInvalidEmailErrorMessage()
  const { emailLabel, firstNameLabel, LastNameLabel, passwordLabel } = getLabels()
  const { emailTheme, passwordInputTheme, firstNameTheme, lastNameTheme, registrationButtonTheme } = getTheme()
  const userInfo: any = { firstName, lastName, email }

  if (userUpdated) return getSuccessAnimation()
  const showInputs = !requirePasswordUpdate || userRedux.userEmail === '' || fName === '' || lName === ''

  return (
    <Row classNames={['is-magrinless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Column
          classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}
        >
          <CloseIconContainer onClick={handlePrePage}>
            <Icon fillColor={color.default} size={24} iconName='close' />
          </CloseIconContainer>
          <form onSubmit={handleOnSubmitClick}>
            <Row classNames={['is-maringless']}>
              <Column className={['is-paddingless', 'is-4', 'is-offset-4']}>
                <HeaderContainer data-cy='headercy'>
                  <Text
                    align='center' textSize='h3' fontColor={color.lightDefaultTheme}
                    text={formatMessage((!showInputs ? messages.firstTimeChangePassword : messages.getStarted))}
                  />
                </HeaderContainer>
              </Column>
            </Row>
            {showInputs &&
              <FormItemContainer>
                <Input
                  name='email'
                  inputTheme={emailTheme}
                  value={email}
                  label={emailLabel}
                  type='text'
                  onChange={(text) => handleInputChange('email', text)}
                />
              </FormItemContainer>}
            {showInputs &&
              <FormItemContainer>
                <Input
                  name='firstName'
                  inputTheme={firstNameTheme}
                  value={firstName}
                  label={firstNameLabel}
                  type='text'
                  onChange={(text) => handleInputChange('firstName', text)}
                />
              </FormItemContainer>}
            {showInputs &&
              <FormItemContainer>
                <Input
                  name='lastName'
                  inputTheme={lastNameTheme}
                  value={lastName}
                  label={LastNameLabel}
                  type='text'
                  onChange={(text) => handleInputChange('lastName', text)}
                />
              </FormItemContainer>}
            <FormItemContainer>
              <Input
                name='password'
                _lr-hide
                inputTheme={passwordInputTheme}
                onClickIcon={handleDisplayPassword}
                type={passwordType === 'password' ? 'password' : 'text'}
                iconName={passwordType === 'password' ? 'eyeClose' : 'eyeOpen'}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                value={password}
                label={passwordLabel}
                onChange={(text) => handleInputChange('password', text)}
                testSetPassword={(pass) => setUserInput({ password: pass })}
              />
            </FormItemContainer>
            {errorMessage}
            {invalidEmail}
            <RequirementSteps
              onUpdate={handleOnPasswordValid}
              password={password}
              repeatPassword={password}
              displayValidator={displayValidator}
              subdomain={subdomain}
              userInfo={userInfo}
              fromRegistration
              errors={errors}
              isCheckingPassword={isCheckingPassword}
            />
            <Row classNames={['is-marginless', 'is-mobile']}>
              <ButtonContainer>
                <Column classNames={['is-paddingless', 'is-full']}>
                  <NewButton
                    pill type='submit' ariaLabel={formatMessage(messages.customerCareSubmit)}
                    fill disabled={!validInputs()} themeColor={registrationButtonTheme}
                    title={formatMessage(messages.customerCareSubmit)}
                  />
                </Column>
              </ButtonContainer>
            </Row>
          </form>
          <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
            <ButtonSpacing onClick={() => navigate('/')}>
              <Text
                align='center' textSize='p' fontColor={color.grey800}
                text={formatMessage(messages.registrationCurrentUser)}
              />
              <TextIcon classNames={['is-paddingless']}>
                <Text
                  align='center' textSize='p' fontColor={color.info} cursor
                  text={formatMessage(messages.registrationLoginHere)}
                />
              </TextIcon>
            </ButtonSpacing>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}

export default injectIntl(UserData)
