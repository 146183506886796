// @flow

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { Animation } from '../Components/index'

import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/NativeText'

const activationMessages = defineMessages({
  activationSuccess: {
    id: 'activation.success',
    defaultMessage: 'Email verified successfully'
  },
  pleaseCloseAndOpenApp: {
    id: 'activation.pleaseCloseAndOpenApp',
    defaultMessage: 'Please close this page and open your app'
  }
})

const LinkContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
`

const AnimationContainer: StyledComponent<{}> = styled.div`
  width: 100%;
  z-index: 3;
`

// add for testing purpose
LinkContainer.displayName = 'LinkContainer'
AnimationContainer.displayName = 'AnimationContainer'

function EmailVerificationSuccess ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  return (
    <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
          <Column classNames={['is-paddingless', 'is-one-fifth', 'is-centered']}>
            <AnimationContainer>
              <Animation animationType='success' />
            </AnimationContainer>
          </Column>
        </Row>
        <LinkContainer>
          <Text align='center' textSize='h2' fontColor={color.success} text={formatMessage(activationMessages.activationSuccess)} />
          <Text align='center' textSize='h4' fontColor={color.success} text={formatMessage(activationMessages.pleaseCloseAndOpenApp)} />
        </LinkContainer>
      </Column>
    </Row>
  )
}

export default (injectIntl(EmailVerificationSuccess))
export { EmailVerificationSuccess as UnwrappedEmailVerificationSuccess }
