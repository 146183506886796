// @flow

import { Riffraff } from '@indicium/leonardo'
import { get, isEmpty, isEqual, trim, reduce, includes, map } from 'lodash'
import { createLogger, INFO, WARN, ERROR } from 'browser-bunyan'
import { ConsolePlainStream } from '@browser-bunyan/console-plain-stream'
import type { Dispatch } from 'redux'
import type { AccountInfo, LookUpUser, ConfirmRegCode, ValidatePasswordInput } from '@indicium/leonardo/src/connectors/riffraff'
import type { StateType } from './reducers'
import { env, riffraffHost, riffraffProtocol, riffraffPort, iframeWhitelist } from '../utils/config'
// import { trackLogin, trackRegistration, trackOrganizationID, trackLookUpUser, trackRegistrationRegCode, trackRegistrationEmail, trackRegistrationName, trackRegistrationAcceptApprisePolicy, trackCustomerCareSent, trackRouteToSSOProvider, trackRegistrationLogin } from '../utils/analytics'
// import jwtDecode from 'jwt-decode'
// import Coleman from '../utils/coleman'
import { default as isEmailValidator } from 'validator/lib/isEmail' // eslint-disable-line
// import isMobile from '../utils/isMobile'
import pingSSO from '../utils/ssoHealthcheck'

type GetState = () => StateType
export type ThunkAction<T> = (dispatch: Dispatch<T>, getState: GetState) => any

/**
 * Alfreds Action Types
 */
export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS'
export const GET_USER_LOOKUP = 'GET_USER_LOOKUP'
export const SET_REG_CODE = 'SET_REG_CODE'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_FIRST_NAME = 'SET_FIRST_NAME'
export const SET_LAST_NAME = 'SET_LAST_NAME'
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_USER_NAME = 'SET_USER_NAME'
export const RESET_EMAIL_DETAILS = 'RESET_EMAIL_DETAILS'
export const SET_USER_REG_CODE = 'SET_USER_REG_CODE'
export const REGISTER_USER_DETAILS = 'REGISTER_USER_DETAILS'
export const SET_URL_QUERY_PARAMS = 'SET_URL_QUERY_PARAMS'
export const REDIRECT_TO_CALLBACK_URL = 'REDIRECT_TO_CALLBACK_URL'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const SET_HAS_USER_ACCEPTED_PRIVACY_POLICY = 'SET_HAS_USER_ACCEPTED_PRIVACY_POLICY'
export const RESET_GETSTARTED = 'RESET_GETSTARTED'
export const ACCOUNT_FETCHING_ERROR = 'ACCOUNT_FETCHING_ERROR'
export const USER_FETCHING_ERROR = 'USER_FETCHING_ERROR'
export const PASSWORD_INVALID = 'PASSWORD_INVALID'
export const REGISTRATION_FETCHING_ERROR = 'REGISTRATION_FETCHING_ERROR'
export const ACCOUNT_FETCH_REQUEST_STARTED = 'ACCOUNT_FETCH_REQUEST_STARTED'
export const ACCOUNT_FETCH_REQUEST_DONE = 'ACCOUNT_FETCH_REQUEST_DONE'
export const USER_FETCH_REQUEST_STARTED = 'USER_FETCH_REQUEST_STARTED'
export const CHECK_PASSWORD_REQUEST_STARTED = 'CHECK_PASSWORD_REQUEST_STARTED'
export const CHECK_PASSWORD_REQUEST_DONE = 'CHECK_PASSWORD_REQUEST_DONE'
export const USER_FETCH_REQUEST_DONE = 'USER_FETCH_REQUEST_DONE'
export const REGISTRATION_FETCH_REQUEST_STARTED = 'REGISTRATION_FETCH_REQUEST_STARTED'
export const REGISTRATION_FETCH_REQUEST_DONE = 'REGISTRATION_FETCH_REQUEST_DONE'
export const RESET_APP_STATE = 'RESET_APP_STATE'
export const ROUTE_TO_SSO_PROVIDER = 'ROUTE_TO_SSO_PROVIDER'
export const OPEN_REG_EMAIL_CHECK = 'OPEN_REG_EMAIL_CHECK'
export const LOADING_SSO_LOGIN = 'LOADING_SSO_LOGIN'
export const ANALYTICS_LOCAL_LOGIN = 'ANALYTICS_LOCAL_LOGIN'
export const RESET_USER_ERROR = 'RESET_USER_ERROR'
export const ANALYTICS_FORGOT_PASSWORD_EMAIL_SENT = 'ANALYTICS_FORGOT_PASSWORD_EMAIL_SENT'
export const ANALYTICS_PASSWORD_UPDATED = 'ANALYTICS_PASSWORD_UPDATED'
export const SET_EMAIL_STATUS = 'SET_EMAIL_STATUS'
export const ANALYTICS_USER_REGISTERED = 'ANALYTICS_USER_REGISTERED'
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_ERROR_RESET = 'CHANGE_PASSWORD_ERROR_RESET'
export const GOING_TO_PRIVACY_POLICY = 'GOING_TO_PRIVACY_POLICY'
export const CHANGE_PASSWORD_CHECK = 'CHANGE_PASSWORD_CHECK'
export const VALIDATE_PASSWORD_REQUEST_STARTED = 'VALIDATE_PASSWORD_REQUEST_STARTED'
export const VALIDATE_PASSWORD_REQUEST_DONE = 'VALIDATE_PASSWORD_REQUEST_DONE'
export const SSO_LOGOUT_REQUEST_STARTED = 'SSO_LOGOUT_REQUEST_STARTED'
export const SSO_LOGOUT_REQUEST_DONE = 'SSO_LOGOUT_REQUEST_DONE'
export const GET_CUSTOM_OAUTH_REDIRECT_URL_DONE = 'GET_CUSTOM_OAUTH_REDIRECT_URL_DONE'
export const SET_MFA_REQUIRED = 'SET_MFA_REQUIRED'
export const SET_USER_UPDATED = 'SET_USER_UPDATED'
export const REGISTER_MOBILE_NUMBER = 'REGISTER_MOBILE_NUMBER'

const url = `${riffraffProtocol}://${riffraffHost}:${riffraffPort}`
const logger = createLogger({
  name: `alfred ${env}`,
  streams: [{
    level: INFO,
    stream: new ConsolePlainStream()
  }, {
    level: WARN,
    stream: new ConsolePlainStream()
  },
  {
    level: ERROR,
    stream: new ConsolePlainStream()
  }]
})
const riffraff = new Riffraff(url, logger)
export type SetRegCode = {
  value: string
} & ConfirmRegCode

export type SetEmail = {
  status: '' | 'EMAIL_AVAILABLE' | 'EMAIL_TAKEN',
  value: string
}
type SetUrlQueryParams = {
  callbackUrl: string, appVersion: string, bypassSSO: boolean, subdomain: string, isGenericFlow: boolean, language: string
}

type RegisterMobileNumberParams = {
  phoneNumber: string, accountOrEmail: string, organizationId: string
}

type GetAccountDetailsAction = { type: 'GET_ACCOUNT_DETAILS', result: AccountInfo }

type GetUserDetailsAction = { type: 'GET_USER_LOOKUP', result: LookUpUser }

type SetUserNameAction = { type: 'SET_USER_NAME', name: string }

type SetUserEmailAction = { type: 'SET_USER_EMAIL', result: string }

type SetUserRegCodeAction = { type: 'SET_USER_REG_CODE', result: string }

type ResetUserDetailsAction = { type: 'RESET_USER_DETAILS' }

type SetRegCodeAction = { type: 'SET_REG_CODE', result: SetRegCode }

type ResetAppStateAction = { type: 'RESET_APP_STATE'}

type SetFirstNameAction = { type: 'SET_FIRST_NAME', result: string }

type SetLastNameAction = { type: 'SET_LAST_NAME', result: string }

type CheckEmailAction = { type: 'CHECK_EMAIL', result: { value: string, status: string } }

type SetEmailAction = { type: 'SET_EMAIL', result: SetEmail }

type ResetEmailDetailsAction = { type: 'RESET_EMAIL_DETAILS' }

type AccountFetchingErrorAction = { type: 'ACCOUNT_FETCHING_ERROR' }

type UserFetchingErrorAction = { type: 'USER_FETCHING_ERROR' }

type InvalidPasswordErrorAction = { type: 'PASSWORD_INVALID', errors: Array<string> }

type RegistrationFetchingErrorAction = { type: 'REGISTRATION_FETCHING_ERROR' }

type AccountFetchRequestStartedAction = { type: 'ACCOUNT_FETCH_REQUEST_STARTED' }

type AccountFetchRequestDoneAction = { type: 'ACCOUNT_FETCH_REQUEST_DONE' }

type UserFetchRequestStartedAction = { type: 'USER_FETCH_REQUEST_STARTED' }

type CheckPasswordRequestStartedAction = { type: 'CHECK_PASSWORD_REQUEST_STARTED' }

type CheckPasswordRequestDoneAction = { type: 'CHECK_PASSWORD_REQUEST_DONE' }

type ValidatePasswordRequestStartedAction = { type: 'VALIDATE_PASSWORD_REQUEST_STARTED' }

type ValidatePasswordRequestDoneAction = { type: 'VALIDATE_PASSWORD_REQUEST_DONE' }

type UserFetchRequestDoneAction= { type: 'USER_FETCH_REQUEST_DONE' }

type RegistrationFetchRequestStartedAction = { type: 'REGISTRATION_FETCH_REQUEST_STARTED' }

type RegistrationFetchRequestDoneAction = { type: 'REGISTRATION_FETCH_REQUEST_DONE' }

type RegisterUserDetailsAction = { type: 'REGISTER_USER_DETAILS', result: string }

type ResetGetStartedAction = { type: 'RESET_GETSTARTED' }

type SetUrlQueryParamsAction = { type: 'SET_URL_QUERY_PARAMS', result: SetUrlQueryParams }

type RegisterMobileNumberAction = { type: 'REGISTER_MOBILE_NUMBER', result: RegisterMobileNumberParams }

type SetAccessTokenAction = { type: 'SET_ACCESS_TOKEN', result: string }

type SetRefreshTokenAction = { type: 'SET_REFRESH_TOKEN', result: string }

type SetHasUserAcceptedPrivacyPolicyAction = { type: 'SET_HAS_USER_ACCEPTED_PRIVACY_POLICY', result: boolean }

type GoingToPrivacyPolicyAction = { type: 'GOING_TO_PRIVACY_POLICY', result: boolean }

type RedirectToCallbackUrlAction = { type: 'REDIRECT_TO_CALLBACK_URL' }

type RouteToSSOProviderAction = { type: 'ROUTE_TO_SSO_PROVIDER' }

type AnalyticsSSOLoginAction = { type: 'LOADING_SSO_LOGIN', ssoType: string, authUrl: string }

type AnalyticsLocalLoginAction = { type: 'ANALYTICS_LOCAL_LOGIN' }

type ResetUserErrorAction = { type: 'RESET_USER_ERROR' }

type AnalyticsForgotPasswordEmailSentAction = { type: 'ANALYTICS_FORGOT_PASSWORD_EMAIL_SENT' }

type AnalyticsPasswordUpdatedAction = { type: 'ANALYTICS_PASSWORD_UPDATED' }

type SetEmailStatusAction = { type: 'SET_EMAIL_STATUS', result: string }

type AnalyticsUserRegisteredAction= { type: 'ANALYTICS_USER_REGISTERED' }

type ChangePasswordChangeAction = { type: 'CHANGE_PASSWORD_CHECK', result: boolean }

type SSOLogoutRequestStartedAction = { type: 'SSO_LOGOUT_REQUEST_STARTED' }

type SSOLogoutRequestDoneAction = { type: 'SSO_LOGOUT_REQUEST_DONE' }

type getCustomOAuthRedirectURLStartedAction = { type: 'GET_CUSTOM_OAUTH_REDIRECT_URL_STARTED' }

type getCustomOAuthRedirectURLDoneAction = { type: 'GET_CUSTOM_OAUTH_REDIRECT_URL_DONE' }

type SetMFARequiredAction = { type: 'SET_MFA_REQUIRED', result: boolean }

type SetUserUpdatedAction = { type: 'SET_USER_UPDATED', result: boolean }

export type Action =
  | GetAccountDetailsAction
  | GetUserDetailsAction
  | ResetUserDetailsAction
  | SetRegCodeAction
  | SetFirstNameAction
  | SetLastNameAction
  | SetEmailAction
  | ResetEmailDetailsAction
  | CheckEmailAction
  | SetUserEmailAction
  | SetUserRegCodeAction
  | SetUserNameAction
  | AccountFetchingErrorAction
  | UserFetchingErrorAction
  | InvalidPasswordErrorAction
  | RegistrationFetchingErrorAction
  | AccountFetchRequestStartedAction
  | AccountFetchRequestDoneAction
  | UserFetchRequestStartedAction
  | CheckPasswordRequestStartedAction
  | CheckPasswordRequestDoneAction
  | UserFetchRequestDoneAction
  | RegistrationFetchRequestStartedAction
  | RegistrationFetchRequestDoneAction
  | RegisterUserDetailsAction
  | SetUrlQueryParamsAction
  | RedirectToCallbackUrlAction
  | SetAccessTokenAction
  | SetRefreshTokenAction
  | SetHasUserAcceptedPrivacyPolicyAction
  | RouteToSSOProviderAction
  | ResetGetStartedAction
  | ResetAppStateAction
  | AnalyticsSSOLoginAction
  | AnalyticsLocalLoginAction
  | ResetUserErrorAction
  | SetEmailStatusAction
  | CheckEmailAction
  | GoingToPrivacyPolicyAction
  | ChangePasswordChangeAction
  | ValidatePasswordRequestStartedAction
  | ValidatePasswordRequestDoneAction
  | SSOLogoutRequestStartedAction
  | SSOLogoutRequestDoneAction
  | SetMFARequiredAction
  | SetUserUpdatedAction
  | RegisterMobileNumberAction

type routeToSSOProviderActions = RouteToSSOProviderAction | AccountFetchRequestDoneAction | AnalyticsSSOLoginAction
/**
 * Gets the built url string and send user to it
 * @param {object} account
 */
export function routeToSSOProvider (account: AccountInfo): ThunkAction<routeToSSOProviderActions> {
  return async function (dispatch: Dispatch<routeToSSOProviderActions>, getState: GetState) {
    if (account) {
      const SSOType = account.SSOType
      if (!isEqual(SSOType, 'NONE') && isEqual(account.authTypes.ssoEnabled, true)) {
        const state = getState()
        const callbackUrl = state.account.callbackUrl
        const url = riffraff.buildSSOLoginUrl(account._id, SSOType, callbackUrl)
        if (url) {
          const state = getState()
          if (state.account.isGenericFlow) {
            // trackRouteToSSOProvider(account._id, account.SSOType, true)
          }
          const authUrl = decodeURIComponent(url)
          dispatch({ type: LOADING_SSO_LOGIN, authUrl })
          if (includes(iframeWhitelist, account._id)) {
            try {
              if (window.self !== window.top) {
                window.open(authUrl, '_blank', 'toolbar=0,location=0,menubar=0')
              } else {
                window.location.href = authUrl
              }
            } catch (e) {
              window.location.href = authUrl
            }
            dispatch({ type: ACCOUNT_FETCH_REQUEST_DONE })
          }
          window.location.href = authUrl
        }
      }
    }
  }
}

type getAccountDetailsAction = GetAccountDetailsAction | AccountFetchRequestStartedAction | AccountFetchRequestDoneAction | AccountFetchingErrorAction | RouteToSSOProviderAction | ResetGetStartedAction
/**
 * Returns the Account detail of the application based on
 * sending subdomain string
 * @param {string} subdomain
 * @returns {object} result
 */
export function getAccountDetails (subdomain: string, customerCare: boolean): ThunkAction<getAccountDetailsAction> {
  return async function (dispatch: Dispatch<getAccountDetailsAction>, getState: GetState) {
    try {
      const state = getState()
      const appVersion = state.account.appVersion || ''
      dispatch({ type: ACCOUNT_FETCH_REQUEST_STARTED })
      const result: AccountInfo = await riffraff.getAccountInfoFromSubdomain(`/account/subdomain/${subdomain}?appVersion=${appVersion}`)
      /**
       * If Generic App flow track the found organizationID value and set it was successful
       */
      if (state.account.isGenericFlow) {
        // trackOrganizationID(subdomain, true)
      }

      dispatch({ type: GET_ACCOUNT_DETAILS, result })
      dispatch({ type: RESET_GETSTARTED })
      const authTypes = result.authTypes

      if (result.ssoOrNativeRedirect && result.ssoOrNativeRedirect.enable && result.ssoOrNativeRedirect.url && !appVersion) {
        const ssoRedirect = await pingSSO(result.ssoOrNativeRedirect.url)
        if (ssoRedirect) dispatch(routeToSSOProvider(result))
      }

      // Get authTypes
      // Loop through and find true
      // Check for invdividuals then check for
      // special cases
      if (authTypes) {
        const newResults = reduce(authTypes, (result, value, key: string) => {
          if (value) {
            result.push(key)
          }
          return result
        }, [])

        if (!(isEqual(newResults.length, 1) && isEqual(newResults[0], 'ssoEnabled')) || state.account.bypassSSO) {
          dispatch({ type: ACCOUNT_FETCH_REQUEST_DONE })
        }

        if (newResults.length === 1 && newResults[0] === 'ssoEnabled' && !state.account.bypassSSO && !customerCare) {
          dispatch(routeToSSOProvider(result))
        } else {
          if (customerCare) { dispatch({ type: ACCOUNT_FETCH_REQUEST_DONE }) }
        }
      }
    } catch (error) {
      const state = getState()
      /**
       * If Generic App flow track the not found organizationID value and set it not successful
       */
      if (state.account.isGenericFlow) {
        // trackOrganizationID(subdomain, false)
      }
      const res = { account: null }
      dispatch({ type: GET_ACCOUNT_DETAILS, res })
      dispatch({ type: ACCOUNT_FETCH_REQUEST_DONE })
      return dispatch({ type: ACCOUNT_FETCHING_ERROR })
    }
  }
}

/**
 * Set the callback url so we can route user later on
 * @param {string} callbackUrl
 * @param {string} appVersion

 */
export function setURLQueryParams (callbackUrl: string, appVersion: string, bypassSSO: boolean, subdomain: string, isGenericFlow: boolean, language: string): SetUrlQueryParamsAction {
  const result = { callbackUrl, appVersion, bypassSSO, subdomain, isGenericFlow, language }
  return { type: SET_URL_QUERY_PARAMS, result }
}
type RedirectToCallbackUrlActions = RedirectToCallbackUrlAction | ResetAppStateAction | AnalyticsLocalLoginAction
/**
 * Redirects user to whatever there callback url is
 */
export function redirectToCallbackUrl (): ThunkAction<RedirectToCallbackUrlActions> {
  return async function (dispatch: Dispatch<RedirectToCallbackUrlActions>, getState: GetState) {
    const state = getState()
    let callbackUrl = state.account.callbackUrl
    const accessToken = state.accessTokenState.accessToken
    // const decodedToken = jwtDecode(state.accessTokenState.accessToken)
    // const registrationDate = decodedToken.user.registrationDate
    const refreshToken = state.accessTokenState.refreshToken
    console.log('callbackUrl', callbackUrl)
    console.log('accessToken', accessToken)

    // const email = decodedToken.user.email
    // const userId = decodedToken.user._id
    // const adminType = decodedToken.adminType
    // const groupsAnalyticsEnabled = decodedToken.groupsAnalyticsEnabled
    // const accountId = get(state, 'account.account._id')
    // const userAnalyticsEnabled = get(state, 'account.account.userAnalyticsEnabled')

    // let appProfileIds, appProfileNames
    try {
      // const coleman = new Coleman()
      // const getMeResult = await coleman.getMe(state.accessTokenState.accessToken)
      // let appProfiles = getMeResult.memberships[0].contentFeedProfiles
      // appProfiles = reduce(appProfiles, (result, cfp) => {
      //   result.appProfileIds.push(cfp._id)
      //   result.appProfileNames.push(cfp.name)
      //   return result
      // }, { appProfileNames: [], appProfileIds: [] })
      // appProfileIds = appProfiles.appProfileIds
      // appProfileNames = appProfiles.appProfileNames
      if (state.account.isGenericFlow) {
        // trackRegistrationLogin(email, userId, registrationDate, adminType, true)
      }
    } catch (err) {
      const state = getState()
      if (state.account.isGenericFlow) {
        // trackRegistrationLogin(email, userId, registrationDate, adminType, false)
      }
      console.error('error attempting getMe')
      console.error(err.message)
    }
    if (callbackUrl && accessToken) {
      console.log('inside redirect')

      dispatch({ type: ANALYTICS_LOCAL_LOGIN })
      dispatch({ type: RESET_APP_STATE })
      if (includes(callbackUrl, 'http://') || includes(callbackUrl, 'https://')) {
        // await trackLogin(userAnalyticsEnabled, 'WEB', accountId, email, decodedToken.user.name, userId, registrationDate, groupsAnalyticsEnabled, appProfileIds, appProfileNames, adminType)
        // if the callbackUrl is http protocol, dont attach the access token to the url, and no refresh token should be issued
        const access = JSON.stringify({ accessToken, subdomain: state.account.subdomain, created: Date.now() })
        window.localStorage.setItem('access', access)
      } else {
        // await trackLogin(userAnalyticsEnabled, 'MOBILE', accountId, email, decodedToken.user.name, userId, registrationDate, groupsAnalyticsEnabled, appProfileIds, appProfileNames, adminType)
        // otherwise, we are going back to mobile app, append the accessToken and refreshToken
        if (includes(callbackUrl, '?')) {
          callbackUrl = callbackUrl + '&accessToken=' + accessToken
        } else {
          callbackUrl = callbackUrl + '?accessToken=' + accessToken
        }
        if (!isEmpty(refreshToken)) callbackUrl = callbackUrl + '&refreshToken=' + refreshToken
      }
      if (state.account.isGenericFlow) {
        window.sessionStorage.clear()
      }
      console.log('callbackUrl before href set', callbackUrl)

      window.location.href = callbackUrl
    }
  }
}

/**
 * Set accessToken to state
 * @param {string} result
 */
export function setAccessToken (result: string): SetAccessTokenAction {
  return { type: SET_ACCESS_TOKEN, result }
}

/**
 * Set refreshToken to state
 * @param {string} result
 */
export function setRefreshToken (result: string): SetRefreshTokenAction {
  return { type: SET_REFRESH_TOKEN, result }
}

export function setHasUserAcceptedPrivacyPolicy (result: boolean): SetHasUserAcceptedPrivacyPolicyAction {
  return { type: SET_HAS_USER_ACCEPTED_PRIVACY_POLICY, result }
}

export function setMFARequired (result: boolean): SetMFARequiredAction {
  return { type: 'SET_MFA_REQUIRED', result }
}

export function goingToPrivacyPolicy (result: boolean): GoingToPrivacyPolicyAction {
  return { type: GOING_TO_PRIVACY_POLICY, result }
}
// FRANK!!!
// This action will set the status of the privacy polisy
// from the result of the login.
/**
 * Set whether the user has accepted the privacy policy to state
 * @param {boolean} result
 */
export function acceptApprisePolicy (): ThunkAction<SetHasUserAcceptedPrivacyPolicyAction> {
  return async function (dispatch: Dispatch<changeEmailAction>, getState: GetState) {
    try {
      const state = getState()
      const accessToken = state.accessTokenState.accessToken

      dispatch({ type: USER_FETCH_REQUEST_STARTED })

      const result = await riffraff.acceptApprisePolicy(accessToken)
      if (state.account.isGenericFlow) {
        // trackRegistrationAcceptApprisePolicy(result, true)
      }
      return dispatch(setHasUserAcceptedPrivacyPolicy(result.success))
    } catch (error) {
      const state = getState()
      if (state.account.isGenericFlow) {
        // trackRegistrationAcceptApprisePolicy({ success: false }, false)
      }
      dispatch(setHasUserAcceptedPrivacyPolicy(false))
      return dispatch({ type: USER_FETCH_REQUEST_DONE })
    }
  }
}

/**
 * Resets user details and RegCode
 */
export function resetUser (): ThunkAction<ResetUserDetailsAction> {
  return async function (dispatch: Dispatch<ResetUserDetailsAction>) {
    dispatch({
      type: RESET_USER_DETAILS
    })
  }
}

/**
 * Validate that the value has an email format and returns true if Email is in the correct format
 * @param {string} email
 */
export function isEmail (email: string) {
  return isEmailValidator(email)
}

type getUserDetailsActions = UserFetchRequestStartedAction | UserFetchRequestDoneAction | GetUserDetailsAction | SetRegCodeAction | SetEmailAction | SetUserEmailAction | SetUserRegCodeAction | UserFetchingErrorAction

/**
 * Returns user details
 * @param {string} accountId
 * @param {string} value
 */
export function getUserDetails (accountId: string, value: string): ThunkAction<getUserDetailsActions> {
  return async function (dispatch: Dispatch<getUserDetailsActions>, getState: GetState) {
    let result
    const state = getState()
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      result = await riffraff.lookUpUser(accountId, value)
      if (state.account.isGenericFlow) {
        /**
         * If Generic App flow track user value input and set successful
         */
        // trackLookUpUser(value, result.status, true)
      }
    } catch (error) {
      /**
       * If Generic App flow track not found value and set not successful
       */
      if (state.account.isGenericFlow) {
        // trackLookUpUser(value, { status: 'ERROR_USER_LOOKUP' }, false)
      }
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
    // Check to see if the value is an email format
    const validEmail = isEmail(value)
    if (result) {
      // Check the value to see the status of the user
      dispatch({ type: GET_USER_LOOKUP, result })
      // Run through the switch case to determine what should be saved
      // and where we should take the user.
      const email = { value, status: '' }
      let isValidAvailableEmail
      switch (result.status) {
        case 'NO_RESULTS':
          // If the status is no results but the value is an email format then save it
          // and take the user to next step
          if (validEmail) {
            if (state.account.isGenericFlow) {
              // trackRegistrationEmail(value, result.status, true)
            }
            dispatch(setEmail(email))
            // navigate('/registration/code')
          }
          break
        case 'FOUND_USER_BY_REGCODE':
          // Set reg code
          // Send the user to enter their password
          dispatch(setUserRegCode(value))
          if (result.email) dispatch(setUserEmail(result.email))
          // navigate('/')
          break
        case 'FOUND_UNREGISTERED_MEMBER_BY_REGCODE':
          // Set the email and accountId to the value
          // We know that most likely the users email
          // is typically their accountId
          isValidAvailableEmail = false
          if (validEmail) {
            isValidAvailableEmail = await riffraff.emailAvailable(value)
          }
          if (state.account.isGenericFlow) {
            // trackRegistrationRegCode(value, result.status, true)
          }
          dispatch(setRegCode({ value, status: '' }))
          if (isValidAvailableEmail) {
            // Then send them off to step 3 to enter
            // their name
            if (state.account.isGenericFlow) {
              // trackRegistrationEmail(value, result.status, true)
            }
            dispatch(setEmail(email))
            // navigate('/registration/name')
          } else {
            // If the value is not an email then
            // just set the accountId and send the user
            // to enter their email
            // navigate('/registration/email')
          }
          break
        case 'FOUND_USER_BY_EMAIL':
          // Set the email
          // Send the user to enter their password
          dispatch(setUserEmail(value))
          // navigate('/')
          break
        default:
          throw new Error('INVALID_USER_DETAILS_STATUS')
      }
    }
    dispatch({ type: USER_FETCH_REQUEST_DONE })
  }
}

/**
 * Set a found users reg code
 * @param {string} result -> this is value
 * @returns {string} result
 */
export function setUserRegCode (result: string): SetUserRegCodeAction {
  return { type: SET_USER_REG_CODE, result }
}
/**
 * Set a found users email
 * @param {string} result -> this is value
 * @returns {string} result
 */
export function setUserEmail (result: string): SetUserEmailAction {
  return { type: SET_USER_EMAIL, result }
}

/**
 * Set a found user name
 * @param {string} name -> this is value
 * @returns {string} name
 */
export function setUserName (name: string): SetUserNameAction {
  return { type: SET_USER_NAME, name }
}

/**
 * Attempt to login the user
 */
type loginUserAction = ResetUserDetailsAction |
UserFetchRequestStartedAction | UserFetchRequestDoneAction |
UserFetchingErrorAction | SetAccessTokenAction | SetRefreshTokenAction | SetHasUserAcceptedPrivacyPolicyAction | SetMFARequiredAction
export function loginUser (password: string, email: string, mfaCode?: string): ThunkAction<loginUserAction> {
  return async function (dispatch: Dispatch<loginUserAction>, getState: GetState) {
    const state = getState()
    const accountId = get(state, 'account.account._id')
    const callbackUrl = get(state, 'account.callbackUrl')
    // const appVersion = get(state, 'account.appVersion')
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      const result = await riffraff.loginUser(email, accountId, password, callbackUrl, mfaCode)
      dispatch({ type: USER_FETCH_REQUEST_DONE })

      if (result.mfaRequired) {
        return dispatch(setMFARequired(result.mfaRequired))
      }
      // const ssoOrNativeRedirect = get(state, 'account.ssoOrNativeRedirect')

      // FRANK!!!
      // grab the boolean hasAcceptedAppPolicy from login result and set it to
      // the user slice of state for use on Privacy Policy Page
      dispatch(setHasUserAcceptedPrivacyPolicy(result.hasAcceptedApprisePolicy))
      if (result.accessToken) {
        dispatch(setAccessToken(result.accessToken))
      }
      if (result.refreshToken) {
        dispatch(setRefreshToken(result.refreshToken))
      }
      // only for web app
      // if (appVersion) {
      //   return dispatch({ type: USER_FETCH_REQUEST_DONE })
      // }

      // if needed to check the name is 'FirstName LastName'
      // const fullNameRegx = /(^[A-Za-z]{1,})([ ]{0,1})([A-Za-z]{1,})?([ ]{0,1})?([A-Za-z]{1,})?([ ]{0,1})?([A-Za-z]{1,})/
      if (isEmail(result.email) && result.name && !result.requirePasswordUpdate) {
        return dispatch(setUserUpdated(true))
      } else if (result.status) {
        // Delete access cookie. In this way if redirect or close tab and go to admin CMS `higgins`
        // the user should not logged in
        document.cookie = 'access=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        dispatch({ type: GET_USER_LOOKUP, result })
        dispatch({ type: SET_USER_EMAIL, result: result.email })
      }
      return result
      // put the code inside this if condition for only ssoOrNativeRedirect enable users
      // if (ssoOrNativeRedirect && ssoOrNativeRedirect.enable) {
      //
      // }
    } catch (error) {
      console.error(error)
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

type contactCustomerCareActions = contactCustomerCareActions

export function contactCustomerCare (accountId: string, name: string, email: string, subject: string, description: string, applicationName: string): ThunkAction<contactCustomerCareActions> {
  return async function (dispatch: Dispatch<contactCustomerCareActions>, getState: GetState) {
    let result = {}
    const state = getState()
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      result = await riffraff.contactCustomerCare(accountId, name, email, subject, description, applicationName)
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      if (state.account.isGenericFlow) {
        // trackCustomerCareSent(name, subject, email)
      }
      return result
    } catch (error) {
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

/**
 * Registration Step 1 Set User accountId
 * @param {string} result
 */
export function setRegCode (result: SetRegCode): SetRegCodeAction {
  return { type: SET_REG_CODE, result }
}
export function resetAppState (): ResetAppStateAction {
  return { type: RESET_APP_STATE }
}

/**
 * Registration Step 2 Set User First Name
 * @param {string} result
 */
export function setFirstName (result: string): ThunkAction<SetFirstNameAction> {
  return async function (dispatch: Dispatch<ResetEmailDetailsAction>, getState: GetState) {
    const state = getState()

    if (state.account.isGenericFlow) {
      // trackRegistrationName(result, 'FIRST_NAME')
    }
    // either concatenate the name here or on the page?
    dispatch({ type: SET_FIRST_NAME, result })
  }
}

/**
 * Resets user error
 */
export function resetUserError (): ResetUserErrorAction {
  // either concatenate the name here or on the page?
  return { type: RESET_USER_ERROR }
}
/**
 * Registration Step 2 Set User Last Name
 * @param {string} result
 */
export function setLastName (result: string): ThunkAction<SetLastNameAction> {
  return async function (dispatch: Dispatch<ResetEmailDetailsAction>, getState: GetState) {
    const state = getState()

    if (state.account.isGenericFlow) {
      // trackRegistrationName(result, 'LAST_NAME')
    }
    // either concatenate the name here or on the page?
    dispatch({ type: SET_LAST_NAME, result })
  }
}

/**
 * Registration Step 3 Set User email
 * @param {Object} result
 */
export function setEmail (result: SetEmail): SetEmailAction {
  return { type: SET_EMAIL, result }
}

/**
 * Registration Step 3 Reset Email value and status on state
 */
export function resetEmailDetails (): ThunkAction<ResetEmailDetailsAction> {
  return async function (dispatch: Dispatch<ResetEmailDetailsAction>, getState: GetState) {
    dispatch({ type: RESET_EMAIL_DETAILS })
  }
}

export function resetEmailStatus (): SetEmailStatusAction {
  return { type: SET_EMAIL_STATUS, result: '' }
}

type registrationUserDetailActions = RegistrationFetchRequestStartedAction
| RegistrationFetchRequestDoneAction | RegistrationFetchingErrorAction | RegisterUserDetailsAction | SetAccessTokenAction | SetRefreshTokenAction | AnalyticsUserRegisteredAction | InvalidPasswordErrorAction
/**
 * Attempts to register the user
 * If it fails the user is prompt to contact cc team
 * if success then register and login the user
 */
export function registerUser (registrationCode: string, email: string, firstName: string, lastName: string, password: string, openReg: boolean): ThunkAction<registrationUserDetailActions> {
  return async function (dispatch: Dispatch<registrationUserDetailActions>, getState: GetState) {
    let application = ''
    const state = getState()
    const appVersion = state.account.appVersion
    const name = trim(firstName) + ' ' + trim(lastName)
    const callbackUrl = state.account.callbackUrl
    const subdomain = state.account.subdomain
    if (state.account.account) {
      application = state.account.account.application
    }
    const accountId = get(state, 'account.account._id')
    // success should look like {accessToken: 'xyz123'}
    try {
      dispatch({ type: REGISTRATION_FETCH_REQUEST_STARTED })
      const registerResult = await riffraff.registerUser(password, email, accountId, name, firstName, lastName, registrationCode, openReg, application, appVersion, callbackUrl, subdomain)
      dispatch({ type: REGISTRATION_FETCH_REQUEST_DONE })
      if (registerResult && registerResult.type === 'PASSWORD_INVALID') {
        dispatch({ type: REGISTRATION_FETCHING_ERROR })
        return dispatch({ type: PASSWORD_INVALID, errors: map(registerResult.errors, error => errorMap[error]) })
      }
      if (registerResult.accessToken) {
        dispatch({ type: ANALYTICS_USER_REGISTERED })
        dispatch(setAccessToken(registerResult.accessToken))
      }
      if (registerResult.refreshToken) {
        dispatch(setRefreshToken(registerResult.refreshToken))
      }
      if (state.account.isGenericFlow) {
        // trackRegistration(accountId, email, appVersion, name, registrationCode, openReg, callbackUrl, true)
      }
      dispatch(setHasUserAcceptedPrivacyPolicy(false))
    } catch (error) {
      const state = getState()
      if (state.account.isGenericFlow) {
        // trackRegistration(accountId, email, appVersion, name, registrationCode, openReg, callbackUrl, false)
      }
      dispatch({ type: REGISTRATION_FETCH_REQUEST_DONE })
      return dispatch({ type: REGISTRATION_FETCHING_ERROR })
    }
  }
}

type forgotPasswordAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction | AnalyticsForgotPasswordEmailSentAction | InvalidPasswordErrorAction

export function forgotPassword (email: string, account: string, subdomain: string): ThunkAction<forgotPasswordAction> {
  return async function (dispatch: Dispatch<forgotPasswordAction>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      const forgotPasswordResult = await riffraff.forgotPassword(email, account, subdomain)
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      if (forgotPasswordResult.success) {
        dispatch({ type: ANALYTICS_FORGOT_PASSWORD_EMAIL_SENT })
      }
    } catch (error) {
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

type resetPasswordAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction | AnalyticsPasswordUpdatedAction | InvalidPasswordErrorAction

export function resetPassword (token: string, password: string, repeatPassword: string): ThunkAction<resetPasswordAction> {
  return async function (dispatch: Dispatch<resetPasswordAction>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })

      const resetPasswordResult = await riffraff.resetPassword(token, password, repeatPassword)

      dispatch({ type: USER_FETCH_REQUEST_DONE })
      if (resetPasswordResult.success) {
        dispatch({ type: ANALYTICS_PASSWORD_UPDATED })
      }
      if (resetPasswordResult.errors) {
        return dispatch({ type: PASSWORD_INVALID, errors: map(resetPasswordResult.errors, error => errorMap[error]) })
      }
    } catch (error) {
      if (error.response && error.response.data === 'PASSWORD_INVALID') {
        dispatch({ type: USER_FETCH_REQUEST_DONE })
        return dispatch({ type: PASSWORD_INVALID })
      }
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

const errorMap = {
  PASSWORD_LENGTH_ERROR: '8 characters',
  PASSWORD_CAPITAL_ERROR: '1 upper case letter',
  PASSWORD_LOWERCASE_ERROR: '1 lower case letter',
  PASSWORD_NUMBER_ERROR: '1 number',
  PASSWORD_SPECIAL_ERROR: '1 special character',
  PASSWORD_COMMON_ERROR: 'Not a common password',
  PASSWORD_SUBDOMAIN_ERROR: 'Does not include your company or domain name',
  PASSWORD_FIRSTNAME_ERROR: 'Does not include your first name',
  PASSWORD_LASTNAME_ERROR: 'Does not include your last name',
  PASSWORD_EMAIL_ERROR: 'Does not include your email address',
  PASSWORD_COMPANY_ERROR: 'Does not include your email address',
  PASSWORD_REGCODE_ERROR: 'Does not include your account id'
}

type checkPasswordAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction | AnalyticsPasswordUpdatedAction | InvalidPasswordErrorAction | CheckPasswordRequestStartedAction | CheckPasswordRequestDoneAction | ValidatePasswordRequestStartedAction | ValidatePasswordRequestDoneAction

export function checkChangePassword (email: string, password: string, repeatPassword: string, subdomain: string): ThunkAction<checkPasswordAction> {
  return async function (dispatch: Dispatch<checkPasswordAction>, getState: GetState) {
    try {
      dispatch({ type: CHECK_PASSWORD_REQUEST_STARTED })

      const checkChangePasswordResult = await riffraff.checkChangePassword(email, password, repeatPassword, subdomain)

      if (checkChangePasswordResult.success) {
        dispatch({ type: CHECK_PASSWORD_REQUEST_DONE })
        dispatch({ type: CHANGE_PASSWORD_CHECK, result: checkChangePasswordResult.success, errors: '' })
        return dispatch({ type: PASSWORD_INVALID, errors: '' })
      }
    } catch (error) {
      if (error.response && error.response.data.length > 0) {
        dispatch({ type: CHECK_PASSWORD_REQUEST_DONE })
        return dispatch({ type: PASSWORD_INVALID, errors: map(error.response.data, error => errorMap[error]) })
      }
      dispatch({ type: CHECK_PASSWORD_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

export function checkPassword (token: string, password: string, repeatPassword: string): ThunkAction<checkPasswordAction> {
  return async function (dispatch: Dispatch<checkPasswordAction>, getState: GetState) {
    try {
      dispatch({ type: CHECK_PASSWORD_REQUEST_STARTED })

      const checkPasswordResult = await riffraff.checkPassword(token, password, repeatPassword)

      if (checkPasswordResult.success) {
        dispatch({ type: CHECK_PASSWORD_REQUEST_DONE })
        dispatch({ type: CHANGE_PASSWORD_CHECK, result: checkPasswordResult.success, errors: '' })
        return dispatch({ type: PASSWORD_INVALID, errors: '' })
      }
    } catch (error) {
      if (error.response && error.response.data.length > 0) {
        dispatch({ type: CHECK_PASSWORD_REQUEST_DONE })
        return dispatch({ type: PASSWORD_INVALID, errors: map(error.response.data, error => errorMap[error]) })
      }
      dispatch({ type: CHECK_PASSWORD_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

export function toggleCheckPassword (): ThunkAction<resetPasswordAction> {
  return async function (dispatch: Dispatch<resetPasswordAction>, getState: GetState) {
    dispatch({ type: CHANGE_PASSWORD_CHECK, result: false })
    dispatch({ type: RESET_USER_DETAILS })
  }
}

type resendVerificationEmailAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction | InvalidPasswordErrorAction

export function resendVerificationEmail (oldToken: string): ThunkAction<resendVerificationEmailAction> {
  return async function (dispatch: Dispatch<resendVerificationEmailAction>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })

      await riffraff.resendVerificationEmail(oldToken)

      dispatch({ type: USER_FETCH_REQUEST_DONE })
    } catch (error) {
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

type verifyEmailAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction

export function verifyEmail (token: string): ThunkAction<verifyEmailAction> {
  return async function (dispatch: Dispatch<verifyEmailAction>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })

      await riffraff.verifyEmail(token)

      dispatch({ type: USER_FETCH_REQUEST_DONE })
    } catch (error) {
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

type changeEmailAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction

export function changeEmail (email: string, newEmail: string, confirmNewEmail: string, password: string, callbackUrl: ?string): ThunkAction<changeEmailAction> {
  return async function (dispatch: Dispatch<changeEmailAction>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      const changeEmailResult = await riffraff.changeEmail(email, newEmail, confirmNewEmail, password)
      dispatch({ type: USER_FETCH_REQUEST_DONE })
      return changeEmailResult
    } catch (error) {
      dispatch({ type: CHANGE_EMAIL_ERROR })
      return dispatch({ type: USER_FETCH_REQUEST_DONE })
    }
  }
}

type changePasswordAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction | InvalidPasswordErrorAction
export function changePassword (email: string, newPassword: string, confirmNewPassword: string, password: string, callbackUrl: ?string, subDomain: ?string): ThunkAction<changePasswordAction> {
  return async function (dispatch: Dispatch<changeEmailAction>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      const changePasswordResult = await riffraff.changePasswordNoToken(email, newPassword, confirmNewPassword, password, subDomain)
      if (changePasswordResult.success) {
        dispatch({ type: USER_FETCH_REQUEST_DONE })
        setTimeout(function () {
          window.close()
        }, 2000)
      } else if (changePasswordResult.errors) {
        dispatch({ type: USER_FETCH_REQUEST_DONE })
        dispatch({ type: CHANGE_PASSWORD_ERROR })
        return dispatch({ type: PASSWORD_INVALID, errors: map(changePasswordResult.errors, error => errorMap[error]) })
      }
      return { success: true }
    } catch (error) {
      if (error.response.data === 'PASSWORD_INVALID') {
        dispatch({ type: USER_FETCH_REQUEST_DONE })
        return dispatch({ type: PASSWORD_INVALID })
      }
      dispatch({ type: CHANGE_PASSWORD_ERROR })
      return dispatch({ type: USER_FETCH_REQUEST_DONE })
    }
  }
}

type getAccessWithSecureCookieActions = UserFetchRequestStartedAction | UserFetchRequestDoneAction | SetAccessTokenAction
| SetRefreshTokenAction | ThunkAction<RedirectToCallbackUrlActions>

export function getAccessWithSecureCookie (): ThunkAction<getAccessWithSecureCookieActions> {
  return async function (dispatch: Dispatch<getAccessWithSecureCookieActions>, getState: GetState) {
    try {
      dispatch({ type: USER_FETCH_REQUEST_STARTED })
      const state = getState()
      const callbackUrl = state.account.callbackUrl
      console.log('callbackUrl is ', callbackUrl)

      let createRefreshToken = true
      if (includes(callbackUrl, 'http')) {
        // we only want to create refresh tokens for clients that are not webApps
        // webApps can not keep refreshTokens safe
        createRefreshToken = false
      }
      const result = await riffraff.getAccessWithSecureCookie(createRefreshToken)
      console.log('result of cookie access is ', result)

      // set the access token to the app state
      dispatch(setAccessToken(result.accessToken))
      if (result.refreshToken) {
        // set the refresh token to the app state
        dispatch(setRefreshToken(result.refreshToken))
      }
      // FRANK!!!
      // redirect to the callbackUrl if user has accepted privacy policy
      dispatch(setHasUserAcceptedPrivacyPolicy(result.hasAcceptedApprisePolicy))
      console.log('checking if policy accepted before redirect', result.hasAcceptedApprisePolicy)

      if (result.hasAcceptedApprisePolicy) dispatch(redirectToCallbackUrl())
    } finally {
      dispatch({ type: USER_FETCH_REQUEST_DONE })
    }
  }
}

type validatePasswordAction = UserFetchRequestStartedAction | UserFetchRequestDoneAction | UserFetchingErrorAction | AnalyticsPasswordUpdatedAction | InvalidPasswordErrorAction | CheckPasswordRequestStartedAction | CheckPasswordRequestDoneAction

export function validatePassword (data: ValidatePasswordInput): ThunkAction<validatePasswordAction> {
  return async function (dispatch: Dispatch<validatePasswordAction>, getState: GetState) {
    try {
      dispatch({ type: VALIDATE_PASSWORD_REQUEST_STARTED })

      const validatePasswordResult = await riffraff.validatePassword(data)

      if (validatePasswordResult.success) {
        dispatch({ type: VALIDATE_PASSWORD_REQUEST_DONE })
        // dispatch({ type: CHANGE_PASSWORD_CHECK, result: validatePasswordResult.success, errors: '' })
        return dispatch({ type: PASSWORD_INVALID, errors: '' })
      }
    } catch (error) {
      if (error.response && error.response.data.length > 0) {
        dispatch({ type: VALIDATE_PASSWORD_REQUEST_DONE })
        return dispatch({ type: PASSWORD_INVALID, errors: map(error.response.data, error => errorMap[error]) })
      }
      dispatch({ type: VALIDATE_PASSWORD_REQUEST_DONE })
      return dispatch({ type: USER_FETCHING_ERROR })
    }
  }
}

type ssoLogoutAction = SSOLogoutRequestStartedAction | SSOLogoutRequestDoneAction

export function ssoLogout (queryParams: string): ThunkAction<ssoLogoutAction> {
  return async function (dispatch: Dispatch<ssoLogoutAction>, getState: GetState) {
    try {
      dispatch({ type: SSO_LOGOUT_REQUEST_STARTED })
      await riffraff.ssoLogout()
    } finally {
      dispatch({ type: SSO_LOGOUT_REQUEST_DONE })
    }
  }
}

type getCustomOAuthRedirectURLAction = getCustomOAuthRedirectURLStartedAction | getCustomOAuthRedirectURLDoneAction

export function getCustomOAuthRedirectURL (accountId: string, queryParams: string): ThunkAction<getCustomOAuthRedirectURLAction> {
  return async function (dispatch: Dispatch<getCustomOAuthRedirectURLAction>, getState: GetState) {
    try {
      const ssoUrl = riffraff.buildSSOLoginUrl(accountId, 'CUSTOM_OAUTH2')
      const url = `${ssoUrl}/callback${queryParams}`
      window.location = url
    } finally {
      dispatch({ type: GET_CUSTOM_OAUTH_REDIRECT_URL_DONE })
    }
  }
}

export function setUserUpdated (result: boolean): SetUserUpdatedAction {
  return { type: SET_USER_UPDATED, result }
}

type updateUserDataAction = SetUserUpdatedAction | SetEmailStatusAction | CheckEmailAction | SetEmailAction
    | RegistrationFetchRequestStartedAction | RegistrationFetchRequestDoneAction | SetAccessTokenAction | SetRefreshTokenAction
    | SetHasUserAcceptedPrivacyPolicyAction | InvalidPasswordErrorAction | RegisterMobileNumberAction
/**
 * Attempts to update the user only used when ssoOrNativeRedirect is set
 * and has property enable: true, and a valid URL
 */
export function updateUserData (email: string, name: string, surname: string, password: string): ThunkAction<updateUserDataAction> {
  return async function (dispatch: Dispatch<updateUserDataAction>, getState: GetState) {
    try {
      const state = getState()
      const userId = state.user.userId
      const userEmail = state.user.userEmail
      const accessTokenOld = state.accessTokenState.accessToken
      const subDomain = state.account.subdomain
      // $FlowFixMe
      const account = state.account.account._id
      let emailCheck = null
      dispatch({ type: REGISTRATION_FETCH_REQUEST_STARTED })
      await riffraff.validatePassword({
        password,
        repeatPassword: password,
        subdomain: subDomain,
        email,
        firstName: trim(name),
        lastName: trim(surname),
        regCode: email
      })
      if (email !== userEmail) {
        emailCheck = await riffraff.getUserByEmail(account, email)
      }
      if (emailCheck && emailCheck.status === 'FOUND_USER_BY_EMAIL' && emailCheck.userId !== userId) {
        dispatch({ type: SET_EMAIL_STATUS, result: 'EMAIL_TAKEN' })
        return dispatch({ type: REGISTRATION_FETCH_REQUEST_DONE })
      } else {
        const result = await riffraff.updateUserData(account, userId, trim(email), trim(name), trim(surname), password, accessTokenOld)
        if (result && result.errors && result.errors.length > 0) {
          dispatch({ type: REGISTRATION_FETCH_REQUEST_DONE })
          dispatch({ type: SET_USER_UPDATED, result: false })
          return dispatch({ type: PASSWORD_INVALID, errors: map(result.errors, error => errorMap[error]) })
        }
        const { accessToken, refreshToken } = result
        dispatch(setAccessToken(accessToken))
        if (refreshToken) {
          // set the refresh token to the app state
          dispatch(setRefreshToken(refreshToken))
        }
        dispatch({ type: REGISTRATION_FETCH_REQUEST_DONE })
        dispatch({ type: SET_USER_UPDATED, result: true })
      }
    } catch (error) {
      dispatch({ type: REGISTRATION_FETCH_REQUEST_DONE })
      if (error.response && error.response.data.length > 0) {
        dispatch({ type: SET_USER_UPDATED, result: false })
        return dispatch({ type: PASSWORD_INVALID, errors: map(error.response.data, error => errorMap[error]) })
      }
      return dispatch({ type: SET_USER_UPDATED, result: false })
    }
  }
}
