// @flow
import React, {
  useReducer, useState, useRef
} from 'react'
import { injectIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import Icon from 'michelangelo/dist/Components/NativeIcon'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Input from 'michelangelo/dist/SharedComponents/Inputs/Input'
import Tabs from 'michelangelo/dist/WebComponents/Navigation/NavigationBar'
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import type { InjectIntlProvidedProps } from 'react-intl'
import { messages } from './i18n/messages'
import styled, { StyledComponent } from 'styled-components'
import { RequirementSteps, Animation, NewButton, Logo } from './Components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Actions from './AlfredApp/actions'
import ReCAPTCHA from 'react-google-recaptcha'
import isEmail from 'validator/lib/isEmail'
import { defaultRecaptchaId, invisibleRecaptchaId } from './utils/config'
import { RESET_EMAIL_DETAILS } from './AlfredApp/actions'

const TextIcon: StyledComponent<{}> = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`
const ErrorMsg: StyledComponent<{}> = styled.div`
  background-color: ${color.danger}20;
  border: 1px solid ${color.danger};
  border-radius: 6px;
  color: ${color.danger};
  padding: 8px;
  margin-bottom: 8px;
`
const CustomText: StyledComponent<{}> = styled.h5`
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.fontcolor};
`
const CustomTextLink: StyledComponent<{}> = styled.span`
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.fontcolor};
  cursor: pointer;
`
const PaddingSpace: StyledComponent<{}> = styled.span`
  padding-left: 3px;
`
const ReCaptchaContainer: StyledComponent<{}> = styled.div`
  padding: 16px 0px;
  display: flex;
  justify-content: center;
`
const ButtonSpacing: StyledComponent<{}> = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
`
const AnimationContainer: StyledComponent<{}> = styled.div`
  z-index: 3;
`
const FormItemContainer: StyledComponent<{}> = styled.div`
  padding: 32px 0px 10px 0px;
  input {
    cursor: pointer !important;
  }
  `
const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: ${(props) => props.isReCaptchaShowing ? '10%' : '10px'};
  padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  padding: 24px 24px 0px 24px;
`
const CloseIconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  @media (max-width: 370px) {
    margin-right: 8px;
    margin-top: 8px;
  }
`
const TabsContainer: StyledComponent<{}> = styled.div`
  display: flex;
  justify-content: center;
`
FormItemContainer.displayName = 'FormItemContainer'
RequirementSteps.displayName = 'RequirementSteps'
NewButton.displayName = 'NewButton'

function Registration ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const [passwordType, setPasswordType] = useState('password')
  const [displayValidator, setDisplayValidator] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [openRegSelected, setOpenRegSelected] = useState(false)
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const [errors, setErrors] = useState([])
  const [isCheckingPassword, setIsCheckingPassword] = useState(false)
  const [isPasswordChecked, setIsPasswordChecked] = useState(false)
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      regCode: '',
      email: '',
      firstName: '',
      lastName: '',
      password: ''
    }
  )
  const { regCode, email, firstName, lastName, password } = userInput
  const navigate = useNavigate()
  const recaptchaRef = useRef()
  const dispatch = useDispatch()
  const accountDetails = useSelector(state => state.account.account)
  const subdomain = useSelector(state => state.account.subdomain)
  const accessToken = useSelector(state => state.accessTokenState.accessToken)
  const fetchingError = useSelector(state => state.errorFetching.isErrorFetchingRegistration)
  const language = useSelector(state => state.account.language)
  const [searchParams] = useSearchParams()

  const customerCareText = language ? '/customer-care?language=' + language : '/customer-care'

  const getSuccessAnimation = () => {
    return (
      <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
        <Column classNames={['is-paddingless', 'is-one-fifth']}>
          <AnimationContainer>
            <Animation onAnimationFinish={handleOnAnimationFinish} animationType='success' />
            <Text align='center' textSize='h1' text={formatMessage(messages.registrationSuccess)} fontColor={color.success} />
          </AnimationContainer>
        </Column>
      </Row>
    )
  }

  const getErrorMessage = () => {
    const errorDiv = (
      <ErrorMsg>
        <CustomText fontcolor={color.danger}>
          {formatMessage(messages.registrationErrorMsg)}
          <PaddingSpace />
          <CustomTextLink textSize='h5' onClick={() => navigate(customerCareText)} fontcolor={color.info}>
            {formatMessage(messages.registrationErrorMsgHere)}
          </CustomTextLink>
        </CustomText>
      </ErrorMsg>
    )
    return fetchingError ? errorDiv : null
  }

  const getTheme = () => {
    const registrationButtonTheme = !validInputs() ? color.disabled : color.success
    const passwordInputTheme = !password ? color.lightTextDefault : (isPasswordChecked && !isValidPassword ? color.danger : color.info)
    const firstNameTheme = fetchingError ? color.danger : (firstName ? color.info : color.lightTextDefault)
    const lastNameTheme = fetchingError ? color.danger : (lastName ? color.info : color.lightTextDefault)
    const emailTheme = fetchingError || (email && !isEmail(email)) ? color.danger : (email ? color.info : color.lightTextDefault)

    return { emailTheme, passwordInputTheme, firstNameTheme, lastNameTheme, registrationButtonTheme }
  }

  const handleOnAnimationFinish = () => {
    if (accountDetails.emailVerification) {
      navigate('/link-sent')
    } else {
      dispatch(Actions.goingToPrivacyPolicy(true))
      navigate('/privacy')
    }
  }

  const handleOnRegisterClick = async (e) => {
    e.preventDefault()
    if (recaptchaRef.current && accountDetails.captchaOption === 'INVISIBLE') {
      if (fetchingError) {
        recaptchaRef.current.reset()
      }
      await recaptchaRef.current.executeAsync()
    }

    if (validInputs()) {
      const regcode = regCode || email
      const result = await dispatch(Actions.registerUser(regcode, email, firstName, lastName, password, (openRegSelected || getRegInput() === null)))

      if (result && result.errors) {
        setErrors(result.errors)
        setIsCheckingPassword(true)
        setIsPasswordChecked(true)
      }
    }
  }

  const handleInputChange = (name: string, value) => {
    if (name !== 'password') {
      setUserInput({ [name]: value })
    } else {
      setIsPasswordChecked(false)
      // $FlowFixMe
      if (fetchingError) dispatch({ type: RESET_EMAIL_DETAILS })
      // $FlowFixMe
      setUserInput({ password: value })
    }
  }

  /**
   * On focus will show the password validator
   */
  const handleOnFocus = () => {
    setDisplayValidator(true)
  }

  /**
   * On blur will hide the password validator
   */
  const handleOnBlur = () => {
    if (!password) {
      setDisplayValidator(false)
    }
  }

  const handleOnPasswordValid = (isValid: boolean) => {
    setIsValidPassword(isValid)
    setIsCheckingPassword(false)
  }

  const handleDisplayPassword = (e: any) => {
    e.preventDefault()
    const isTextOrHide = (passwordType === 'password')
    const newType = (isTextOrHide) ? 'text' : 'password'
    setPasswordType(newType)
  }
  const onTabSelect = ({ index }) => {
    if (index === 0) {
      setOpenRegSelected(false)
    } else {
      setOpenRegSelected(true)
    }
  }
  const getTabs = () => {
    let tabs = null
    if (accountDetails.authTypes.registrationCodeEnabled && accountDetails.authTypes.openRegistrationEnabled) {
      const tabsArray = [{ icon: null, label: formatMessage(messages.registrationTab) }, { icon: null, label: formatMessage(messages.openRegistrationTab) }]
      tabs =
        <TabsContainer>
          <FormItemContainer>
            <Tabs backgroundColor={color.white} borderColor={color.white} onTabClick={onTabSelect} tabList={tabsArray} />
          </FormItemContainer>
        </TabsContainer>
    }
    return tabs
  }
  const handleReCaptchaAction = async (value: string) => {
    if (accountDetails.captchaOption !== 'INVISIBLE') { setRecaptchaValue(value) }
  }
  const validInputs = (withoutRecaptchaValue) => {
    const result = ((getRegInput() === null || regCode !== null) && isEmail(email) && firstName !== null && lastName !== null && password.length > 0 && (accountDetails.captchaOption === 'DISABLED' || accountDetails.captchaOption === 'INVISIBLE' || withoutRecaptchaValue || recaptchaValue !== null))
    return result
  }
  const getReCaptcha = () => {
    const captchaOption = accountDetails.captchaOption
    const showReCaptcha = !defaultRecaptchaId || captchaOption === 'DISABLED' ? false : validInputs(true) || captchaOption === 'INVISIBLE'
    if (!showReCaptcha) return null
    let sitekey = defaultRecaptchaId
    let badge = ''
    let captchaSize = 'normal'

    if (captchaOption === 'INVISIBLE') {
      captchaSize = 'invisible'
      badge = 'inline'
      sitekey = invisibleRecaptchaId
    }
    return (
      <ReCaptchaContainer>
        <ReCAPTCHA
          badge={badge}
          ref={recaptchaRef}
          sitekey={sitekey}
          onChange={handleReCaptchaAction}
          size={captchaSize}
        />
      </ReCaptchaContainer>
    )
  }
  const getRegInput = () => {
    let regInput = null
    if (!openRegSelected && accountDetails.authTypes.registrationCodeEnabled) {
      const regCodeTheme = fetchingError ? color.danger : (regCode ? color.info : color.lightTextDefault)
      regInput =
        <FormItemContainer>
          <Input
            name='regCode'
            inputTheme={regCodeTheme}
            value={regCode}
            label={regcodeLabel}
            type='text'
            onChange={(text) => handleInputChange('regCode', text)}
          />
        </FormItemContainer>
    }
    return regInput
  }
  const getLabels = () => {
    const customTexts = accountDetails.customTexts
    let emailLabel = formatMessage(messages.email)
    let regcodeLabel = formatMessage(messages.regCode)
    const firstNameLabel = formatMessage(messages.firstName)
    const LastNameLabel = formatMessage(messages.lastName)
    const passwordLabel = formatMessage(messages.password)
    const registerButtonLabel = formatMessage(messages.registrationRegister)
    if (customTexts) {
      if (customTexts.registerRegCodeEmailPlaceholder) {
        emailLabel = openRegSelected ? customTexts.registerOpenRegEmailPlaceholder : customTexts.registerRegCodeEmailPlaceholder
      }
      if (customTexts.registerRegCodeAccountPlaceholder) regcodeLabel = customTexts.registerRegCodeAccountPlaceholder
    }
    return { emailLabel, regcodeLabel, firstNameLabel, LastNameLabel, passwordLabel, registerButtonLabel }
  }

  const errorMessage = getErrorMessage()
  const { emailLabel, regcodeLabel, firstNameLabel, LastNameLabel, passwordLabel, registerButtonLabel } = getLabels()
  const { emailTheme, passwordInputTheme, firstNameTheme, lastNameTheme, registrationButtonTheme } = getTheme()
  const userInfo: any = { firstName, lastName, email }
  if (regCode) userInfo.regCode = regCode
  if (accessToken) return getSuccessAnimation()

  const tabs = getTabs()
  const regCodeInput = getRegInput()
  const recaptcha = getReCaptcha()
  return (
    <Row classNames={['is-magrinless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Logo />
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <CloseIconContainer onClick={() => navigate(-1)}>
            <Icon fillColor={color.default} size={24} iconName='close' />
          </CloseIconContainer>
          <form onSubmit={handleOnRegisterClick}>
            <Row classNames={['is-maringless']}>
              <Column className={['is-paddingless', 'is-4', 'is-offset-4']}>
                <HeaderContainer data-cy='headercy'>
                  <Text align='center' textSize='h3' fontColor={color.lightDefaultTheme} text={formatMessage(messages.getStarted)} />
                </HeaderContainer>
              </Column>
            </Row>
            {tabs}
            {regCodeInput}
            <FormItemContainer>
              <Input
                name='email'
                inputTheme={emailTheme}
                value={email}
                label={emailLabel}
                type='text'
                onChange={(text) => handleInputChange('email', text)}

              />
            </FormItemContainer>
            <FormItemContainer>
              <Input
                name='firstName'
                inputTheme={firstNameTheme}
                value={firstName}
                label={firstNameLabel}
                type='text'
                onChange={(text) => handleInputChange('firstName', text)}
              />
            </FormItemContainer>
            <FormItemContainer>
              <Input
                name='lastName'
                inputTheme={lastNameTheme}
                value={lastName}
                label={LastNameLabel}
                type='text'
                onChange={(text) => handleInputChange('lastName', text)}
              />
            </FormItemContainer>
            <FormItemContainer>
              <Input
                name='password'
                _lr-hide
                inputTheme={passwordInputTheme}
                onClickIcon={handleDisplayPassword}
                type={passwordType === 'password' ? 'password' : 'text'}
                iconName={passwordType === 'password' ? 'eyeClose' : 'eyeOpen'}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                value={password}
                label={passwordLabel}
                onChange={(text) => {
                  handleInputChange('password', text)
                }}
                testSetPassword={(pass) => {
                  setUserInput({ password: pass })
                }}
              />
            </FormItemContainer>
            {errorMessage}
            <RequirementSteps
              onUpdate={handleOnPasswordValid}
              password={password}
              repeatPassword={password}
              displayValidator={displayValidator}
              subdomain={subdomain}
              userInfo={userInfo}
              fromRegistration
              isCheckingPassword={isCheckingPassword}
              errors={errors}
            />
            {recaptcha}
            <Row classNames={['is-marginless', 'is-mobile']}>
              <ButtonContainer>
                <Column classNames={['is-paddingless', 'is-full']}>
                  <NewButton pill type='submit' ariaLabel={registerButtonLabel} fill disabled={!validInputs()} themeColor={registrationButtonTheme} title={registerButtonLabel} />
                </Column>
              </ButtonContainer>
            </Row>
          </form>
          <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
            <ButtonSpacing onClick={() => navigate(`/?${searchParams.toString()}`)}>
              <Text align='center' textSize='p' fontColor={color.grey800} text={formatMessage(messages.registrationCurrentUser)} />
              <TextIcon classNames={['is-paddingless']}>
                <Text align='center' textSize='p' fontColor={color.info} cursor text={formatMessage(messages.registrationLoginHere)} />
              </TextIcon>
            </ButtonSpacing>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}
export default injectIntl(Registration)
