// @flow

import {
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN
} from './actions'

import type { Action } from './actions'

export type AccessTokenStateType = {
  accessToken: string,
  refreshToken: string
}

export const initialAccessTokenState: AccessTokenStateType = {
  accessToken: '',
  refreshToken: ''
}

export default function accessTokenState (state: AccessTokenStateType = initialAccessTokenState, action: Action): AccessTokenStateType {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.result
      })
    case SET_REFRESH_TOKEN:
      return Object.assign({}, state, {
        refreshToken: action.result
      })
    default:
      return state
  }
}
