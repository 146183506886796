// @flow

import { combineReducers } from 'redux'
import type { CombinedReducer, Reducer } from 'redux'
// $FlowFixMe
import { persistReducer } from 'redux-persist'
// $FlowFixMe
import storage from 'redux-persist/lib/storage'
import type { Action } from './actions'
import type { StatusStateType } from './statusReducer'
import type { UserStateType } from './userReducer'
import type { FetchingType, ErrorFetchingType } from './fetchingReducer'
import type { AccessTokenStateType } from './accessTokenReducer'
import type { AccountStateType } from '../LetsGetStarted/reducer'
import user from './userReducer'
import account from '../LetsGetStarted/reducer'
import status from './statusReducer'
import { fetching, errorFetching } from './fetchingReducer'
import accessTokenState from './accessTokenReducer'
export type StateType = {
  account: AccountStateType,
  user: UserStateType,
  fetching: FetchingType,
  errorFetching: ErrorFetchingType,
  status: StatusStateType,
  accessTokenState: AccessTokenStateType
}

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: 'mfaRequired'
}

const alfredAppReducer: CombinedReducer<StateType, Action> = combineReducers({
  account,
  user: persistReducer(userPersistConfig, user),
  fetching,
  errorFetching,
  status,
  accessTokenState
})
const rootReducer: Reducer<StateType, Action> = (state?: StateType, action: Action) => {
  if (action.type === 'RESET_APP_STATE') {
    state = undefined
  }
  return alfredAppReducer(state, action)
}
export default rootReducer
