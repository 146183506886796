/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/** Michelangelo Components */
/* eslint-disable */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import WebIcon from 'michelangelo/dist/Components/Icon'
import InfoModal from 'michelangelo/dist/SharedComponents/InfoModal'
import FixedLabelInput from 'michelangelo/dist/Components/FixedLabelInput'
import { useDispatch } from 'react-redux'
import * as Actions from './AlfredApp/actions'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { messages } from './i18n/messages'
import employeeAppLogo from './Images/employeeApp-logo.png'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import theme from 'michelangelo/dist/Components/styles/theme'

// Riffraff
import { ConsolePlainStream } from '@browser-bunyan/console-plain-stream'
import { createLogger, INFO, WARN, ERROR } from 'browser-bunyan'
import { Riffraff } from '@indicium/leonardo'
import { riffraffHost, riffraffProtocol, riffraffPort, env } from './utils/config'
import Input from 'michelangelo/dist/SharedComponents/Inputs/Input'

const url = `${riffraffProtocol}://${riffraffHost}:${riffraffPort}`

const logger = createLogger({
  name: `alfred ${env}`,
  streams: [{
    level: INFO,
    stream: new ConsolePlainStream()
  }, {
    level: WARN,
    stream: new ConsolePlainStream()
  },
  {
    level: ERROR,
    stream: new ConsolePlainStream()
  }]
})

const riffraff = new Riffraff(url, logger)

const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
  padding-bottom: 16px;
  display: flex;
`
const InputContainer: StyledComponent<{}> = styled.div`
  padding: 40px 0px 8px 0px;
  width: 100%;
  max-width: 100%;
  input {
    cursor: pointer !important;
    width: 100%;
  }

  div {
    font-weight: 400 !important;
    font-family: 'Roboto';
    
    label {
      color: #758192 !important;
    }
  }

  article:nth-child(1) {
    max-width: 100%;
  }
  
  .r-width-13qz1uu, .r-minWidth-xdb040, .r-xdb040 {
    border-color: #AEB5BC;
  }
`

const GenericHeaderContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 30px;
  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`

const EmployeeAppLogo: StyledComponent<{}> = styled.img`
  max-height: 38px;
  vertical-align: middle;
  margin-left: 5px;
  @media (max-width: 768px) {
    margin-top: 86px;
  }
`

export const InputLabel: StyledComponent<{}> = styled.div`
  color: #A3AEBD;
  font-weight: 700;
  font-family: Roboto;
  margin-bottom: .5rem;
`

const FormWrapper: StyledComponent<{}> = styled.div`
  padding: 1rem 23%;
`

const SuccessMessage : StyledComponent<{}> = styled.div`
  padding: 2rem 10%;
`
const SuccessContainer : StyledComponent<{}> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
`

const SuccessHeader : StyledComponent<{}> = styled.div`
  font-weight: 700;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 1rem;
`

const CheckboxContainer: StyledComponent<{}> = styled.div`
  margin-top: 2rem;
  word-break: break-all;
`

const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: 50px;
  div, button {
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'}
  }
`

const LoadingContainer: StyledComponent<{}> = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
`
const ModalBackground: StyledComponent<{}> = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: ${(props) => props.show ? 'block' : 'none'};
`
const ModalContainer: StyledComponent<{}> = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => props.show ? 'block' : 'none'};
`

const ErrorInput: StyledComponent<{}> = styled.div`
  border: 1px solid #F07B7B;
  border-radius: 6px;
  padding: 10px 7px;
  background: rgba(240, 123, 123, 0.2);
  color: #F07B7B;
  margin-top: 50px;
`

type LoginProps = {
    onChangeLang: Function
  } & InjectIntlProvidedProps

const MobileRegistration = ({ intl: { formatMessage }, onChangeLang }: LoginProps) => {
  const [organizationId, setOrganizationId] = useState('')
  const [accountOrEmail, setAccountOrEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const fieldsCompleted = organizationId !== '' && accountOrEmail !== '' && phoneNumber !== '' && isChecked

  const [showGenericModal, setShowGenericModal] = useState(false)
  const [genericLoading, setGenericLoading] = useState(false)
  const [genericModalData, setGenericModalMessage] = useState('')
  const [buttonEnabled, setButtonEnabled] = useState(!!fieldsCompleted)
  const [validationError, setValidationError] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [serverError, setServerError] = useState('')

  const dispatch = useDispatch()

  const getTheme = () => {
    let inputTheme = color.lightTextDefault
    let buttonTheme = color.disabled
    let disabled = true

    const characterPresent = false

    if (characterPresent) {
      inputTheme = color.info
      buttonTheme = color.success
      disabled = false
    }
    return { inputTheme, buttonTheme, disabled }
  }

  useEffect(() => {
    if (fieldsCompleted) {
      setButtonEnabled(true)
    } else {
      setButtonEnabled(false)
    }
  }, [fieldsCompleted])

  const { inputTheme } = getTheme()

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  const handleOnRegisterClick = async () => {

    if (!fieldsCompleted) {
      setButtonEnabled(true)
    }

    let newPhoneNumber = phoneNumber.replace(/[^a-zA-Z0-9 ]/g, '');

    newPhoneNumber = '+1' + newPhoneNumber

    if (!newPhoneNumber.match(/^\+[1-9]\d{10,10}$/)) {
      return setValidationError(true)
    } else {
      setValidationError(false)
    }
    await riffraff.mobileRegistration(newPhoneNumber.trim(), accountOrEmail.trim(), organizationId.trim()).then(data => {
      if (data && data.success) {
          setMessageSuccess(true)
        }
      }).catch(error => {
        setServerError('Error, one or more of the inputs are invalid, please try again.')
    })
  }

  const handleOrganiationIdChange = (value) => {
    setOrganizationId(value)
  }

  const handleAccountOrEmailChange = (value) => {
    setAccountOrEmail(value)
  }

  const handlePhoneNumberChange = (value, event) => {
    let newValue = value.replace(/[^a-zA-Z0-9 ]/g, '');

    const isNumber = !isNaN(Number(newValue))
    if (isNumber) {
      setPhoneNumber(value.trim())
    }
  }

  const handleKeyDown = (e) => {
    var key = e.keyCode || e.charCode;
    const isBackSpaceKey = key == 8 || key == 46

      if (key !== 189) {
        if(isBackSpaceKey) {
          setPhoneNumber(e.target.value.trim())
        } else if ((phoneNumber.trim().length === 3)) {
          setPhoneNumber(phoneNumber.trim()+ '-')
        } else if ((phoneNumber.trim().length === 7)) {
          setPhoneNumber(phoneNumber.trim() + '-')
        } else {
          setPhoneNumber(e.target.value)
        }
      }
  }

  const handleGenericInfoClick = async () => {
    setShowGenericModal(true)
    setGenericLoading(true)
    const { message: { genericLoginOrgIDHelpText } } = await riffraff.getMessage()
    setGenericModalMessage(genericLoginOrgIDHelpText.en)
    setGenericLoading(false)
  }

  const onGenericModalClose = () => {
    closeGenericModal()
  }

  const closeGenericModal = () => {
    setShowGenericModal(false)
  }

  const loadingSection = (
    <LoadingContainer>
      <WebIcon color={color.grey600} iconName='spinner' pulse />
    </LoadingContainer>
  )

  if (messageSuccess) {
    return (
      <Row classNames={['is-marginless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <SuccessMessage>
          <GenericHeaderContainer data-cy='headercy'>
            <Row classNames={['is-marginless']}>
                <Column classNames={['center']}>
                  <EmployeeAppLogo data-cy='logoheadercy' src={employeeAppLogo} alt={formatMessage(messages.genericStart)} />
                </Column>
            </Row>
          </GenericHeaderContainer>
          <SuccessContainer data-cy='successmessagecy'>
            <SuccessHeader>You have successfully subscribed for SMS.</SuccessHeader>
            <p>Please confirm your registration for SMS updates by replying YES to the Opt-In message that you will receive momentarily on your mobile device.</p>
          </SuccessContainer>
        </SuccessMessage>
      </Column>
    </Row>
    )
  }

  return (
    <Row classNames={['is-marginless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <FormWrapper>
          <GenericHeaderContainer data-cy='headercy'>
            <Row classNames={['is-marginless']}>
                <Column classNames={['center']}>
                  <EmployeeAppLogo data-cy='logoheadercy' src={employeeAppLogo} alt={formatMessage(messages.genericStart)} />
                </Column>
            </Row>
          </GenericHeaderContainer>
          <form>
            <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
              <HeaderContainer data-cy='headercy'>
                <Text align='center' textSize='h3' fontColor={color.lightTextDefault} text={formatMessage(messages.getStarted)} />
              </HeaderContainer>
            </Row>
            <InputContainer>
                <FixedLabelInput
                  label={formatMessage(messages.orgId)}
                  inputTheme={inputTheme}
                  value={organizationId}
                  type='text'
                  onChange={handleOrganiationIdChange}
                  iconName='info'
                  onClickIcon={handleGenericInfoClick}
                  style={{ width: '100%' }}
                />
            </InputContainer>
            <InputContainer>
                <FixedLabelInput
                  inputTheme={inputTheme}
                  label={formatMessage(messages.accountOrEmail)}
                  value={accountOrEmail}
                  type='text'
                  onChange={handleAccountOrEmailChange}
                  style={{ width: '100%' }}
                />
            </InputContainer>
            <InputContainer>
              <div style={{ display: 'flex', position: 'relative' }}>
                <FixedLabelInput
                    label={formatMessage(messages.mobilePhoneNr)}
                    inputTheme={inputTheme}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onKeyDown={handleKeyDown}
                    style={{ width: '100%' }}
                  />
              </div>
            </InputContainer>
          </form>
          <CheckboxContainer>
            <input data-cy='termscheckboxcy' type="checkbox" name="terms" value={isChecked} onChange={handleCheckboxChange}/>
            <label style={{ marginLeft: '.5rem' }}>{formatMessage(messages.terms)} <a href="https://app.theemployeeapp.com/signup/privacy.html" style={{ textDecoration: 'underline', color: '#4D5665' }}>(https://app.theemployeeapp.com/signup/privacy.html)</a></label>
          </CheckboxContainer>
          {validationError && <ErrorInput data-cy='inputerrorcy'>{formatMessage(messages.mobileValidationError)}</ErrorInput> }
          {serverError && <ErrorInput data-cy='servererrorcy'>{serverError}</ErrorInput> }
          <ButtonContainer disabled={!buttonEnabled}>
            <Button fullWidth textTransform={'capitalize'} fill disabled={!buttonEnabled} style={'success'} title={formatMessage(messages.registrationRegister)} onClick={handleOnRegisterClick} />
          </ButtonContainer>
        </FormWrapper>
      </Column>
      <ModalBackground show={showGenericModal} onClick={onGenericModalClose}>
        <ModalContainer show={showGenericModal}>
          {!genericLoading
            ? <InfoModal
              body={genericModalData}
              cancelAction={closeGenericModal}
              show={showGenericModal}
              cancelText={formatMessage(messages.close)}
            />
            : loadingSection }
        </ModalContainer>
      </ModalBackground>
    </Row>
  )
}
export default injectIntl(MobileRegistration)
