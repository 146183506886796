// @flow

import React, { useEffect } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Actions from './AlfredApp/actions'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
/** Michelangelo Components */
import PrivacyPolicy from 'michelangelo/dist/Components/PrivacyPolicy'
import color from 'michelangelo/dist/Components/styles/color'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

// export for tests
export const apprisePrivacyPolicyMessages = defineMessages({
  appriseMobile: {
    id: 'apprisePrivacyPolicy.appriseMobile',
    defaultMessage: 'theEMPLOYEEapp'
  },
  privacyPolicyHeader: {
    id: 'apprisePrivacyPolicy.privacyPolicyHeader',
    defaultMessage: 'Privacy Policy'
  },
  privacyPolicyBody: {
    id: 'apprisePrivacyPolicy.privacyPolicyBody',
    defaultMessage: 'This privacy policy (the "Privacy Policy") is maintained by theEMPLOYEEapp, which is referred to in this Privacy Policy as “we,” “our,” or “us.”  The organization that provides you access to theEMPLOYEEapp is referred to in this Privacy Policy as “the entity providing this app” and may have additional privacy terms that apply to your use of the app.\n\n' +
        'We take the privacy of those who use this app as one of our most important and serious priorities. Your privacy as a user of our app is important to you and to us. It is our commitment that we will protect the information contained within this app.\n\n' +
        'To protect your privacy, we follow different principles in accordance with worldwide practices for customer privacy and data protection. This Privacy Policy has been compiled to better serve those who are concerned with how their personally identifiable or corporate information is being used either on its own or with other information. Please read our Privacy Policy carefully to understand how we collect, use, protect or otherwise handle your information with respect to the services provided by this app ("Services").\n\n' +
        'This Privacy Policy was last updated on January 27, 2021. If it changes, the revised policy will include a new effective date and will be posted on this page. If we make any material changes to the Privacy Policy, we will notify the entity who is providing this app, or we may notify you through a broadcast alert on your app.\n\n' +
        'By using the Services and/or continuing to use of the Services following the posting of any amendment constitutes your agreement of the Privacy Policy and acceptance of any changes to the Privacy Policy.\n\n' + '' +
        'We follow generally accepted standards to protect the personal data submitted to us, both during transmission and once we receive it. For more information about our security practices, please contact the company providing this app or our Customer Care department.\n\n' +
        'In order to download and login to this app, you will have to set up an account and register. In doing so, you will be required to create an account and in doing so provide the following information: your account ID (determined by the company providing this app), a first and last name, any email address and a password that you will create (collectively referred to as the "End User Information").\n\n' +
        'We will never give, share or sell the End User Information to any person or entity except as provided in this Privacy Policy. We may, however, use the End User Information for the following purposes:\n\n' +
        ' • Providing and enabling the use of the Services.\n\n' +
        ' • Understanding and improving our product and offering.\n\n' +
        ' • Investigating and preventing our products and offering from being subject to fraud and abuse.\n\n' +
        ' • Ensuring compliance with the terms of an agreement between theEMPLOYEEapp and the company providing this app.\n\n' +
        ' • Complying with any legal process, regulations, laws or government requests.\n\n' +
        ' • Protecting our company, the company providing this app and the public from harm or illegal activity or to respond to an emergency that we believe requires us to provide the End User Information so as to avoid someone\'s death or serious injury.\n\n' +
        ' • Where third parties that we work with or on behalf of require access to your information to carry out their work (e.g. customer support, billing, etc.).\n\n' +
        ' • If we engage in any merger or acquisition transaction that involves transfer of information to an acquiring or acquired entity.\n\n' +
        ' • For other purposes about which we notify you.\n\n' +
        'When performing certain actions within this App, you may be prompted to give access to the following. You may opt out of each action.\n\n' +
        ' • Identity\n' +
        ' • Calendar\n' +
        ' • Photos/Media/Files\n' +
        ' • Microphone\n' +
        ' • Wifi connection\n' +
        ' • Device ID\n\n' +
        'The access would be required for the following actions within the App:\n\n' +
        ' • The Identity request is solely to determine who the account belongs to.\n\n' +
        ' • The Calendar request seeks permission to access your calendar so that the app can add items of your choice to your calendar.\n\n' +
        ' • The request for Photos/Media/Files allows you to save files that are included in your app for offline use. To do this, the app needs access to your files. The app does not alter your information. It simply allows you to save a file of your choice for access at a later time, even when an internet connection is not available.\n\n' +
        ' • The Microphone request is used for live events or to record videos, which this app can support.\n\n' +
        ' • The Wifi connection request is to determine if you are on a Wifi or cellular connection for optimal performance of the app.\n\n' +
        ' • The Device ID request allows you to receive push notifications within the app.\n\n' +
        'For purposes of analytics, our platform may also have access to device information such as device type, version, operating system, and browser type and version (if applicable) as well as information regarding your use of the Services. You agree that we may analyze your use of the Services. ' +
        'This may include but is not limited to assisting in resolving issues that may be based on the device you are using, analysis of how you utilize the App, the content you view, time spent on the content and your use of different App features. We may also obtain information from third parties, including Google Analytics, and combine that with information we collect. We may then share such analysis in a manner that is aggregated and non identifiable to you with the company that has provided you with the App. We also have the right to solicit feedback from you.' +
        'In using this app, you consent to us using and sharing this information in a manner that is aggregated and non identifiable. You also agree to us soliciting feedback and sharing such feedback in a manner that is aggregated and non identifiable.\n\n' +
        'We welcome your questions or comments regarding this Privacy Policy. To do so you can email CustomerCare@theemployeeapp.com.\n'
  },
  checkBoxText: {
    id: 'apprisePrivacyPolicy.checkBoxText',
    defaultMessage: 'I have read and acknowledge the privacy policy'
  },
  buttonTitle: {
    id: 'apprisePrivacyPolicy.buttonTitle',
    defaultMessage: 'Accept'
  }
})

const ParentContainer: StyledComponent<{}> = styled.div`
  background-color: ${color.white};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 360px) {
    width: 300px;
  }
`

const Container: StyledComponent<{}> = styled.div`
  width: 100%;
  height: 90%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

function ApprisePrivacyPolicy ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let redirectedOnce = false

  const hasAcceptedApprisePolicy = useSelector(state => state.user.hasAcceptedApprisePolicy)
  const accessToken = useSelector(state => state.accessTokenState.accessToken)

  if (isEmpty(accessToken)) {
    navigate('/?no-token')
  }

  useEffect(() => {
    setTimeout(() => dispatch(Actions.goingToPrivacyPolicy(false)), 600)
    dispatch(Actions.setUserUpdated(false))
  })

  useEffect(() => {
    redirectIfAccepted()
  })

  const redirectIfAccepted = () => {
    if (hasAcceptedApprisePolicy && !redirectedOnce) {
      redirectedOnce = true
      dispatch(Actions.redirectToCallbackUrl())
    }
  }

  const acceptPrivacyPolicyOrRedirect = () => {
    if (hasAcceptedApprisePolicy) dispatch(Actions.redirectToCallbackUrl())
    else dispatch(Actions.acceptApprisePolicy())
  }

  const getPrivacyPolicy = (formatMessage: Function) => {
    if (hasAcceptedApprisePolicy === false) {
      const companyName = formatMessage(apprisePrivacyPolicyMessages.appriseMobile)
      const privacyPolicyHeader = formatMessage(apprisePrivacyPolicyMessages.privacyPolicyHeader)
      const privacyPolicyBody = formatMessage(apprisePrivacyPolicyMessages.privacyPolicyBody)
      const checkBoxText = formatMessage(apprisePrivacyPolicyMessages.checkBoxText)
      const buttonTitle = formatMessage(apprisePrivacyPolicyMessages.buttonTitle)
      return (
        <PrivacyPolicy
          accountName={companyName}
          privacyPolicyHeader={privacyPolicyHeader}
          privacyPolicyText={privacyPolicyBody}
          checkBoxText={checkBoxText}
          buttonTitle={buttonTitle}
          buttonColor={color.success}
          buttonOnClick={acceptPrivacyPolicyOrRedirect}
        />
      )
    }
  }

  const privacyPolicy = getPrivacyPolicy(formatMessage)
  return (
    <ParentContainer>
      <Container>
        {privacyPolicy}
      </Container>
    </ParentContainer>
  )
}

export default (injectIntl(ApprisePrivacyPolicy))
export { ApprisePrivacyPolicy as UnconnectedApprisePrivacyPolicy }
