// @flow

import React, {
  Component
} from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { Animation } from '../Components/index'

import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/NativeText'

const activationMessages = defineMessages({
  checkEmail: {
    id: 'activation.checkEmail',
    defaultMessage: 'Please check your email'
  },

  linkSentHeader: {
    id: 'activation.linkSent',
    defaultMessage: 'Activation Link Sent'
  }
})

const LinkContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
`

const AnimationContainer: StyledComponent<{}> = styled.div`
  width: 100%;
  z-index: 3;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
`

// add for testing purpose
LinkContainer.displayName = 'LinkContainer'
AnimationContainer.displayName = 'AnimationContainer'
HeaderContainer.displayName = 'HeaderContainer'

class EmailVerificationLinkSent extends Component<InjectIntlProvidedProps, {}> {
  render () {
    const { intl: { formatMessage } } = this.props
    const headerText = formatMessage(activationMessages.linkSentHeader)

    return (
      <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
        <Column classNames={['is-paddingless', 'is-12']}>
          <HeaderContainer>
            <Text align='center' textSize='h2' fontColor={color.lightTextDefault} text={headerText} />
          </HeaderContainer>
          <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
            <Column classNames={['is-paddingless', 'is-one-fifth', 'is-centered']}>
              <AnimationContainer>
                <Animation animationType='success' />
              </AnimationContainer>
            </Column>
          </Row>
          <LinkContainer>
            <Text align='center' textSize='h2' fontColor={color.success} text={formatMessage(activationMessages.checkEmail)} />
          </LinkContainer>
        </Column>
      </Row>
    )
  }
}

export default (injectIntl(EmailVerificationLinkSent))
export { EmailVerificationLinkSent as UnwrappedEmailVerificationLinkSent }
