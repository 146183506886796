// @flow
import axios from 'axios'

const pingSSO = async (url: string) => {
  // eslint-disable-next-line prefer-regex-literals
  const urlRule = new RegExp('(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]')
  if (!urlRule.test(url)) return false
  const request = axios.create({
    baseURL: url,
    timeout: 5000,
    crossDomain: true,
    validateStatus: () => true
  })
  try {
    const response = await request.head()
    if (response) {
      if (response.status) {
        const codeRule = /^[1-5][0-9][0-9]$/
        return codeRule.test(response.status)
      }
    }
    return false
  } catch (error) {
    if (error.response) {
      console.log('error.response.toJSON() :', error.response.toJSON())
      console.log('error data :', error.response.data)
      console.log('error status:', error.response.status)
      console.log('error headers:', error.response.headers)
    } else if (error.request) {
      /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
      console.log('error :', error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.toJSON())
    }
    return false
  }
}
// backup solutions for ping sso endpoint
// export const isSiteOnline = (url,callback) => {
//     // try to load favicon
//     var timer = setTimeout(function(){
//         // timeout after 5 seconds
//         callback(false);
//     },5000)
//
//     const img = document.createElement("img");
//     img.onload = function() {
//         clearTimeout(timer);
//         callback(true);
//     }
//
//     img.onerror = function() {
//         clearTimeout(timer);
//         callback(false);
//     }
//
//     img.src = url+"/favicon.ico";
// }
export default pingSSO
