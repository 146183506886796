// @flow

import React from 'react'
import lottie from 'lottie-web'
import styled from 'styled-components'
import { isFunction } from 'lodash'
import type { StyledComponent } from 'styled-components'

const Container: StyledComponent<{}> = styled.div`
  width: 100%;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    /* This is for scalling svg properlly do not remove unless you know what you're about to do */
    svg {
      height: 50vh !important;
      width: auto !important;
    }
 }
`

const animations = {
  success: '/success-check.json',
  bubbles: '/color-bubbles.json',
  email: '/success-email.json'
}

type AnimationProps = {
  animationType: string,
  loop?: boolean,
  autoplay?: boolean,
  onAnimationFinish?: () => void,
  speed?: number
}

class Animation extends React.Component<AnimationProps, {}> {
  props: AnimationProps
  animation: HTMLDivElement

  componentDidMount () {
    const animation = lottie.loadAnimation({
      container: this.animation, // the dom element that will contain the animation
      renderer: 'svg',
      loop: this.props.loop || false,
      autoplay: this.props.autoplay || true,
      path: animations[this.props.animationType] // the path to the animation json
    })
    if (isFunction(this.props.onAnimationFinish)) {
      animation.addEventListener('complete', this.props.onAnimationFinish)
    }
    if (this.props.speed) {
      animation.setSpeed(this.props.speed)
    }
  }

  animationRef = (el: HTMLDivElement) => {
    this.animation = el
  }

  render () {
    return (
      // $FlowFixMe
      <Container ref={this.animationRef} />
    )
  }
}

export default Animation
