// @flow

import React, { useEffect } from 'react'
import qs from 'qs'
import * as Actions from '../AlfredApp/actions'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

function VerifyEmail () {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const query = location.search
    const queryParams = qs.parse(query.replace(/^.*\?/, ''))
    const token = queryParams.token
    if (token) {
      const verify = dispatch(Actions.verifyEmail(token))
      if (verify && verify.type && verify.type === 'USER_FETCHING_ERROR') {
        navigate('/resend-email-verification', {
          state: { oldToken: token }
        })
      } else {
        navigate('/verification-success')
      }
    }
  })

  return (
    <div />
  )
}

export default (VerifyEmail)
