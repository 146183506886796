// @flow

import {
  GET_ACCOUNT_DETAILS,
  SET_URL_QUERY_PARAMS
} from '../AlfredApp/actions'
import type { Action } from '../AlfredApp/actions'
import type { AccountInfo } from '@indicium/leonardo/src/connectors/riffraff'
export type AccountStateType = {
  account: ?AccountInfo,
  subdomain: string,
  callbackUrl: string,
  appVersion: string,
  bypassSSO: boolean,
  isGenericFlow: boolean,
  ssoOrNativeRedirect: {
    enable: boolean,
    url: string
  },
  showRegistrationLink: boolean,
  language: string
}

export const initialAccountState: AccountStateType = {
  account: null,
  subdomain: '',
  callbackUrl: '',
  appVersion: '',
  bypassSSO: false,
  isGenericFlow: false,
  ssoOrNativeRedirect: {
    enable: false,
    url: ''
  },
  showRegistrationLink: true,
  language: ''
}

export default function account (state: AccountStateType = initialAccountState, action: Action): AccountStateType {
  switch (action.type) {
    case GET_ACCOUNT_DETAILS:
      return Object.assign({}, state, {
        account: action.result,
        subdomain: action.result ? action.result.subDomain : '',
        showRegistrationLink: action.result && action.result.showRegistrationLink !== undefined ? action.result.showRegistrationLink : true,
        ssoOrNativeRedirect: action.result && action.result.ssoOrNativeRedirect
          ? action.result.ssoOrNativeRedirect
          : {
              enable: false,
              url: ''
            }
      })
    case SET_URL_QUERY_PARAMS:
      return Object.assign({}, state, {
        callbackUrl: action.result.callbackUrl,
        appVersion: action.result.appVersion,
        bypassSSO: action.result.bypassSSO,
        subdomain: action.result ? action.result.subdomain : '',
        isGenericFlow: action.result.isGenericFlow,
        language: action.result.language
      })
    default:
      return state
  }
}
