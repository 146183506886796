// @flow

import {
  SET_REG_CODE,
  RESET_EMAIL_DETAILS,
  ACCOUNT_FETCHING_ERROR,
  USER_FETCHING_ERROR,
  PASSWORD_INVALID,
  REGISTRATION_FETCHING_ERROR,
  ACCOUNT_FETCH_REQUEST_STARTED,
  ACCOUNT_FETCH_REQUEST_DONE,
  USER_FETCH_REQUEST_STARTED,
  CHECK_PASSWORD_REQUEST_STARTED,
  CHECK_PASSWORD_REQUEST_DONE,
  USER_FETCH_REQUEST_DONE,
  REGISTRATION_FETCH_REQUEST_STARTED,
  REGISTRATION_FETCH_REQUEST_DONE,
  REGISTER_USER_DETAILS,
  RESET_USER_DETAILS,
  RESET_GETSTARTED,
  RESET_USER_ERROR,
  CHANGE_EMAIL_ERROR,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_ERROR_RESET,
  GOING_TO_PRIVACY_POLICY,
  LOADING_SSO_LOGIN,
  VALIDATE_PASSWORD_REQUEST_STARTED,
  VALIDATE_PASSWORD_REQUEST_DONE
} from './actions'
import type { Action } from './actions'

export type FetchingType = {
  isAccountFetching: boolean,
  isRegistrationFetching: boolean,
  isUserFetching: boolean,
  isGoingToPrivacyPolicy?: ?boolean,
  isCheckingPassword?: ?boolean,
  authUrl?: ?string
}

export type ErrorFetchingType = {
  isErrorFetchingAccount: boolean,
  isErrorFetchingRegistration: boolean,
  isErrorFetchingUser: boolean,
  changeEmailTryAgain: boolean,
  changePasswordTryAgain: boolean,
  invalidPassword: boolean,
  errors: Array<string>
}

export const initialFetchingState: FetchingType = {
  isAccountFetching: false,
  isRegistrationFetching: false,
  isUserFetching: false
}
export const initialErrorFetchingState: ErrorFetchingType = {
  isErrorFetchingAccount: false,
  isErrorFetchingRegistration: false,
  isErrorFetchingUser: false,
  changeEmailTryAgain: false,
  changePasswordTryAgain: false,
  invalidPassword: false,
  errors: []
}

export function errorFetching (state: ErrorFetchingType = initialErrorFetchingState, action: Action): ErrorFetchingType {
  switch (action.type) {
    case ACCOUNT_FETCH_REQUEST_STARTED:
      return Object.assign({}, state, {
        isErrorFetchingAccount: false
      })
    case ACCOUNT_FETCHING_ERROR:
      return Object.assign({}, state, {
        isErrorFetchingAccount: true
      })
    case USER_FETCH_REQUEST_STARTED:
      return Object.assign({}, state, {
        isErrorFetchingUser: false
      })
    case CHECK_PASSWORD_REQUEST_STARTED:
      return Object.assign({}, state, {
        isErrorFetchingUser: false
      })
    case USER_FETCHING_ERROR:
      return Object.assign({}, state, {
        isErrorFetchingUser: true
      })
    case PASSWORD_INVALID:
      return Object.assign({}, state, {
        isErrorFetchingUser: true,
        invalidPassword: true,
        errors: action.errors
      })
    case REGISTRATION_FETCH_REQUEST_STARTED:
      return Object.assign({}, state, {
        isErrorFetchingRegistration: false
      })
    case REGISTRATION_FETCHING_ERROR:
      return Object.assign({}, state, {
        isErrorFetchingRegistration: true
      })
    case RESET_EMAIL_DETAILS:
      return Object.assign({}, state, {
        isErrorFetchingRegistration: false
      })
    case RESET_USER_DETAILS:
      return Object.assign({}, state, {
        isErrorFetchingUser: false
      })
    case RESET_USER_ERROR:
      return Object.assign({}, state, {
        isErrorFetchingUser: false
      })
    case REGISTER_USER_DETAILS:
      return Object.assign({}, state, {
        isErrorFetchingRegistration: false
      })
    case RESET_GETSTARTED:
      return Object.assign({}, state,
        initialErrorFetchingState
      )
    // $FlowFixMe
    case CHANGE_EMAIL_ERROR:
      return Object.assign({}, state, {
        changeEmailTryAgain: true
      })
    // $FlowFixMe
    case CHANGE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        changePasswordTryAgain: true
      })
    // $FlowFixMe
    case CHANGE_PASSWORD_ERROR_RESET:
      return Object.assign({}, state, {
        changePasswordTryAgain: false
      })
    default:
      return state
  }
}
export function fetching (state: FetchingType = initialFetchingState, action: Action): FetchingType {
  switch (action.type) {
    case ACCOUNT_FETCH_REQUEST_STARTED:
      return Object.assign({}, state, {
        isAccountFetching: true
      })
    case ACCOUNT_FETCH_REQUEST_DONE:
      return Object.assign({}, state, {
        isAccountFetching: false
      })
    case SET_REG_CODE:
      return Object.assign({}, state, {
        isRegistrationFetching: false
      })
    case REGISTRATION_FETCH_REQUEST_STARTED:
      return Object.assign({}, state, {
        isRegistrationFetching: true
      })
    case REGISTRATION_FETCH_REQUEST_DONE:
      return Object.assign({}, state, {
        isRegistrationFetching: false
      })
    case USER_FETCH_REQUEST_STARTED:
      return Object.assign({}, state, {
        isUserFetching: true
      })
    case CHECK_PASSWORD_REQUEST_STARTED:
      return Object.assign({}, state, {
        isUserFetching: false,
        isCheckingPassword: true
      })
    case CHECK_PASSWORD_REQUEST_DONE:
      return Object.assign({}, state, {
        isUserFetching: false,
        isCheckingPassword: false
      })
    case VALIDATE_PASSWORD_REQUEST_STARTED:
      return Object.assign({}, state, {
        isUserFetching: false,
        isCheckingPassword: true
      })
    case VALIDATE_PASSWORD_REQUEST_DONE:
      return Object.assign({}, state, {
        isUserFetching: false,
        isCheckingPassword: false
      })
    case USER_FETCH_REQUEST_DONE:
      return Object.assign({}, state, {
        isUserFetching: false
      })
    case REGISTER_USER_DETAILS:
      return Object.assign({}, state, {
        isRegistrationFetching: false
      })
    case GOING_TO_PRIVACY_POLICY:
      return Object.assign({}, state, {
        isGoingToPrivacyPolicy: action.result
      })
    case LOADING_SSO_LOGIN:
      return Object.assign({}, state, {
        isAccountFetching: true,
        authUrl: action.authUrl
      })
    default:
      return state
  }
}
