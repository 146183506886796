// @flow

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  firstName: {
    id: 'registrationName.firstName',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'registrationName.lastName',
    defaultMessage: 'Last name'
  },
  back: {
    id: 'app.back',
    defaultMessage: 'back'
  },
  cancel: {
    id: 'app.cancel',
    defaultMessage: 'cancel'
  },
  registrationRegister: {
    id: 'registration.register',
    defaultMessage: 'Register'
  },
  registrationSuccess: {
    id: 'registration.success',
    defaultMessage: 'Success!'
  },
  registrationCurrentUser: {
    id: 'registration.registrationCurrentUser',
    defaultMessage: 'Current user?'
  },
  registrationLoginHere: {
    id: 'registration.registrationLoginHere',
    defaultMessage: 'Login here'
  },
  registrationErrorMsg: {
    id: 'registration.registrationErrorMsg',
    defaultMessage: 'Error, one or more of the inputs are invalid, please try again. If the error persists please click'
  },
  registrationErrorMsgHere: {
    id: 'registration.registrationErrorMsgHere',
    defaultMessage: 'here.'
  },
  registrationTab: {
    id: 'registration.registrationTab',
    defaultMessage: 'Registration'
  },
  openRegistrationTab: {
    id: 'registration.openRegistrationTab',
    defaultMessage: 'Open Registration'
  },
  registrationModalBodyText: {
    id: 'registration.modalBodyText',
    defaultMessage: 'We were unable to successfully register your account. Please contact Customer Care by clicking the email button below.'
  },
  errorMessage: {
    id: 'app.errorMessage',
    defaultMessage: 'Oops! Something went wrong. Please try again.'
  },
  invalidPassword: {
    id: 'app.invalidPassword',
    defaultMessage: 'Your password cannot contain personal information about you or the company'
  },
  invalidValue: {
    id: 'registration.invalidValue',
    defaultMessage: 'Email taken or invalid. If you have an account please'
  },
  login: {
    id: 'app.login',
    defaultMessage: 'login'
  },
  next: {
    id: 'app.next',
    defaultMessage: 'next'
  },
  connectionMessage: {
    id: 'app.connectionMessage',
    defaultMessage: 'Network error: trying to reconnect...'
  },
  slowConnection: {
    id: 'app.slowConnection',
    defaultMessage: 'Slow network connection...'
  },
  close: {
    id: 'app.close',
    defaultMessage: 'close'
  },
  currentEmail: {
    id: 'currentEmail',
    defaultMessage: 'Current Email'
  },
  email: {
    id: 'app.email',
    defaultMessage: 'Email'
  },
  contactUs: {
    id: 'app.contactUs',
    defaultMessage: 'Contact Us'
  },
  customerCareEmail: {
    id: 'app.customerCareEmail',
    defaultMessage: 'customercare@apprise-mobile.com'
  },
  accountFetchingError: {
    id: 'app.accountFetchingError',
    defaultMessage: 'Oops! Something went wrong! If the error continues, please contact Customer Care by clicking the email button below.'
  },
  sso: {
    id: 'getStarted.sso',
    defaultMessage: 'Single Sign On'
  },
  getStarted: {
    id: 'getStarted.getStarted',
    defaultMessage: "Let's Get Started"
  },
  firstTimeChangePassword: {
    id: 'getStarted.changePassword',
    defaultMessage: 'Reset Password'
  },
  changeEmail: {
    id: 'changeEmail',
    defaultMessage: 'Change Email'
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'Change Password'
  },
  customerCare: {
    id: 'customerCare.customerCare',
    defaultMessage: 'Contact Customer Care'
  },
  customerCareName: {
    id: 'customerCare.name',
    defaultMessage: 'Name'
  },
  customerCareEmailLabel: {
    id: 'customerCare.email',
    defaultMessage: 'Email'
  },
  customerCareSubject: {
    id: 'customerCare.subject',
    defaultMessage: 'Subject'
  },
  customerCareSubmit: {
    id: 'customerCare.submit',
    defaultMessage: 'Submit'
  },
  accountNotFound: {
    id: 'getStarted.accountNotFound',
    defaultMessage: 'We did not find an account with the provided Email or Account Id, please try again.'
  },
  continueButton: {
    id: 'getStarted.continueButton',
    defaultMessage: 'continue'
  },
  userPlaceholder: {
    id: 'getStarted.userPlaceholder',
    defaultMessage: 'Email or Account ID'
  },
  notAnEmployee: {
    id: 'getStarted.notAnEmployee',
    defaultMessage: 'Not an employee?'
  },
  registrationText: {
    id: 'registration.registrationText',
    defaultMessage: 'Welcome, just a few steps for registration'
  },
  password: {
    id: 'app.password',
    defaultMessage: 'Password'
  },
  regCode: {
    id: 'app.regCode',
    defaultMessage: 'Account Id'
  },
  regCodeNotFound: {
    id: 'app.regCodeNotFound',
    defaultMessage: 'The account id you provided does not exist in our system. Please try again.'
  },
  loadingSSO: {
    id: 'loadingSSO.loadingSSO',
    defaultMessage: 'Did not see the popup to login? Please click the button below. '
  },
  refresh: {
    id: 'loadingSSO.refresh',
    defaultMessage: 'If you are seeing this page and you are logged in, please click the button below.'
  },
  newPassword: {
    id: 'reset.newPassword',
    defaultMessage: 'New Password'
  },
  confirmNewPassword: {
    id: 'reset.confirmNewPassword',
    defaultMessage: 'Confirm New Password'
  },
  setNewPassword: {
    id: 'reset.setNewPassword',
    defaultMessage: 'Set New Password'
  },
  verify: {
    id: 'reset.verify',
    defaultMessage: 'Verifying Password Requirements ...'
  },
  passwordCheckFailed: {
    id: 'reset.passwordCheckFailed',
    defaultMessage: 'Your password does not have the following below:'
  },
  checkNewPassword: {
    id: 'reset.checkNewPassword',
    defaultMessage: 'Check Password'
  },
  newPasswordInstructions: {
    id: 'reset.newPasswordInstructions',
    defaultMessage: 'In order to protect your account, your password must contain the following:'
  },
  subdomainReset: {
    id: 'reset.subdomain',
    defaultMessage: 'Does not include your company or domain name'
  },
  misMatch: {
    id: 'reset.misMatch',
    defaultMessage: 'Passwords Do Not Match'
  },
  firstNameReset: {
    id: 'reset.firstName',
    defaultMessage: 'Does not include your first name'
  },
  lastNameReset: {
    id: 'reset.lastName',
    defaultMessage: 'Does not include your last name'
  },
  emailReset: {
    id: 'reset.email',
    defaultMessage: 'Does not include your email address'
  },
  regcodeReset: {
    id: 'reset.regcode',
    defaultMessage: 'Does not include your account id'
  },
  signIn: {
    id: 'reset.signIn',
    defaultMessage: 'Sign In'
  },
  passwordReset: {
    id: 'reset.confirmation',
    defaultMessage: 'Password changed successfully'
  },
  save: {
    id: 'save',
    defaultMessage: 'Save'
  },
  sendLink: {
    id: 'activation.sendLink',
    defaultMessage: 'Send Link'
  },
  confirmYourEmail: {
    id: 'activation.confirmEmail',
    defaultMessage: 'Verify your email'
  },
  genericStart: {
    id: 'app.genericStartMessage',
    defaultMessage: "Let's get you in the app"
  },
  organizationID: {
    id: 'app.organizationID',
    defaultMessage: 'Organization ID'
  },
  organizationNotFound: {
    id: 'app.organizationNotFound',
    defaultMessage: 'Unknown Organization ID'
  },
  welcome: {
    id: 'app.welcome',
    defaultMessage: 'Welcome!'
  },
  or: {
    id: 'or',
    defaultMessage: 'or'
  },
  notAClient: {
    id: 'notAClient',
    defaultMessage: 'Not a client?'
  },
  loginHere: {
    id: 'loginHere',
    defaultMessage: 'Then Login Here'
  },
  oneTimeCode: {
    id: 'oneTimeCode',
    defaultMessage: 'One-Time code'
  },
  mfaCodeIncorrect: {
    id: 'mfaCodeIncorrect',
    defaultMessage: 'MFA Code is incorrect or has expired, please try entering code again or request a new code.'
  },
  getVerified: {
    id: 'getVerified',
    defaultMessage: 'Get Verified'
  },
  invalidEmail: {
    id: 'invalidEmail',
    defaultMessage: 'Email taken or invalid.'
  },
  orgId: {
    id: 'orgId',
    defaultMessage: 'Org ID'
  },
  accountOrEmail: {
    id: 'accountOrEmail',
    defaultMessage: 'Account ID or Email'
  },
  mobilePhoneNr: {
    id: 'mobilePhoneNr',
    defaultMessage: 'Mobile Phone Number (10 Digits e.g. 555-555-5555)'
  },
  mobileValidation: {
    id: 'mobileValidation',
    defaultMessage: 'Your mobile phone number must contain the following'
  },
  mobileValidationDigits: {
    id: 'mobileValidationDigits',
    defaultMessage: '10 digits (US based)'
  },
  terms: {
    id: 'terms',
    defaultMessage: 'By registering, you agree to receive text messages from us. Message frequency may vary. Standard SMS & Data rates may apply. Please confirm that you consent to the Terms and Privacy'
  },
  msgAndData: {
    id: 'msgAndData',
    defaultMessage: 'Msg & Data rates may apply.'
  },
  validate: {
    id: 'validate',
    defaultMessage: 'Validate'
  },
  mobileValidationError: {
    id: 'mobileValidationError',
    defaultMessage: 'Error, one or more of the inputs are invalid, please try again.'
  }
})
