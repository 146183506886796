// @flow

import React, { useState } from 'react'
import * as Actions from '../AlfredApp/actions'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import qs from 'qs'
import { injectIntl, defineMessages } from 'react-intl'
import validateDomain from '../utils/whiteListHelper'
import { RequirementSteps } from '../Components'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { StyledComponent } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { messages } from '../i18n/messages/index'
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Input from 'michelangelo/dist/SharedComponents/Inputs/Input'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import Text from 'michelangelo/dist/Components/NativeText'
import { CHANGE_PASSWORD_ERROR_RESET } from '../AlfredApp/actions'
import isEmail from 'validator/lib/isEmail'

const HeaderContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 20px;
  margin-top: 32px;
`

const InputContainer: StyledComponent<{}> = styled.div`
  padding-top: 32px;
`

const ErrorContainer: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  word-wrap: break-word;
  padding-top: 10px;
`

const ButtonContainer: StyledComponent<{}> = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const ValidatorContainer: StyledComponent<{}> = styled.div`
  padding-top: 8px;
`

// added for testing purpose
HeaderContainer.displayName = 'HeaderContainer'
InputContainer.displayName = 'InputContainer'
ErrorContainer.displayName = 'ErrorContainer'
ButtonContainer.displayName = 'ButtonContainer'
ValidatorContainer.displayName = 'ValidatorContainer'
Text.displayName = 'Text'

export const changePasswordMessages = defineMessages({
  currentPassword: {
    id: 'currentPassword',
    defaultMessage: 'Current Password'
  },
  newPassword: {
    id: 'newPassword',
    defaultMessage: 'New Password'
  },
  confirmNewPassword: {
    id: 'confirmNewPassword',
    defaultMessage: 'Confirm New Password'
  },
  passwordsDoNotMatchWarning: {
    id: 'passwordsDoNotMatchWarning',
    defaultMessage: 'Passwords do not match'
  }
})

function ChangePassword ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const displayTryAgain = useSelector(state => state.errorFetching.changePasswordTryAgain)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordType, setPasswordType] = useState('password')
  const [newPasswordType, setNewPasswordType] = useState('password')
  const [confirmNewPasswordType, setConfirmNewPasswordType] = useState('password')
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false)
  const [isCheckingPassword, setIsCheckingPassword] = useState(false)
  const [errors, setErrors] = useState([])
  const [isRequestDone, setIsRequestDone] = useState(false)
  const [displayValidator, setDisplayValidator] = useState(false)

  const query = location.search
  const queryParams = qs.parse(query.replace(/^.*\?/, ''))

  let callbackUrl
  let subdomain = useSelector(state => state.account.subdomain)
  let hasPassword = true
  if (queryParams && queryParams.callbackUrl) {
    callbackUrl = validateDomain(queryParams.callbackUrl) ? queryParams.callbackUrl : ''
  }
  if (queryParams && queryParams.subdomain) {
    subdomain = queryParams.subdomain
  }
  if (queryParams && queryParams.hasPassword) {
    hasPassword = !(queryParams.hasPassword === 'false')
  }

  const displayPassword = (e: any, typeFieldName: string) => {
    e.preventDefault()

    const typeArray = {
      passwordType,
      newPasswordType,
      confirmNewPasswordType
    }

    const oldType = typeArray[typeFieldName]
    const isTextOrHide = (oldType === 'password')
    const newType = (isTextOrHide) ? 'text' : 'password'

    const setTypeArray = {
      passwordType: setPasswordType,
      newPasswordType: setNewPasswordType,
      confirmNewPasswordType: setConfirmNewPasswordType
    }
    setTypeArray[typeFieldName](newType)
  }

  const getTheme = () => {
    let emailTheme = color.lightTextDefault
    let newPasswordTheme = color.lightTextDefault
    let confirmNewPasswordTheme = color.lightTextDefault
    let passwordTheme = color.lightTextDefault
    let buttonTheme = color.disabled
    let buttonDisabled = true

    // validate the email is an email
    if (email && isEmail(email)) emailTheme = color.info
    else if (email) emailTheme = color.danger

    // validate if the password is !empty
    if (password) passwordTheme = color.info

    // validated new password is
    if (newPassword) {
      if (isNewPasswordValid) newPasswordTheme = color.info
      else newPasswordTheme = color.danger
    }

    // if there is a confirm password color it blue, but if they match color them both green
    if (confirmNewPassword) {
      if (newPassword) {
        if (newPassword === confirmNewPassword) {
          newPasswordTheme = color.success
          confirmNewPasswordTheme = color.success
        } else confirmNewPasswordTheme = color.danger
      }
    } else if (confirmNewPassword) confirmNewPasswordTheme = color.danger

    // handle validity of all fields for button control
    if (email && isEmail(email) && newPassword && confirmNewPassword && (password || !hasPassword)) {
      if (newPassword === confirmNewPassword) {
        buttonTheme = color.success
        buttonDisabled = false
      }
    }

    return { emailTheme, newPasswordTheme, confirmNewPasswordTheme, buttonDisabled, buttonTheme, passwordTheme }
  }

  const getErrorMessage = (displayError: ?boolean, text: string) => {
    if (displayError) {
      return (
        <ErrorContainer data-cy='errormessagecy'>
          <Text textSize='h4' align='left' fontColor={color.danger} text={text} />
        </ErrorContainer>
      )
    } else return null
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const result = await dispatch(Actions.changePassword(email, newPassword, confirmNewPassword, password, callbackUrl, subdomain))
    if (result.errors) {
      setErrors(result.errors)
      setIsNewPasswordValid(false)
      setIsCheckingPassword(true)
    }
    if (result.success) {
      setIsRequestDone(true)
      navigate('/change-email-password-success', {
        state: { callbackUrl, type: 'password' }
      })
    }
  }

  const onChange = (pass: string, v: string) => {
    if (displayTryAgain) dispatch({ type: CHANGE_PASSWORD_ERROR_RESET })

    const passType = {
      newPassword: setNewPassword,
      confirmNewPassword: setConfirmNewPassword
    }
    passType[pass](v)
  }

  const updateRequirements = (isValid: boolean) => {
    setIsNewPasswordValid(isValid)
    setIsCheckingPassword(false)
  }

  const { emailTheme, passwordTheme, newPasswordTheme, confirmNewPasswordTheme, buttonTheme, buttonDisabled } = getTheme()
  const passwordsDoNotMatchWarning = getErrorMessage(confirmNewPasswordTheme === color.danger, formatMessage(changePasswordMessages.passwordsDoNotMatchWarning))
  const tryAgainError = getErrorMessage(displayTryAgain, formatMessage(messages.errorMessage))

  // disabled the button click because michelangelo doesnt respect disabled attribute
  // TODO: Movie this logic into michelangelo
  let buttonClick
  if (!buttonDisabled) buttonClick = onSubmit
  if (isRequestDone) {
    return null
  }
  return (
    <Row classNames={['is-magrinless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <HeaderContainer>
          <Text align='center' textSize='h1' fontColor={color.lightDefaultTheme} text={formatMessage(messages.changePassword)} />
        </HeaderContainer>
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <ValidatorContainer>
            <RequirementSteps
              onUpdate={(isValid) => updateRequirements(isValid)}
              password={newPassword}
              repeatPassword={confirmNewPassword}
              displayValidator={displayValidator}
              subdomain={subdomain}
              isCheckingPassword={isCheckingPassword}
              userInfo={{ email }}
              fromRegistration={false}
              errors={errors}
            />
          </ValidatorContainer>
          <InputContainer>
            <Input
              inputTheme={emailTheme}
              value={email}
              label={formatMessage(messages.currentEmail)}
              onChange={(e) => setEmail(e)}
              type='email'
            />
          </InputContainer>
          <InputContainer>
            <Input
              _lr-hide
              inputTheme={passwordTheme}
              value={password}
              label={formatMessage(changePasswordMessages.currentPassword)}
              onChange={(v) => setPassword(v)}
              type={passwordType === 'password' ? 'password' : 'text'}
              iconName={passwordType === 'password' ? 'eyeClose' : 'eyeOpen'}
              onClickIcon={(e) => displayPassword(e, 'passwordType')}
            />
          </InputContainer>
          <InputContainer>
            <Input
              _lr-hide
              name='newPassword'
              inputTheme={newPasswordTheme}
              value={newPassword}
              label={formatMessage(changePasswordMessages.newPassword)}
              onChange={(v) => onChange('newPassword', v)}
              type={newPasswordType === 'password' ? 'password' : 'text'}
              iconName={newPasswordType === 'password' ? 'eyeClose' : 'eyeOpen'}
              onClickIcon={(e) => displayPassword(e, 'newPasswordType')}
              onFocus={() => setDisplayValidator(true)}
              onBlur={() => setDisplayValidator(false)}
            />
          </InputContainer>
          <InputContainer>
            <Input
              _lr-hide
              inputTheme={confirmNewPasswordTheme}
              value={confirmNewPassword}
              label={formatMessage(changePasswordMessages.confirmNewPassword)}
              onChange={(v) => onChange('confirmNewPassword', v)}
              type={confirmNewPasswordType === 'password' ? 'password' : 'text'}
              iconName={confirmNewPasswordType === 'password' ? 'eyeClose' : 'eyeOpen'}
              onClickIcon={(e) => displayPassword(e, 'confirmNewPasswordType')}
            />
          </InputContainer>
          {passwordsDoNotMatchWarning}
          {tryAgainError}
          <Row classNames={['is-marginless', 'is-mobile']}>
            <ButtonContainer>
              <Column classNames={['is-paddingless', 'is-half']}>
                <Button fill themeColor={buttonTheme} type='button' onClick={buttonClick} title={formatMessage(messages.save)} />
              </Column>
            </ButtonContainer>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}

export default (injectIntl(ChangePassword))
export { ChangePassword as ChangePasswordUnwrapped }
