// @flow

import React from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import color from '../styles/color'

// Define what props.theme will look like
const kind = {
  default: color.default,
  info: color.info,
  warning: color.warning,
  danger: color.danger,
  success: color.success,
  disabled: color.disabled
}

type ButtonProps = {
  children?: string,
  onClick?: Function,
  kind: 'default' | 'info' | 'warning' | 'danger' | 'success' | 'disabled',
  disabled: boolean
}

/* istanbul ignore next */
const DefaultButton: StyledComponent<{}> = styled.button`
  border: 2px solid ${(props: ButtonProps) => kind[props.kind]};
  color: ${(props: ButtonProps) => kind[props.kind]};
  background-color: transparent;
  width: auto;
  min-width: 128px;
  word-wrap: break-word;
  font-family: Raleway;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  text-align: center;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: ${(props: ButtonProps) => props.disabled ? 'not-allowed' : 'pointer'};
  &:focus {
    outline: none;
  }
`
// For testing purpose added this
DefaultButton.displayName = 'DefaultButton'

export class Button extends React.Component<ButtonProps, {}> {
  render () {
    return (
      <DefaultButton
        onClick={this.props.onClick}
        kind={this.props.kind}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </DefaultButton>
    )
  }
}

export default Button
