// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'

import type { StateType } from '../AlfredApp/reducers'
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'

type LoadingProps = {
  isAccountFetching: boolean,
  isUserFetching: boolean,
  isRegistrationFetching: boolean,
  isGoingToPrivacyPolicy: Boolean,
}
const ParentContainer: StyledComponent<{}> = styled.div`
  top: 0;
  background: ${color.white};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
`
const Container: StyledComponent<{}> = styled.div`
  background: ${color.white};
  width: 100%;
  height: 100%;
  z-index: 3;
`
const Child: StyledComponent<{}> = styled.div`

`

// add for testing purpose
ParentContainer.displayName = 'ParentContainer'
Container.displayName = 'Container'
Child.displayName = 'Child'

export class LoadingScreen extends Component<LoadingProps, {}> {
  props: LoadingProps

  /* istanbul ignore next */
  static mapStateToProps (state: StateType) {
    return {
      isAccountFetching: state.fetching.isAccountFetching,
      isUserFetching: state.fetching.isUserFetching,
      isRegistrationFetching: state.fetching.isRegistrationFetching,
      isGoingToPrivacyPolicy: state.fetching.isGoingToPrivacyPolicy
    }
  }

  render () {
    return (
      (this.props.isAccountFetching || this.props.isUserFetching || this.props.isRegistrationFetching || this.props.isGoingToPrivacyPolicy)
        ? (
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless', 'is-4', 'is-centered']}>
              <ParentContainer>
                <Container>
                  <Child data-cy='loadingbarcy' className='loading'>
                    <div className='loading-bar' />
                    <div className='loading-bar' />
                    <div className='loading-bar' />
                    <div className='loading-bar' />
                    <div className='loading-bar' />
                  </Child>
                </Container>
              </ParentContainer>
            </Column>
          </Row>
          )
        : null
    )
  }
}

export default connect(LoadingScreen.mapStateToProps, null)(LoadingScreen)
