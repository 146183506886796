// @flow

import * as React from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import { isEmpty } from 'lodash'
/** Michelangelo Components */
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'

const ParentCircle: StyledComponent<{}> = styled.div`
  width: 158px;
  height: 158px;
  background: rgba(222,222,222,0.40);
  border-radius: 50%;
  position: relative;
  left: 0;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  float: left;
  margin-top: 80px;
`
const ChildCircle: StyledComponent<{}> = styled.div`
  background: #E4E4E4;
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.50);
  height: 128px;
  width: 128px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
`

const Initial: StyledComponent<{}> = styled.div`
  height: 128px;
  width: 128px;
  font-size: 48px;
  font-family: Raleway;
  font-weight: 400;
  position: absolute;
  text-align: center;
  top: 35px;
  text-transform: uppercase;
`

// add for testing purpose
ParentCircle.displayName = 'ParentCircle'
ChildCircle.displayName = 'ChildCircle'
Initial.displayName = 'Initial'

type ProfileIconProps = {
  userName: Array<string>
}

/**
 * Eventually this will become a users profile picture.
 * For now we pass in the users initials
 * Postion is default centered but has a no vertical positon set
 */
export default class ProfileIcon extends React.Component<ProfileIconProps, {}> {
  render () {
    let userInitials
    if (!isEmpty(this.props.userName)) {
      const firstInitial = this.props.userName[0][0]
      let lastInitial = this.props.userName[1][0]
      if (this.props.userName.length > 2) {
        lastInitial = this.props.userName[2][0]
      }
      userInitials = firstInitial + lastInitial
    }

    return (
      <Row classNames={['is-marginless']}>
        <Column classNames={['is-paddingless']}>
          <ParentCircle>
            <ChildCircle>
              <Initial>{userInitials}</Initial>
            </ChildCircle>
          </ParentCircle>
        </Column>
      </Row>
    )
  }
}
