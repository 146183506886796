// @flow

import {
  SET_REG_CODE,
  SET_EMAIL_STATUS,
  GET_USER_LOOKUP,
  RESET_USER_DETAILS,
  RESET_EMAIL_DETAILS,
  RESET_GETSTARTED
} from './actions'

import type { Action } from './actions'

export type StatusStateType = {
  regCodeStatus: string,
  emailStatus: string,
  userStatus: string
}

export const initialStatusState = {
  regCodeStatus: '',
  emailStatus: '',
  userStatus: ''
}

export default function status (state: StatusStateType = initialStatusState, action: Action): StatusStateType {
  switch (action.type) {
    case RESET_USER_DETAILS:
      return Object.assign({}, state, initialStatusState)
    case RESET_EMAIL_DETAILS:
      return Object.assign({}, state, {
        emailStatus: ''
      })
    case SET_REG_CODE:
      return Object.assign({}, state, {
        regCodeStatus: action.result.status
      })
    case SET_EMAIL_STATUS:
      return Object.assign({}, state, {
        emailStatus: action.result
      })
    case GET_USER_LOOKUP:
      return Object.assign({}, state, {
        userStatus: action.result.status
      })
    case RESET_GETSTARTED:
      return Object.assign({}, state, initialStatusState)
    default:
      return state
  }
}
