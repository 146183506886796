// @flow

import {
  GET_USER_LOOKUP,
  RESET_USER_DETAILS,
  SET_USER_EMAIL,
  SET_USER_NAME,
  SET_USER_REG_CODE,
  SET_HAS_USER_ACCEPTED_PRIVACY_POLICY,
  CHANGE_PASSWORD_CHECK,
  SET_MFA_REQUIRED,
  SET_USER_UPDATED
  // SET_GLOBAL_CONFIG_MESSAGE
} from './actions'

import type { Action } from './actions'

export type UserStateType = {
  userName: string,
  userEmail: string,
  userRegCode: string,
  userId: string,
  hasAcceptedApprisePolicy?: ?boolean,
  checkPasswordValue?: ?boolean,
  isCheckingPassword?: ?boolean,
  mfaRequired?: ?boolean,
  userUpdated: boolean,
  requirePasswordUpdate: boolean
}

export const initialUserState: UserStateType = {
  userName: '',
  userEmail: '',
  userRegCode: '',
  userId: '',
  checkPasswordValue: false,
  isCheckingPassword: false,
  mfaRequired: false,
  userUpdated: false,
  requirePasswordUpdate: false,
  globalConfig: ''
}

export default function user (state: UserStateType = initialUserState, action: Action): UserStateType {
  switch (action.type) {
    case RESET_USER_DETAILS:
      return Object.assign({}, state,
        initialUserState
      )
    case GET_USER_LOOKUP:
      return Object.assign({}, state, {
        userName: action.result.name,
        userId: action.result.userId,
        requirePasswordUpdate: action.result.requirePasswordUpdate
      })
    case SET_USER_NAME:
      return Object.assign({}, state, {
        userName: action.name
      })
    // case SET_GLOBAL_CONFIG_MESSAGE:
    //   return Object.assign({}, state, {
    //     globalConfigMessage: action.result
    //   })
    case SET_USER_EMAIL:
      return Object.assign({}, state, {
        userEmail: action.result
      })
    case SET_USER_REG_CODE:
      return Object.assign({}, state, {
        userRegCode: action.result
      })
    case SET_HAS_USER_ACCEPTED_PRIVACY_POLICY:
      return Object.assign({}, state, {
        hasAcceptedApprisePolicy: action.result
      })
    case CHANGE_PASSWORD_CHECK:
      return Object.assign({}, state, {
        checkPasswordValue: action.result
      })
    case SET_MFA_REQUIRED:
      return Object.assign({}, state, {
        mfaRequired: action.result
      })
    case SET_USER_UPDATED:
      return Object.assign({}, state, {
        userUpdated: action.result
      })
    default:
      return state
  }
}
