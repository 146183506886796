// @flow

import React, { useEffect } from 'react'
import * as Actions from '../AlfredApp/actions'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

const Container: StyledComponent<{}> = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`

function SSOLogout () {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(Actions.ssoLogout(location.search))
    navigate(`/${location.search}`)
  })

  return (
    <Container />
  )
}

export default (injectIntl(SSOLogout))
