// @flow

import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { injectIntl, defineMessages } from 'react-intl'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { StateType } from '../AlfredApp/reducers'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

const logoMessages = defineMessages({
  clientLogoAlt: {
    id: 'logo.clientLogoAlt',
    defaultMessage: 'Your Logo'
  }
})

const LogoContainer: StyledComponent<{}> = styled.div`
  justify-content: center;
  padding: 16px;
  text-align: center;
`
const LogoImage: StyledComponent<{}> = styled.img`
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 100px;
`

// For testing purpose added this
LogoImage.displayName = 'LogoImage'
ContentLoader.displayName = 'Loader'

const LoaderContainer: StyledComponent<{}> = styled.div`
  text-align: center;
  height: 100px;
  width: auto;
  padding: 16px;
`

export function Loader () {
  return (
    <LoaderContainer>
      <ContentLoader
        height={90}
        width={200}
        speed={2}
        animate
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      />
    </LoaderContainer>
  )
}

type LogoProps = {
  accountDetails: Object,
  logoUrl?: any
} & InjectIntlProvidedProps

type LogoState = {
  loaded: boolean
}

class Logo extends React.Component<LogoProps, LogoState> {
  constructor (props: LogoProps) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  /* istanbul ignore next */
  static mapStateToProps (state: StateType) {
    return {
      accountDetails: state.account ? state.account.account : null
    }
  }

  generateLogo () {
    const accountDetails = this.props.accountDetails
    let logo = null
    /* istanbul ignore else */
    if (!isEmpty(accountDetails)) {
      const applicationId = accountDetails.application ? accountDetails.application : ''
      const { intl: { formatMessage } } = this.props
      const logoS3Url = this.props.logoUrl ?? 'https://cdn-logos.theemployeeapp.com/' + applicationId + '.png'
      let loader = null
      if (!this.state.loaded) {
        loader = Loader()
      }
      logo =
        <LogoContainer>
          {loader}
          <LogoImage
            data-cy='headerlogoimagecy' style={this.state.loaded ? {} : { display: 'none' }}
            onLoad={() => this.setState({ loaded: true })} src={logoS3Url} alt={formatMessage(logoMessages.clientLogoAlt)}
          />
        </LogoContainer>
    }
    return logo
  }

  render () {
    const logo = this.generateLogo()
    return (
      <div>
        {logo}
      </div>
    )
  }
}

// export for tests
export const RawLogo = Logo
export default connect(Logo.mapStateToProps, null)(injectIntl(Logo))
