// @flow
// react-app-polyfill/ie11 import must be the first line in src/index.js
import 'react-app-polyfill/ie11'
import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'

import * as Sentry from '@sentry/browser'
import registerIFrameListener from './iFrameListener'
// import registerServiceWorker from './registerServiceWorker' disabled for now as it wasn't loading and not being used
import translations from './i18n/locales'
import privacyPolicyTranslations from './i18n/locales/privacy-policy'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
// $FlowFixMe - need redux-persist to release a new version that fixes flow errors
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './AlfredApp/store'
import Registration from './Registration'
import { Footer, LoadingScreen, OfflineMessage, SlowConnection } from './Components'

import ChangeEmail from './ChangeEmailPassword/ChangeEmail'
import ChangePassword from './ChangeEmailPassword/ChangePassword'
import ChangeEmailPasswordSuccess from './ChangeEmailPassword/ChangeEmailPasswordSuccess'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import Login from './Login'
import UserData from './UserData'
import LoadingSSO from './LoadingSSO'
import ForgotPasswordEmailEntry from './ForgotPasswordEmailEntry'
import ApprisePrivacyPolicy from './PrivacyPolicy'
import ResetPassword from './ForgotPassword/ResetPassword'
import ResetPasswordConfirmation from './ForgotPassword/ResetPasswordConfirmation'
import EmailVerificationLinkSent from './EmailVerification/EmailVerificationLinkSent'
import VerifyEmail from './EmailVerification/VerifyEmail'
import CustomerCare from './CustomerCare/CustomerCare'
import CustomerCareSuccess from './CustomerCare/CustomerCareSuccess'
import EmailVerificationSuccess from './EmailVerification/EmailVerificationSuccess'
import ResendEmailVerification from './EmailVerification/ResendEmailVerification'
import SSOAccess from './SSOAccess/SSOAccess'
import SSOLogout from './SSOAccess/Logout'
import OAuthRedirect from './SSOAccess/OAuthRedirect'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { env } from './utils/config'
import styled, { StyledComponent } from 'styled-components'
import './styles/style.css'

import { IntlProvider } from 'react-intl'
import withClearCache from './ClearCache'
import packageJson from '../package.json'
import moment from 'moment'
import MobileRegistration from './MobileRegistration'

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/locale-data/en') // needed polyfill for react-intl v3 if you would like to support ie11 + safari
}
// $FlowFixMe
const rtf = (Intl && Intl.RelativeTimeFormat && new Intl.RelativeTimeFormat('en', { style: 'short', numeric: 'auto' })) || false

if (!rtf) {
  require('@formatjs/intl-locale/polyfill') // fix safari 13.x error Intl.Locale constructor missing
  require('@formatjs/intl-relativetimeformat/polyfill') // needed again for react-intl v3
  require('@formatjs/intl-relativetimeformat/locale-data/en') // needed for react-intl v3
}

if (env === 'production') {
  Sentry.init({
    dsn: 'https://e941c47277fe4459af580a4cc66de8c6@sentry.io/305980',
    environment: env,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  })
}

const destination: ?Element = document.querySelector('#container')

// registerServiceWorker() disabled for now as it wasn't loading and not being used
registerIFrameListener()

const updateTransition = (classNames) =>
  (child) => {
    return React.cloneElement(child,
      { classNames }
    )
  }

const Container: StyledComponent<{}> = styled.div`
  position: relative;
  min-height: 100%;
`
const PageContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 70px;
`
const ClearCacheComponent = withClearCache(MainApp)
const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format('DD-MM-YYYY HH:MM')
  return buildDate
}
function App () {
  return <ClearCacheComponent />
}
function MainApp () {
  const language = store.getState().account.language
  const locale = language || navigator.language || 'en'
  const localeToLower = locale.toLowerCase()
  const [lang, setLang] = useState(localeToLower)
  const currentRoute = window.location.href.split('/')[3]
  const messages = translations[lang]
  const ppMessages = privacyPolicyTranslations[lang]
  const mergedMessages = { ...messages, ...ppMessages }

  const onChangeLang = (lang) => {
    setLang(lang.toLowerCase())
  }
  console.log('Build date: ', getBuildDate(packageJson.buildDate))
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={lang} key={lang} messages={mergedMessages}>
          <Router>
            <Container>
              <LoadingScreen />
              <OfflineMessage />
              <SlowConnection />
              <PageContainer>
                <TransitionGroup childFactory={updateTransition()}>
                  <CSSTransition timeout={500}>
                    <Routes>
                      <Route path='/registration' element={<Registration />} />
                      <Route path='/privacy/*' element={<ApprisePrivacyPolicy />} />
                      <Route path='/reset/*' element={<ResetPassword />} />
                      <Route path='/reset-confirmation/*' element={<ResetPasswordConfirmation />} />
                      <Route path='/forgot-password/*' element={<ForgotPassword />} />
                      <Route path='/link-sent/*' element={<EmailVerificationLinkSent />} />
                      <Route path='/email-verification/*' element={<VerifyEmail />} />
                      <Route path='/verification-success/*' element={<EmailVerificationSuccess />} />
                      <Route path='/resend-email-verification/*' element={<ResendEmailVerification />} />
                      <Route path='/customer-care/*' element={<CustomerCare onChangeLang={onChangeLang} />} />
                      <Route path='/customer-care-success/*' element={<CustomerCareSuccess />} />
                      <Route path='/sso-access/*' element={<SSOAccess />} />
                      <Route path='/logout/*' element={<SSOLogout />} />
                      <Route path='/oauth/custom/:accountId/callback/*' element={<OAuthRedirect />} />
                      <Route path='/change-email/*' element={<ChangeEmail />} />
                      <Route path='/change-password/*' element={<ChangePassword />} />
                      <Route path='/change-email-password-success/*' element={<ChangeEmailPasswordSuccess />} />
                      <Route path='/*' element={<Login onChangeLang={onChangeLang} />} />
                      <Route path='/loadingSSO/*' element={<LoadingSSO />} />
                      <Route path='/forgot-password-email-entry' element={<ForgotPasswordEmailEntry />} />
                      <Route path='/user-data' element={<UserData />} />
                      <Route path='/mobile-number-registration' element={<MobileRegistration />} />
                    </Routes>
                  </CSSTransition>
                </TransitionGroup>
              </PageContainer>
              {!currentRoute.includes('mobile-number-registration') && <Footer /> }
            </Container>
          </Router>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}
if (destination) {
  const root = createRoot(destination)
  root.render(<App/>)
}
