// @flow

import React, { useEffect, useState } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import styled from 'styled-components'
import { Button, Icons } from '../Components/index'
import { messages } from '../i18n/messages/index'
import * as Actions from '../AlfredApp/actions'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import Text from 'michelangelo/dist/Components/NativeText'
import color from 'michelangelo/dist/Components/styles/color'
import { useLocation, useNavigate } from 'react-router-dom'

// export for tests
export const activationMessages = defineMessages({
  headerText: {
    id: 'activation.header',
    defaultMessage: 'Get new activation link'
  },
  errorActivating: {
    id: 'activation.error',
    defaultMessage: 'We\'ve encountered an error when verifying your email \n Please try again'
  }
})

const ErrorMsg: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  word-wrap: break-word;
  padding-top: 10px;
`
const ContainerDiv: StyledComponent<{}> = styled.div`
  margin-top: 16px;
`
const ButtonContainer: StyledComponent<{}> = styled.div`
  margin: 20px 0;
`

const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
`

const ErrorContainer: StyledComponent<{}> = styled.div`
  font-family: Raleway;
  font-weight: normal;
  margin-top: 30px;
  margin-bottom: 45px;
  text-align:center;
  color: #F07B7B
  white-space: pre-line;
`

function ResendEmailVerification ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [oldToken, setOldToken] = useState('')

  const fetchingError = useSelector(state => state.errorFetching.isErrorFetchingUser)

  useEffect(() => {
    let oldToken
    if (location.state) {
      oldToken = location.state.oldToken || ''
    }

    if (oldToken) {
      setOldToken(oldToken)
    }
    // eslint-disable-next-line
  }, [])

  const handleResendLink = async (e: any) => {
    e.preventDefault()

    if (oldToken) {
      dispatch(Actions.resendVerificationEmail(oldToken))
      navigate('/link-sent')
    }
  }

  const getTheme = () => {
    let buttonTheme = 'disabled'
    let disabled = true

    if (oldToken) {
      buttonTheme = 'success'
      disabled = false
    }

    return { buttonTheme, disabled }
  }

  const getErrorMessage = () => {
    let errorMessage = ''
    if (fetchingError) {
      errorMessage = formatMessage(messages.errorMessage)
    }

    const errorDiv = (
      <ErrorMsg>
        <Text textSize='h4' align='center' fontColor={color.danger} text={errorMessage} />
      </ErrorMsg>
    )
    return errorMessage ? errorDiv : null
  }

  const getBodyButtons = () => {
    const { buttonTheme, disabled } = getTheme()
    const resultMessage = getErrorMessage()
    return (
      <div>
        {resultMessage}
        <ButtonContainer>
          <Row>
            <Column classNames={['is-centered']} lg={4} lgShift={4}>
              <div className='div-align'>
                <Button kind={buttonTheme} disabled={disabled} type='submit' onClick={handleResendLink}>{formatMessage(messages.sendLink)}</Button>
              </div>
            </Column>
          </Row>
        </ButtonContainer>
      </div>
    )
  }

  const bodyButtons = getBodyButtons()
  const headerText = formatMessage(activationMessages.headerText)
  const errorMessage = formatMessage(activationMessages.errorActivating)

  return (
    <div className='transition-item detail-page'>
      <HeaderContainer>
        <Text align='center' textSize='h2' fontColor={color.lightTextDefault} text={headerText} />
      </HeaderContainer>

      <ErrorContainer>
        <Icons fillColor={color.danger} size='3x' iconName='close' />
        <ContainerDiv>{errorMessage}</ContainerDiv>
      </ErrorContainer>

      {bodyButtons}
    </div>
  )
}

export default (injectIntl(ResendEmailVerification))
