/* eslint-disable react/jsx-handler-names */
// @flow

import React, { useState } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Icons, NewButton } from './Components/index'
import { messages } from './i18n/messages/index'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import * as Actions from './AlfredApp/actions'

/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Input from 'michelangelo/dist/Components/NativeInput'
import Text from 'michelangelo/dist/Components/Text'
import isEmail from 'validator/lib/isEmail'

export const ForgotPasswordEmailEntryMessages = defineMessages({
  sendResetLink: {
    id: 'login.sendResetLink',
    defaultMessage: 'Send Email'
  },
  resetPasswordSubtitle: {
    id: 'login.resetPasswordSubtitle',
    defaultMessage: 'To reset your password, please enter your email below and submit. If the email is registered with us, an email will be sent to you with instructions on resetting your password.'
  },
  contactCustomerCare: {
    id: 'login.contactCustomerCare',
    defaultMessage: 'If you do not receive an email within 15 minutes, please contact '
  },
  customerCareLink: {
    id: 'login.customerCareLink',
    defaultMessage: 'Customer Care'
  },
  resetPassword: {
    id: 'reset.resetPassword',
    defaultMessage: 'Reset Password'
  }
})

const CloseIconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  @media (max-width: 370px) {
    margin-right: 8px;
    margin-top: 8px;
  }
`
const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: ${(props) => props.isReCaptchaShowing ? '10%' : '10px'};
  padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const ResetSubtitle: StyledComponent<{}> = styled.div`
  padding: 32px 0px 10px 0px;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 15px;
  padding: 8px;
`
const InputContainer: StyledComponent<{}> = styled.div`
  padding: 32px 0px 10px 0px;
  input {
    cursor: pointer !important;
  }
`
const ForgotMsg: StyledComponent<{}> = styled.div`
  cursor: pointer;
`
ForgotMsg.displayName = 'ForgotMsg'

function ForgotPasswordEmailEntry ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subdomain = useSelector(state => state.account.subdomain)
  const accountDetails = useSelector(state => state.account.account)
  const [email, setEmail] = useState('')

  const handleChangeEmail = (e: any) => {
    const email = e.target.value
    setEmail(email)
  }

  const forgotPasswordClick = async (e: Event) => {
    e.preventDefault()
    if (!isEmpty(email) && !isEmpty(accountDetails._id)) {
      await dispatch(Actions.forgotPassword(email, accountDetails._id, subdomain))
      navigate('/forgot-password')
    }
  }

  const getTheme = () => {
    let inputTheme = color.lightTextDefault
    let buttonTheme = color.disabled
    let disabled = true

    const characterPresent = !isEmpty(email)

    if (characterPresent) {
      inputTheme = color.info
      buttonTheme = color.success
      disabled = false
    }
    return { inputTheme, buttonTheme, disabled }
  }

  const { buttonTheme, inputTheme } = getTheme()

  return (
    <Row classNames={['is-magrinless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <CloseIconContainer onClick={() => navigate(-1)}>
            <Icons fillColor={color.default} size='2x' iconName='close' />
          </CloseIconContainer>
          <HeaderContainer>
            <Text align='center' textSize='h1' text={formatMessage(ForgotPasswordEmailEntryMessages.resetPassword)} fontColor={color.grey800} />
          </HeaderContainer>
          <ResetSubtitle>
            <Text align='left' textSize='p' text={formatMessage(ForgotPasswordEmailEntryMessages.resetPasswordSubtitle)} fontColor={color.grey800} />
            <br />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Text align='left' textSize='p' fontColor={color.grey800}>
                {formatMessage(ForgotPasswordEmailEntryMessages.contactCustomerCare)}
                <a href='mailto:support@theemployeeapp.com'>{formatMessage(ForgotPasswordEmailEntryMessages.customerCareLink)}</a>
              </Text>
            </div>
          </ResetSubtitle>
          <form onSubmit={forgotPasswordClick}>
            <Row classNames={['is-marginless', 'is-mobile']}>
              <ButtonContainer>
                <Column classNames={['is-paddingless', 'is-full']}>
                  <InputContainer>
                    <Input
                      inputTheme={inputTheme}
                      value={email}
                      label={formatMessage(messages.email)}
                      onChange={handleChangeEmail}
                      type='email'
                    />
                  </InputContainer>

                  <Row classNames={['is-marginless', 'is-mobile']}>
                    <ButtonContainer>
                      <Column classNames={['is-paddingless', 'is-full']}>
                        <NewButton pill disabled={!isEmail(email)} type='submit' fill themeColor={buttonTheme} onClick={forgotPasswordClick} title={formatMessage(ForgotPasswordEmailEntryMessages.sendResetLink)} />
                      </Column>
                    </ButtonContainer>
                  </Row>
                </Column>
              </ButtonContainer>
            </Row>
          </form>
        </Column>
      </Column>
    </Row>
  )
}

export default (injectIntl(ForgotPasswordEmailEntry))
export { ForgotPasswordEmailEntry as UnconnectedLogin }
