// @flow

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'

import { Icon } from './Icons'
/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'

import type { InjectIntlProvidedProps } from 'react-intl'
import type { StyledComponent } from 'styled-components'

const alertMessages = defineMessages({
  alphaCharacter: {
    id: 'alert.alphaCharacter',
    defaultMessage: 'Lower and upper case letter'
  },
  numberCharacter: {
    id: 'alert.numberCharacter',
    defaultMessage: 'Number'
  },
  specialCharacter: {
    id: 'alert.specialCharacter',
    defaultMessage: 'Special characters'
  }
})

const ParentContainer: StyledComponent<{}> = styled.div`
  background-color: ${color.white};
  border: 1px solid ${color.lightTextDefault};
  padding: 8px;
`
const ParentTextContainer: StyledComponent<{}> = styled.div`
  color: ${color.lightTextDefault};
  font-family: Raleway;
  font-size: 13px;
  font-weight: 800;
  line-height: 17px;
  text-align: left;
  padding: 2px 8px;
`
const ChildTextContainer: StyledComponent<{}> = styled.div`
  color: ${color.lightTextDefault};
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding: 2px 8px;
`
const CloseContainer: StyledComponent<{}> = styled.div`
  float: right;
  padding-right: 8px;
`

// add for testing purpose
ParentContainer.displayName = 'ParentContainer'
ChildTextContainer.displayName = 'ChildTextContainer'
CloseContainer.displayName = 'CloseContainer'
ParentTextContainer.displayName = 'ParentTextContainer'

type PasswordHelperCardProps = {
  onClose: () => void
} & InjectIntlProvidedProps

export const PasswordHelperCard = (props: PasswordHelperCardProps) => {
  const { intl: { formatMessage } } = props
  return (
    <ParentContainer>
      <Row classNames={['is-marginless', 'is-mobile']}>
        <Column classNames={['is-paddingless']}>
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless']}>
              <Column classNames={['is-paddingless']}><ParentTextContainer>{formatMessage(alertMessages.specialCharacter)}</ParentTextContainer></Column>
              <Column classNames={['is-paddingless']}><ChildTextContainer># ? ! @ % ^ & * -</ChildTextContainer></Column>
            </Column>
          </Row>
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless']}>
              <Column classNames={['is-paddingless']}><ParentTextContainer>{formatMessage(alertMessages.numberCharacter)}</ParentTextContainer></Column>
              <Column classNames={['is-paddingless']}><ChildTextContainer>0 1 2 3 4 5 6 7 8 9</ChildTextContainer></Column>
            </Column>
          </Row>
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless']}>
              <Column classNames={['is-paddingless']}><ParentTextContainer>{formatMessage(alertMessages.alphaCharacter)}</ParentTextContainer></Column>
              <Column classNames={['is-paddingless']}><ChildTextContainer>Aa-Zz</ChildTextContainer></Column>
            </Column>
          </Row>
        </Column>
        <Column classNames={['is-paddingless', 'is-1']}>
          <CloseContainer data-cy='passwordHelperCard' onClick={props.onClose}><Icon iconName='x' size='1x' fillColor={color.lightTextDefault} /></CloseContainer>
        </Column>
      </Row>
    </ParentContainer>
  )
}

export default injectIntl(PasswordHelperCard)
export { PasswordHelperCard as UnwrappedPasswordHelperCard }
