// @flow

import React, { useEffect } from 'react'
import * as Actions from '../AlfredApp/actions'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import { useLocation, useMatch } from 'react-router-dom'

const Container: StyledComponent<{}> = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`

function OAuthRedirect () {
  const location = useLocation()
  const dispatch = useDispatch()
  const match = useMatch('/oauth/custom/:accountId/callback')

  useEffect(() => {
    dispatch(Actions.getCustomOAuthRedirectURL(match.params.accountId, location.search))
  })

  return (
    <Container />
  )
}

export default (injectIntl(OAuthRedirect))
