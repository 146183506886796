// @flow

/* eslint-disable */

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { Animation } from '../Components/index'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { messages } from '../i18n/messages'

/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/NativeText'

const customerCareMessages = defineMessages({
  mailSuccess: {
    id: 'customerCare.success',
    defaultMessage: 'Mail Sent'
  }
})

const LinkContainer: StyledComponent<{}> = styled.div`
  margin-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 10px;
`

const AnimationContainer: StyledComponent<{}> = styled.div`
  width: 100%;
  z-index: 3;
`

const HeaderContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 20px;
  margin-top: 15px;
`

function CustomerCareSuccess ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const subdomain = urlParams.get('subdomain')
  const handleOnAnimationFinish = () => {
    if (!subdomain) {
      navigate('/')
    } else {
      navigate('/' + window.location.search)
    }
  }

  return (
    <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <HeaderContainer><Text align='center' textSize='h1' fontColor={color.lightTextDefault} text={formatMessage(messages.customerCare)} /></HeaderContainer>
        <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
          <Column classNames={['is-paddingless', 'is-one-fifth', 'is-centered']}>
            <AnimationContainer>
              <Animation onAnimationFinish={handleOnAnimationFinish} animationType='email' speed={3} />
            </AnimationContainer>
          </Column>
        </Row>
        <LinkContainer>
          <Text align='center' textSize='h1' fontColor={color.success} text={formatMessage(customerCareMessages.mailSuccess)} />
        </LinkContainer>
      </Column>
    </Row>
  )
}
export default (injectIntl(CustomerCareSuccess))
export { CustomerCareSuccess as UnconnectedCustomerCareSuccess }
