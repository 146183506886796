// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import type { StyledComponent } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
type ButtonProps = {
  title: string,
  onClick?: Function,
  fill?: boolean,
  outline?: boolean,
  disabled?: boolean,
  pill?: boolean
}
/* istanbul ignore next */
const StyledButton: StyledComponent<{}> = styled.button`
  width: 100%;
  min-width: 128px;
  padding: 12px 20px;
  color: ${color.info};
  background: transparent;
  border: none;
  text-transform: uppercase;
  word-wrap: break-word;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.08em;
  transition: all 0.3s ease;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 0.8;
    }
  ${props => props.outline && css`
    border: 1px solid ${color.success};
    border-radius: 6px;
  `}
  ${props => props.fillButton && css`
    color: ${color.white};
    background: ${color.success};
    border: 1px solid ${color.success};
    border-radius: 6px;
  `}
  ${props => props.disabled && css`
    background: ${color.disabled};
    border: 1px solid ${color.disabled};
  `}
  ${props => props.pill && css`
    border-radius: 100px;
  `}
`
// For testing purpose added this
StyledButton.displayName = 'StyledButton'
export class Button extends React.Component<ButtonProps, {}> {
  render () {
    return (
      <StyledButton
        onClick={this.props.onClick}
        fillButton={this.props.fill}
        outline={this.props.outline}
        disabled={this.props.disabled}
        pill={this.props.pill}
      >
        {this.props.title}
      </StyledButton>
    )
  }
}
export default Button
