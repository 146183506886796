// @flow

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { messages } from '../i18n/messages/index'
import { Animation } from '../Components/index'
import jwtDecode from 'jwt-decode'
import qs from 'qs'

import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/NativeText'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import { useLocation } from 'react-router-dom'

const resetPasswordMessages = defineMessages({
  passwordReset: {
    id: 'resetPassword.confirmation',
    defaultMessage: 'Password changed successfully'
  }
})

const LinkContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
`
const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`
const AnimationContainer: StyledComponent<{}> = styled.div`
  width: 100%;
  z-index: 3;
`

// add for testing purpose
LinkContainer.displayName = 'LinkContainer'
AnimationContainer.displayName = 'AnimationContainer'

function ResetConfirmation ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const location = useLocation()

  // Use history to go back a page
  const handleGoToGetStarted = (e: any) => {
    e.preventDefault()
    const query = location.search
    const queryParams = qs.parse(query.replace(/^.*\?/, ''))
    const token = queryParams.token
    const decodedToken = jwtDecode(token)
    const subdomain = decodedToken.subdomain
    window.open(`https://${subdomain}.theemployeeapp.com`, '_self')
  }

  return (
    <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
          <Column classNames={['is-paddingless', 'is-one-fifth', 'is-centered']}>
            <AnimationContainer>
              <Animation animationType='success' />
            </AnimationContainer>
          </Column>
        </Row>
        <LinkContainer>
          <Text align='center' textSize='h3' fontColor={color.success} text={formatMessage(resetPasswordMessages.passwordReset)} />
        </LinkContainer>

        <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
          <Column classNames={['is-paddingless', 'is-4']}>
            <ButtonContainer>
              <Button fullWidth padding='8px 24px' fontSize='p' outline={true} style='success' disabled={false} onClick={handleGoToGetStarted} ariaLabel={formatMessage(messages.signIn)} title={formatMessage(messages.signIn)} />
            </ButtonContainer>
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default (injectIntl(ResetConfirmation))
export { ResetConfirmation as UnwrappedResetConfirmation }
