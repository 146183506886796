// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { messages } from './i18n/messages/index'

import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useNavigate } from 'react-router-dom'

/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/Text'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'

const LinkContainer: StyledComponent<{}> = styled.div`
  padding: 8px 16px;
`

const Spacer: StyledComponent<{}> = styled.div`
  padding-bottom: 8px;
`

function LoadingSSO ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const navigate = useNavigate()
  const authUrl = useSelector(state => state.fetching.authUrl)

  const handleOpenRedirectPopUp = (e: any) => {
    e.preventDefault()
    window.open(authUrl, '_blank', 'toolbar=0,location=0,menubar=0')
  }

  const handleRefresh = (e: any) => {
    e.preventDefault()
    navigate('/')
  }
  return (
    <Row classNames={['is-marginless', 'is-centered', 'is-multiline']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <LinkContainer>
            <Text align='left' textSize='p' fontColor={color.grey600} text={formatMessage(messages.loadingSSO)}>
              <Spacer />
              <Button width='150px' textTransform='capitalize' fill themeColor={color.info} title='Login' fontSize='p' onClick={handleOpenRedirectPopUp} />
            </Text>
          </LinkContainer>
          <LinkContainer>
            <Text align='left' textSize='p' fontColor={color.grey600} text={formatMessage(messages.refresh)}>
              <Spacer />
              <Button width='150px' textTransform='capitalize' fill themeColor={color.info} title='Go Home' fontSize='p' onClick={handleRefresh} />
            </Text>
          </LinkContainer>
        </Column>
      </Column>
    </Row>
  )
}

export default (injectIntl(LoadingSSO))
export { LoadingSSO as UnwrappedLoadingSSO }
