// @flow

import ar from './ar.json'
import bgBG from './bg-BG.json'
import cs from './cs.json'
import de from './de.json'
import en from './en.json'
import enUS from './en-US.json'
import es from './es.json'
import fr from './fr.json'
import hu from './hu.json'
import ja from './ja.json'
import ko from './ko.json'
import msMy from './ms-MY.json'
import pl from './pl.json'
import ptBR from './pt-BR.json'
import ptPT from './pt-PT.json'
import ro from './ro.json'
import ru from './ru.json'
import tr from './tr.json'
import zhCN from './zh-CN.json'
import zhTW from './zh-TW.json'

export default {
  ar,
  bg: bgBG,
  'bg-BG': bgBG,
  cs,
  de,
  en,
  'en-us': enUS,
  es,
  fr,
  hu,
  ja,
  ko,
  ms: msMy,
  'ms-My': msMy,
  pl,
  pt: ptPT,
  'pt-br': ptBR,
  'pt-pt': ptPT,
  ro,
  ru,
  tr,
  'zh-cn': zhCN,
  'zh-tw': zhTW
}
