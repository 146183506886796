// @flow

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEyeSlash, faEye, faCheck, faTimes, faExclamationTriangle, faTimesCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

library.add(faEyeSlash, faEye, faCheck, faTimes, faExclamationTriangle, faTimesCircle, faInfoCircle, faCircle)

const icons = {
  eyeClose: faEyeSlash,
  eyeOpen: faEye,
  check: faCheck,
  x: faTimes,
  warning: faExclamationTriangle,
  close: faTimesCircle,
  info: faInfoCircle,
  circle: faCircle
}

type IconProps = {
  fillColor: string,
  iconName: string,
  size: string
}
export const Icon = (props: IconProps) => {
  return (
    <FontAwesomeIcon
      icon={icons[props.iconName]}
      color={props.fillColor}
      size={props.size}
    />
  )
}
