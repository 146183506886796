// @flow

import React from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import color from '../styles/color'

/* istanbul ignore next */
const LinkSpan: StyledComponent<{}> = styled.span`
  font-family: Raleway;
  font-weight: 400;
  font-size: 16px;
  color: ${color.info} !important;
  letter-spacing: 0.27px;
  line-height: 19px;
  text-decoration: underline;
  @media (max-width: 380px) {
    font-size: 14px;
  }
  cursor: pointer;
`

// add for testing purpose
LinkSpan.displayName = 'LinkSpan'

type LinkProps = {
  linkText: string,
  onClick: Function
}

export const Link = (props: LinkProps) => {
  return (
    <LinkSpan data-cy='linkcy' onClick={props.onClick}>{props.linkText}</LinkSpan>
  )
}

export default Link
