// @flow

import React, { useEffect } from 'react'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { messages } from '../i18n/messages/index'
import { Button, Icons } from '../Components/index'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'michelangelo/dist/Components/NativeText'
import color from 'michelangelo/dist/Components/styles/color'

const forgotPasswordMessages = defineMessages({
  accountLocked: {
    id: 'forgotPasswordPage.accountLocker',
    defaultMessage: 'You have too many failed login attempts. Your account has been locked.'
  },
  everyoneForgets: {
    id: 'forgotPasswordPage.everyoneForgets',
    defaultMessage: 'Don’t worry, everyone forgets!'
  },
  passwordReset: {
    id: 'forgotPasswordPage.passwordReset',
    defaultMessage: 'The password reset request has been sent to the email address you provided.'
  },
  passwordResetInstructions: {
    id: 'forgotPasswordPage.passwordResetInstructions',
    defaultMessage: 'If you do not receive an email, please check that you have supplied the correct email address and that it is not in your spam folder.'
  },
  contactCustomerCare: {
    id: 'forgotPasswordPage.contactCustomerCare',
    defaultMessage: 'If you do not receive an email within 15 minutes, please contact '
  },
  customerCareLink: {
    id: 'login.customerCareLink',
    defaultMessage: 'Customer Care'
  }
})

const LinkContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 20px;
  @media (max-width: 380px) {
    margin: 0 15px;
  }
`

// const TextContentLink: StyledComponent<{}> = styled.div`
//   justify-content: center;
//   flex-direction: row;
//   font-size: 14px;
//   font-family: Raleway;
//   color: ${props => props.color}
// `

const IconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: -60px;
  margin-right: 30px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  @media (max-width: 370px) {
    margin-right: 10px;
  }
`

const CustomCareLink: StyledComponent<{}> = styled.div`
  @media (max-width: 380px) {
    margin: 0 15px;
  }
`

const LinkContent: StyledComponent<{}> = styled.p`
  @media (max-width: 570px) {
      font-size: 14px;
      color:  ${props => props.color}; 
      font-family: Raleway;
      width: 90%;
      text-align: center;
  }
  @media (max-width: 460px) {
    font-size: 14px;
    color:  ${props => props.color};
    font-family: Raleway;
    width: 330px;
    text-align: center;
  }
`

// add for testing purpose
LinkContainer.displayName = 'LinkContainer'
IconContainer.displayName = 'IconContainer'

function ForgotPassword ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const errorStatus = useSelector(state => state.status.userStatus)

  // Use history to go back a page
  const handleGoToGetStarted = (e: any) => {
    e.preventDefault()
    navigate(-2)
  }

  useEffect(() => {
    dispatch({ type: 'ANALYTICS_ACCOUNT_LOCKED' })
    // eslint-disable-next-line
  }, [errorStatus])

  let lockedAccount
  /* istanbul ignore else */
  if (errorStatus === 'ACCOUNT_LOCKED') {
    lockedAccount = (
      <LinkContainer>
        <Text align='center' textSize='h3' fontColor={color.danger} text={formatMessage(forgotPasswordMessages.accountLocked)} />
      </LinkContainer>
    )
  }

  return (
    <div className='transition-item detail-page'>
      <IconContainer onClick={handleGoToGetStarted}>
        <Icons fillColor={color.default} size='2x' iconName='close' />
      </IconContainer>
      <LinkContainer>
        <Text align='center' textSize='h2' fontColor={color.lightTextDefault} text={formatMessage(forgotPasswordMessages.everyoneForgets)} />
      </LinkContainer>
      <Row>
        <Column classNames={['is-centered']} lg={12}>
          {lockedAccount}
          <LinkContainer>
            <Text align='center' textSize='p' fontColor={color.lightTextDefault} text={formatMessage(forgotPasswordMessages.passwordReset)} />
          </LinkContainer>
          <LinkContainer>
            <Text align='center' textSize='p' fontColor={color.lightTextDefault} text={formatMessage(forgotPasswordMessages.passwordResetInstructions)} />
          </LinkContainer>
          <LinkContainer>
            <CustomCareLink style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Text align='left' textSize='p' fontColor={color.grey800}>
                  <LinkContent color={color.grey800}>
                    {formatMessage(forgotPasswordMessages.contactCustomerCare)}
                    <a href='mailto:support@theemployeeapp.com'> {formatMessage(forgotPasswordMessages.customerCareLink)}</a>
                  </LinkContent>
                </Text>
              </div>
            </CustomCareLink>
          </LinkContainer>
          <div className='center continue-button'>
            <Button kind='default' disabled={false} type='button' onClick={handleGoToGetStarted}>{formatMessage(messages.login)}</Button>
          </div>
        </Column>
      </Row>
    </div>
  )
}

export default injectIntl(ForgotPassword)
export { ForgotPassword as UnconnectedForgotPassword }
