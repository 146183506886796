// @flow

import React, { useEffect, useState } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Actions from '../AlfredApp/actions'
import { messages } from '../i18n/messages/index'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { isEmpty, isEqual, includes } from 'lodash'
import qs from 'qs'
import { useLocation, useNavigate } from 'react-router-dom'
/** Michelangelo Components */
// import { Icon } from '../Components/Icons'
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Input from 'michelangelo/dist/Components/NativeInput'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import WebIcon from 'michelangelo/dist/Components/Icon'
import { RequirementSteps } from '../Components'

// export for tests
export const newPasswordMessages = defineMessages({
  passwordsNotMatch: {
    id: 'newPassword.passwordNotMatch',
    defaultMessage: 'Password doesn\'t match'
  },
  resetPassword: {
    id: 'reset.resetPassword',
    defaultMessage: 'Reset Password'
  },
  passwordCheckFailed: {
    id: 'reset.passwordCheckFailed',
    defaultMessage: 'Your password does not have the following below:'
  }
})

const ErrorMsg: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  word-wrap: break-word;
`
const ButtonContainer: StyledComponent<{}> = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
const InputContainer: StyledComponent<{}> = styled.div`
  padding-top: 24px;
`
const ValidatorContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 15px;
  padding: 8px;
`
const ErrorHeaderContainer: StyledComponent<{}> = styled.div`
  padding: 5px;
`

const LoadingContainer: StyledComponent<{}> = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
`

const LoadingText: StyledComponent<{}> = styled.div`
  padding-left: 8px;
`

const CloseIconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  @media (max-width: 370px) {
    margin-right: 8px;
    margin-top: 8px;
  }
`

const PasswordContainer: StyledComponent<{}> = styled.div`
  background-color: ${color.danger}20;
  border: 1px solid ${color.danger};
  border-radius: 6px;
  color: ${color.danger};
  padding: 8px;
  margin-top: 8px;
`

const ErrorContainer: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  word-wrap: break-word;
  padding-top: 10px;
`

// add for testing purpose
ErrorMsg.displayName = 'ErrorMsg'
ButtonContainer.displayName = 'ButtonContainer'
InputContainer.displayName = 'InputContainer'
ValidatorContainer.displayName = 'ValidatorContainer'

function ResetPassword ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [token, setToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [misMatch, setMismatch] = useState(false)
  const [validPassword, setValidPassword] = useState(false)
  const [displayValidator, setDisplayValidator] = useState(false)
  const [type, setType] = useState('password')

  let errors = useSelector(state => state.errorFetching.errors)
  let isCheckingPassword = useSelector(state => state.fetching.isCheckingPassword)
  const isErrorFetchingUser = useSelector(state => state.errorFetching.isErrorFetchingUser)

  useEffect(() => {
    const query = location.search
    const queryParams = qs.parse(query.replace(/^.*\?/, ''))
    const token = queryParams.token
    setToken(token)

    dispatch({ type: 'ANALYTICS_RESET_PASSWORD_SERVER_ERROR' })

    if (!includes(location.pathname, '/reset')) {
      dispatch(Actions.resetUserError())
    }
    // eslint-disable-next-line
  }, [])

  const handleNewPassword = async (e: any) => {
    setNewPassword(e.target.value)

    if ((e.target.value.length === confirmNewPassword.length) && !(e.target.value === confirmNewPassword)) setMismatch(true)
    if (e.target.value === confirmNewPassword) {
      setMismatch(false)
    } else {
      await dispatch(Actions.toggleCheckPassword())
    }
  }

  const handleOnPasswordValid = (isValid: boolean) => {
    setValidPassword(isValid)
    isCheckingPassword = false
  }

  /**
   * On blur will hide the password validator
   */
  const handleOnBlur = () => {
    if (!newPassword) {
      setDisplayValidator(false)
    }
  }

  const handleConfirmNewPassword = async (e: any) => {
    setConfirmNewPassword(e.target.value)
    if ((e.target.value.length === newPassword.length) && !(e.target.value === newPassword)) setMismatch(true)
    if (e.target.value === newPassword) {
      setMismatch(false)
    }
  }

  /**
   * On focus will show the password validator
   */
  const handleOnFocus = () => {
    setDisplayValidator(true)
  }

  const handleOnConfirmFocus = () => {
    setDisplayValidator(false)
  }

  const handleSubmitNewPassword = async (e: any) => {
    e.preventDefault()

    if (newPassword && confirmNewPassword) {
      if (isEqual(newPassword, confirmNewPassword) && !isEmpty(token)) {
        const res = await dispatch(Actions.resetPassword(token, newPassword, confirmNewPassword))
        if (res && res.errors && res.errors.length > 0) {
          isCheckingPassword = true
          errors = res.errors
        } else if (res && res.type && res.type === 'USER_FETCHING_ERROR') {
          errors = ['Invalid reset link']
        } else {
          navigate(`/reset-confirmation?token=${token}`)
        }
      }
    }
  }

  const getTheme = () => {
    let newPasswordTheme = color.lightTextDefault
    let confirmTheme = color.lightTextDefault
    let buttonTheme = color.disabled
    let checkTheme = color.disabled
    let disabled = true

    const validToSubmit = newPassword === confirmNewPassword && confirmNewPassword !== ''
    const fieldsAreEqual = isEqual(newPassword, confirmNewPassword)

    if (!isEmpty(newPassword) && !validPassword) {
      newPasswordTheme = color.danger
    }

    if (!isEmpty(confirmNewPassword) && !validPassword) {
      confirmTheme = color.danger
    }

    if (fieldsAreEqual && (newPassword !== '')) {
      newPasswordTheme = color.success
      confirmTheme = color.success
      checkTheme = color.success
      disabled = false
    } else {
      newPasswordTheme = color.success
      confirmTheme = color.danger
    }

    if (validToSubmit) {
      buttonTheme = color.success
    }

    return { newPasswordTheme, confirmTheme, buttonTheme, disabled, checkTheme }
  }

  const getErrorMessage = () => {
    if (misMatch) {
      return (
        <Column classNames={['is-paddingless', 'is-12']}>
          <Column classNames={['is-paddingless']}>
            <PasswordContainer validPassword={false}>
              <ErrorHeaderContainer>
                <Text align='left' fontColor={color.danger} text={formatMessage(messages.misMatch)} textSize='p' />
              </ErrorHeaderContainer>
            </PasswordContainer>
          </Column>
        </Column>
      )
    }
  }

  const getErrorMessageTry = (displayError: boolean, text: string) => {
    if (displayError) {
      return (
        <ErrorContainer data-cy='errormessagecy'>
          <Text textSize='h4' align='left' fontColor={color.danger} text={text} />
        </ErrorContainer>
      )
    } else return null
  }

  const getLoadingIndicator = () => {
    if (isCheckingPassword) {
      return (
        <LoadingContainer>
          <WebIcon color={color.grey600} iconName='spinner' pulse />
          <LoadingText>
            <Text text={formatMessage(messages.verify)} textSize='h5' fontColor={color.grey800} />
          </LoadingText>
        </LoadingContainer>
      )
    }
  }

  const getBodyButtons = () => {
    const { buttonTheme } = getTheme()
    const loading = getLoadingIndicator()
    const error = getErrorMessage()
    const tryAgainError = getErrorMessageTry(isErrorFetchingUser, formatMessage(messages.errorMessage))
    const validToSubmit = newPassword === confirmNewPassword && confirmNewPassword !== ''

    return (
      <Row classNames={['is-marginless', 'is-mobile']}>
        <Column classNames={['is-paddingless']}>
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless']}>
              {loading}
            </Column>
          </Row>
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless']}>
              {error}
              {tryAgainError}
            </Column>
          </Row>
          <Row classNames={['is-marginless', 'is-mobile']}>
            <ButtonContainer>
              <Column classNames={['is-paddingless', 'is-half']}>
                <Button fill onClick={handleSubmitNewPassword} themeColor={buttonTheme} disabled={!validToSubmit} type='submit' ariaLabel={formatMessage(messages.setNewPassword)} title={formatMessage(messages.setNewPassword)} />
              </Column>
            </ButtonContainer>
          </Row>
        </Column>
      </Row>
    )
  }

  const handleDisplayPassword = (e: any) => {
    e.preventDefault()
    const oldType = type
    const isTextOrHide = (oldType === 'password')
    const newType = (isTextOrHide) ? 'text' : 'password'
    setType(newType)
  }

  const bodyButtons = getBodyButtons()
  return (
    <Row classNames={['is-magrinless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <CloseIconContainer onClick={() => navigate(-1)}>
          <Icon fillColor={color.default} size={24} iconName='close' />
        </CloseIconContainer>
        <HeaderContainer>
          <Text align='center' textSize='h1' fontColor={color.lightDefaultTheme} text={formatMessage(newPasswordMessages.resetPassword)} />
        </HeaderContainer>
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <div>
            <HeaderContainer>
              <Text align='left' color={color.grey900} text={formatMessage(messages.newPasswordInstructions)} textSize='h5' />
            </HeaderContainer>
            <ValidatorContainer>
              <RequirementSteps
                onUpdate={(isValid) => handleOnPasswordValid(isValid)}
                password={newPassword}
                repeatPassword={confirmNewPassword}
                displayValidator={displayValidator}
                isCheckingPassword={isCheckingPassword}
                fromRegistration={false}
                errors={errors}
              />
            </ValidatorContainer>
          </div>
          <form onSubmit={handleSubmitNewPassword}>
            <InputContainer>
              <Input
                _lr-hide
                value={newPassword}
                label={formatMessage(messages.newPassword)}
                onChange={handleNewPassword}
                type={type === 'password' ? 'password' : 'text'}
                iconName={type === 'password' ? 'eyeClose' : 'eyeOpen'}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                onClickIcon={handleDisplayPassword}
              />
            </InputContainer>
            <InputContainer>
              <Input
                _lr-hide
                value={confirmNewPassword}
                label={formatMessage(messages.confirmNewPassword)}
                onChange={handleConfirmNewPassword}
                type={type === 'password' ? 'password' : 'text'}
                iconName={type === 'password' ? 'eyeClose' : 'eyeOpen'}
                onFocus={handleOnConfirmFocus}
                onClickIcon={handleDisplayPassword}
              />
            </InputContainer>
            {bodyButtons}
          </form>
        </Column>
      </Column>
    </Row>
  )
}

export default (injectIntl(ResetPassword))
export { ResetPassword as UnconnectedResetPassword }
