// @flow

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import logo from '../Images/employeeApp-logo.png'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { StyledComponent } from 'styled-components'
import { Link } from 'react-router-dom'
import type { StateType } from '../AlfredApp/reducers'
import { connect } from 'react-redux'
/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'

type FooterProps = {
  appVersion?: string,
  language?: string
} & InjectIntlProvidedProps

// exported for testing
export const footerMessages = defineMessages({
  appriseLogoAlt: {
    id: 'footer.appriseLogoAlt',
    defaultMessage: 'theEMPLOYEEapp Logo'
  },
  help: {
    id: 'footer.help',
    defaultMessage: 'help'
  },
  powerBy: {
    id: 'footer.powerBy',
    defaultMessage: 'powered by'
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: 'privacy'
  },
  version: {
    id: 'footer.version',
    defaultMessage: 'V'
  }
})

// need phone logic here
const EmployeeAppLogo: StyledComponent<{}> = styled.img`
  max-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: 1px;
`
const Container: StyledComponent<{}> = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: inline;
  height: 75px;
  font-size: 12px;
  color: ${color.darkPrimary};
  font-family: Helvetica;
  line-height: 16px;
  font-weight: 500;
`
const LinkOneContainer: StyledComponent<{}> = styled.div`
  display: flex;
  justify-content: flex-start;
`
const LinkTwoContainer: StyledComponent<{}> = styled.div`
  display: flex;
  margin-bottom: 10px;
  //justify-content: flex-end;
`
const LinkStyle: StyledComponent<{}> = styled.a`
  cursor: pointer;
  color: ${color.darkPrimary};
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
`
const Link2 = styled(Link)`
  margin-left: 5px;
  cursor: pointer !important;
  color: ${color.darkPrimary} !important;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
`
const VersionNumber: StyledComponent<{}> = styled.div`
  margin-left: 5px;
  color: ${color.darkPrimary} !important;
  text-decoration: none;
`
const SeparatorBar: StyledComponent<{}> = styled.span`
  border-right: 1px solid #A3AEBD;
  margin-left: 10px;
  margin-right: 5px;
}
`

// add for testing purpose
EmployeeAppLogo.displayName = 'EmployeeAppLogo'
Container.displayName = 'Container'
LinkOneContainer.displayName = 'LinkOneContainer'
LinkTwoContainer.displayName = 'LinkTwoContainer'
LinkStyle.displayName = 'LinkStyle'
Link2.displayName = 'Link2'

class RawFooter extends React.Component<FooterProps, {}> {
  props: FooterProps
  /* istanbul ignore next */
  static mapStateToProps (state: StateType) {
    return {
      appVersion: state.account.appVersion,
      language: state.account.language
    }
  }

  getAppVersion = (appVersion: string) => {
    const { intl: { formatMessage } } = this.props
    return <VersionNumber><span>{`${formatMessage(footerMessages.version)} ${appVersion}`}</span></VersionNumber>
  }

  render () {
    const { intl: { formatMessage }, appVersion } = this.props
    const getAppVersion = appVersion ? this.getAppVersion(appVersion) : null
    const customerCareLink = '/customer-care' + window.location.search

    return (
      <Row classNames={['is-marginless', 'is-mobile']}>
        <Container>
          <Column classNames={['is-12']}>
            <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-12-mobile']}>
              <Row classNames={['is-marginless', 'is-centered', 'is-mobile']}>
                <Column classNames={['is-paddingless']}>
                  <LinkTwoContainer data-cy='privacycy'>
                    <LinkStyle href='https://app.theemployeeapp.com/signup/privacy.html' target='_blank'>{formatMessage(footerMessages.privacy)}</LinkStyle>
                    <SeparatorBar />
                    <Link2 to={customerCareLink}>{formatMessage(footerMessages.help)}</Link2>
                  </LinkTwoContainer>
                  <LinkOneContainer data-cy='powerbyfootercy'>
                    <span style={{ textTransform: 'capitalize' }}>{formatMessage(footerMessages.powerBy)}</span>
                    <EmployeeAppLogo data-cy='logofootercy' src={logo} alt={formatMessage(footerMessages.appriseLogoAlt)} />
                    {getAppVersion}
                  </LinkOneContainer>
                </Column>
              </Row>
            </Column>
          </Column>
        </Container>
      </Row>
    )
  }
}

export default connect(RawFooter.mapStateToProps, null)(injectIntl(RawFooter))
export { RawFooter as UnwrappedFooter }
