// @flow

import React, { useState } from 'react'
import * as Actions from '../AlfredApp/actions'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import qs from 'qs'
import { injectIntl, defineMessages } from 'react-intl'
import validateDomain from '../utils/whiteListHelper'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { StyledComponent } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { messages } from '../i18n/messages/index'

import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Input from 'michelangelo/dist/Components/NativeInput'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import Text from 'michelangelo/dist/Components/NativeText'

const HeaderContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 20px;
  margin-top: 60px;
`

const InputContainer: StyledComponent<{}> = styled.div`
  padding-top: 40px;
`

const ErrorContainer: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  word-wrap: break-word;
  padding-top: 10px;
`

const ButtonContainer: StyledComponent<{}> = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

// added for testing purpose
HeaderContainer.displayName = 'HeaderContainer'
InputContainer.displayName = 'InputContainer'
ErrorContainer.displayName = 'ErrorContainer'
ButtonContainer.displayName = 'ButtonContainer'
Text.displayName = 'Text'

export const changeEmailMessages = defineMessages({
  newEmail: {
    id: 'newEmail',
    defaultMessage: 'New Email'
  },
  confirmNewEmail: {
    id: 'confirmNewEmail',
    defaultMessage: 'Confirm New Email'
  },
  emailsDoNotMatchWarning: {
    id: 'emailsDoNotMatchWarning',
    defaultMessage: 'Emails do not match'
  }
})

function ChangeEmail ({ intl: { formatMessage } }: InjectIntlProvidedProps) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [passwordType, setPasswordType] = useState('password')
  const [confirmNewEmail, setConfirmNewEmail] = useState('')
  const [password, setPassword] = useState('')

  const displayTryAgain = useSelector(state => state.errorFetching.changeEmailTryAgain)

  const query = location.search
  const queryParams = qs.parse(query.replace(/^.*\?/, ''))
  let callbackUrl
  if (queryParams && queryParams.callbackUrl) {
    callbackUrl = validateDomain(queryParams.callbackUrl) ? queryParams.callbackUrl : ''
  }

  const handleDisplayPassword = (e: any) => {
    e.preventDefault()
    const isTextOrHide = (passwordType === 'password')
    const newType = (isTextOrHide) ? 'text' : 'password'
    setPasswordType(newType)
  }

  const handleConfirmEmailChange = (e: any) => {
    setConfirmNewEmail(e.target.value)
  }

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value)
  }

  const getTheme = () => {
    let emailTheme = color.lightTextDefault
    let newEmailTheme = color.lightTextDefault
    let confirmEmailTheme = color.lightTextDefault
    let passwordTheme = color.lightTextDefault
    let buttonTheme = color.disabled
    let buttonDisabled = true

    if (email && Actions.isEmail(email)) emailTheme = color.info
    else if (email) emailTheme = color.danger

    if (newEmail && Actions.isEmail(newEmail)) newEmailTheme = color.info
    else if (newEmail) newEmailTheme = color.danger

    if (confirmNewEmail && Actions.isEmail(confirmNewEmail)) {
      if (newEmail && Actions.isEmail(newEmail)) {
        if (newEmail === confirmNewEmail) {
          newEmailTheme = color.success
          confirmEmailTheme = color.success
        } else confirmEmailTheme = color.danger
      }
    } else if (confirmNewEmail) confirmEmailTheme = color.danger

    if (password) passwordTheme = color.info

    if (Actions.isEmail(email) && Actions.isEmail(newEmail) && Actions.isEmail(confirmNewEmail) && password) {
      if (newEmail === confirmNewEmail) {
        buttonTheme = color.success
        buttonDisabled = false
      }
    }

    return { emailTheme, newEmailTheme, confirmEmailTheme, buttonDisabled, buttonTheme, passwordTheme }
  }

  const getErrorMessage = (displayError: ?boolean, text: string) => {
    if (displayError) {
      return (
        <ErrorContainer data-cy='errormessagecy'>
          <Text textSize='h4' align='left' fontColor={color.danger} text={text} />
        </ErrorContainer>
      )
    } else return null
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const response = await dispatch(Actions.changeEmail(email, newEmail, confirmNewEmail, password, callbackUrl))
    if (response && response.success) {
      navigate('/change-email-password-success', {
        state: { callbackUrl, type: 'email' }
      })
    }
  }

  const handleCurrentEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handleNewEmailChange = (e: any) => {
    setNewEmail(e.target.value)
  }

  const { emailTheme, newEmailTheme, confirmEmailTheme, buttonTheme, buttonDisabled, passwordTheme } = getTheme()
  const emailsDoNotMatchWarning = getErrorMessage(confirmEmailTheme === color.danger, formatMessage(changeEmailMessages.emailsDoNotMatchWarning))
  const tryAgainError = getErrorMessage(displayTryAgain, formatMessage(messages.errorMessage))

  // disabled the button click because michelangelo doesnt respect disabled attribute
  // TODO: Movie this logic into michelangelo
  let buttonClick
  if (!buttonDisabled) buttonClick = onSubmit

  return (
    <Row classNames={['is-magrinless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <HeaderContainer>
          <Text align='center' textSize='h1' fontColor={color.lightDefaultTheme} text={formatMessage(messages.changeEmail)} />
        </HeaderContainer>
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <InputContainer>
            <Input
              inputTheme={emailTheme}
              value={email}
              label={formatMessage(messages.currentEmail)}
              onChange={handleCurrentEmailChange}
              type='text'
              maxLength={254}
            />
          </InputContainer>
          <InputContainer>
            <Input
              inputTheme={newEmailTheme}
              value={newEmail}
              label={formatMessage(changeEmailMessages.newEmail)}
              onChange={handleNewEmailChange}
              type='email'
            />
          </InputContainer>
          <InputContainer>
            <Input
              inputTheme={confirmEmailTheme}
              value={confirmNewEmail}
              label={formatMessage(changeEmailMessages.confirmNewEmail)}
              onChange={handleConfirmEmailChange}
              type='email'
            />
          </InputContainer>
          {emailsDoNotMatchWarning}
          <InputContainer>
            <Input
              _lr-hide
              inputTheme={passwordTheme}
              value={password}
              label={formatMessage(messages.password)}
              onChange={handlePasswordChange}
              type={passwordType === 'password' ? 'password' : 'text'}
              iconName={passwordType === 'password' ? 'eyeClose' : 'eyeOpen'}
              onClickIcon={handleDisplayPassword}
            />
          </InputContainer>
          {tryAgainError}
          <Row classNames={['is-marginless', 'is-mobile']}>
            <ButtonContainer>
              <Column classNames={['is-paddingless', 'is-half']}>
                <Button fill themeColor={buttonTheme} type='button' onClick={buttonClick} title={formatMessage(messages.save)} />
              </Column>
            </ButtonContainer>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}

export default (injectIntl(ChangeEmail))
export { ChangeEmail as ChangeEmailUnwrapped }
