// @flow

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl, defineMessages } from 'react-intl'
import * as Actions from '../AlfredApp/actions'
import { messages } from '../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import { developerSubdomain } from '../utils/config'
import isEmail from 'validator/lib/isEmail'

/** Michelangelo Components */
import Icon from 'michelangelo/dist/Components/NativeIcon'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Dropdown from 'michelangelo/dist/WebComponents/Inputs/Dropdown'
import Input from 'michelangelo/dist/Components/NativeInput'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'

const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: 10px;
  float: right;
`
const ErrorContainer: StyledComponent<{}> = styled.div`
  word-wrap: break-word;
  background-color: ${color.danger}20;
  border: 1px solid ${color.danger};
  border-radius: 6px;
  color: ${color.danger};
  padding: 8px;
  margin-top: 8px;
`
const DescriptionInput: StyledComponent<{}> = styled.textarea`
  height: 200px;
  word-break: break-word;
  padding: 10px;
  font-size: 14px;
  resize: none;
  width: 100%;
  &:focus {
    outline: none;
  }
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  padding: 24px 24px 48px 24px;
`
const CloseIconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  @media (max-width: 370px) {
    margin-right: 8px;
    margin-top: 8px;
  }
`

const subjectMessages = defineMessages({
  subjectAccount: {
    id: 'customerCare.subjectAccount',
    defaultMessage: 'Create New Account'
  },
  subjectLogin: {
    id: 'customerCare.subjectLogin',
    defaultMessage: 'Login'
  },
  subjectOther: {
    id: 'customerCare.subjectOther',
    defaultMessage: 'Other'
  },
  subjectMobileRequest: {
    id: 'customerCare.subjectMobileRequest',
    defaultMessage: 'Mobile App Customer Care Request'
  },
  subjectCarouselArchivedContent: {
    id: 'customerCare.subjectCarouselArchivedContent',
    defaultMessage: 'Carousel Archived Content'
  },
  subjectUserData: {
    id: 'customerCare.subjectUserData',
    defaultMessage: 'Request or Delete User Data'
  }
})

type LoginProps = {
  onChangeLang: Function
} & InjectIntlProvidedProps

function CustomerCare ({ intl: { formatMessage }, onChangeLang }: LoginProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    findSubjectFromQueryParams()
  }, [])

  const fetchingError = useSelector(state => state.errorFetching.isErrorFetchingRegistration)
  const accountDetails = useSelector(state => state.account.account)
  let subdomain = useSelector(state => state.account.subdomain)
  let callbackUrl = useSelector(state => state.account.callbackUrl)
  let appVersion = useSelector(state => state.account.appVersion)
  let language = useSelector(state => state.account.language)

  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [subjectValue, setSubjectValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const isGenericFlow = useSelector(state => state.account.isGenericFlow)
  const query = location.search
  const queryParams = qs.parse(query.replace(/^.*\?/, ''))

  // add the #/access to the callback url if present
  if (location.hash) queryParams.callbackUrl = queryParams.callbackUrl + location.hash
  if (queryParams.subdomain) {
    subdomain = queryParams.subdomain
  }
  if (!subdomain && developerSubdomain) {
    subdomain = developerSubdomain
  }
  if (queryParams.callbackUrl) callbackUrl = queryParams.callbackUrl
  if (queryParams.appVersion) appVersion = queryParams.appVersion
  if (queryParams.language) language = queryParams.language
  if (language) onChangeLang(language)
  if (location.pathname === '/customer-care' && !isGenericFlow) {
    Actions.getAccountDetails(subdomain, true)
  }
  /**
   * NEED GENERIC FLOW LOGIC HERE FOR NOW SET AT PROPS
   */
  dispatch(Actions.setURLQueryParams(callbackUrl, appVersion, false, subdomain, isGenericFlow, language))

  const findSubjectFromQueryParams = () => {
    dropdounList.forEach((d, index) => {
      if (index + 1 === Number(queryParams.subject)) {
        handleSubjectChange(d)
      }
    })
  }

  const areInputsValid = (nameValue: string, emailValue: string, subjectValue: string, descriptionValue: string) => {
    const areInputsValid = (
      !isEmpty(nameValue) &&
      !isEmpty(emailValue) &&
      !isEmpty(subjectValue) &&
      !isEmpty(descriptionValue) &&
      isEmail(emailValue)
    )
    return areInputsValid
  }

  const handleOnContinueClick = async (e: any) => {
    e.preventDefault()
    const accountId = accountDetails ? accountDetails._id : ''
    const applicationName = accountDetails ? accountDetails.applicationName : 'GENERIC'
    const result = await dispatch(Actions.contactCustomerCare(accountId, nameValue, emailValue, subjectValue, descriptionValue, applicationName))
    if (result.success) {
      navigate('/customer-care-success' + window.location.search)
    }
    return result
  }

  const handleNameChange = (e: any) => {
    setNameValue(e.target.value)
  }

  const handleEmailChange = (e: any) => {
    setEmailValue(e.target.value)
  }

  const handleSubjectChange = (value: any) => {
    setSubjectValue(value)
  }

  const handleDescriptionChange = (e) => {
    setDescriptionValue(e.currentTarget.value)
  }

  const getErrorMessage = () => {
    let errorMessage = ''

    if (fetchingError) {
      errorMessage = formatMessage(messages.errorMessage)
    }

    const errorDiv = (
      <Column classNames={['is-paddingless', 'is-12']}>
        <ErrorContainer data-cy='errormessagecy'>
          <Text textSize='p' align='left' fontColor={color.danger} text={errorMessage} />
        </ErrorContainer>
      </Column>
    )
    return errorMessage ? errorDiv : null
  }

  const getInputTheme = () => {
    let buttonTheme = color.disabled
    let { nameTheme, emailTheme } = color.lightTextDefault

    const btnEnabled = areInputsValid(nameValue, emailValue, subjectValue, descriptionValue)
    if (nameValue) {
      nameTheme = color.info
    }
    if (emailValue) {
      emailTheme = color.info
    }
    if (btnEnabled) buttonTheme = color.success
    return { btnEnabled, buttonTheme, nameTheme, emailTheme }
  }

  const errorMessage = getErrorMessage()
  const { btnEnabled, buttonTheme, nameTheme, emailTheme } = getInputTheme()
  const dropdounList = [
    formatMessage(subjectMessages.subjectAccount),
    formatMessage(subjectMessages.subjectLogin),
    formatMessage(subjectMessages.subjectOther),
    formatMessage(subjectMessages.subjectMobileRequest),
    formatMessage(subjectMessages.subjectCarouselArchivedContent),
    formatMessage(subjectMessages.subjectUserData)
  ]

  return (
    <Row classNames={['is-magrinless', 'is-mobile']}>
      <Column classNames={['is-paddingless', 'is-8-desktop', 'is-offset-2-desktop', 'is-10-tablet', 'is-offset-1-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
        <CloseIconContainer onClick={() => navigate(-1)}>
          <Icon fillColor={color.default} size={24} iconName='close' />
        </CloseIconContainer>
        <HeaderContainer>
          <Text align='center' textSize='h3' fontColor={color.lightDefaultTheme} text={formatMessage(messages.customerCare)} />
        </HeaderContainer>
        <form onSubmit={handleOnContinueClick}>
          <Row classNames={['is-maringless']}>
            <Column classNames={['is-one-third', 'is-12-mobile']}>
              <Input
                inputTheme={nameTheme}
                value={nameValue}
                label={formatMessage(messages.customerCareName)}
                type='text'
                onChange={handleNameChange}
              />
            </Column>
            <Column classNames={['is-one-third', 'is-12-mobile']}>
              <Input
                inputTheme={emailTheme}
                value={emailValue}
                label={formatMessage(messages.customerCareEmailLabel)}
                type='text'
                onChange={handleEmailChange}
              />
            </Column>
            <Column classNames={['is-one-third', 'is-12-mobile']}>
              <Dropdown
                label={formatMessage(messages.customerCareSubject)}
                list={dropdounList}
                selected={subjectValue}
                onListItemClick={handleSubjectChange}
              />
            </Column>
          </Row>
          <Row classNames={['is-marginless']}>
            <Column classNames={['is-paddingless', 'is-12']}>
              <DescriptionInput value={descriptionValue} onChange={handleDescriptionChange} />
            </Column>
          </Row>
        </form>
        {errorMessage}
        <ButtonContainer>
          <Button fill themeColor={buttonTheme} title={formatMessage(messages.customerCareSubmit)} onClick={handleOnContinueClick} disabled={!btnEnabled} />
        </ButtonContainer>
      </Column>
    </Row>
  )
}

export default injectIntl(CustomerCare)
