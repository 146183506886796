// @flow

import { configureStore } from '@reduxjs/toolkit'
// $FlowFixMe - need redux-persist to release a new version that fixes flow errors
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
// $FlowFixMe - need redux-persist to release a new version that fixes flow errors
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import Reducers from './reducers'
import type { Action } from './actions'
import type { StateType } from './reducers'
import type { Reducer, Store, Dispatch } from '@reduxjs/toolkit'
// import { analyticsMiddleware } from '../utils/analytics'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'errorFetching',
    'fetching',
    'status',
    'accessTokenState',
    'user'
  ]
}

const persistedReducer: Reducer<StateType, Action> = persistReducer(persistConfig, Reducers)

export const store: Store<StateType, Action, Dispatch<Action>> = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat(thunk),
  devTools: process.env.NODE_ENV !== 'production'
})
export const persistor = persistStore(store)
