// @flow

import React, { useEffect, useState } from 'react'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import NotificationBanner from 'michelangelo/dist/Components/NotificationBanner'
import { messages } from '../i18n/messages'

const INTERVAL = 30000 // 30 sec
const DOWNLOAD_LIMIT = 128 // 128 Kbps is the limit to determine if the connection is slow
const IMAGE_URL = 'https://heartbeat1.indiciummobile.com/speedtest.png'
const IMAGE_SIZE = 54302 // bytes

export const SlowConnection = (props: InjectIntlProvidedProps) => {
  const { intl: { formatMessage } } = props
  const [isSlowConnection, setIsSlowConnection] = useState(false)

  const downloadImage = () => {
    const startTime = new Date().getTime()
    const imageSrc = IMAGE_URL + '?n=' + startTime // added time to prevent browser cache
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      const endTime = new Date().getTime()
      const duration = Math.round((endTime - startTime) / 1000)
      const bitsLoaded = IMAGE_SIZE * 8
      const speedBps = Math.round(bitsLoaded / duration)
      const speedKbps = parseFloat((speedBps / 1024).toFixed(2))
      setIsSlowConnection(speedKbps < DOWNLOAD_LIMIT)
    }
  }

  useEffect(() => {
    if (!process.env.CI) {
      const ping = setInterval(downloadImage, INTERVAL) // run every 5 sec
      return () => clearInterval(ping)
    }
  }, [])
  return (
    isSlowConnection ? <NotificationBanner type='warning' bannerText={formatMessage(messages.slowConnection)} /> : null
  )
}

export default injectIntl(SlowConnection)
export { SlowConnection as UnwrappedSlowConnection }
