/* eslint-disable react/jsx-handler-names */
// @flow

import React, { Component } from 'react'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { map, every, includes } from 'lodash'
import type { StyledComponent } from 'styled-components'
import PasswordHelperCard from './PasswordHelperCard'
import { injectIntl, defineMessages } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { ValidatePasswordInput } from '@indicium/leonardo/src/connectors/riffraff'
import { messages } from '../i18n/messages/index'

/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import { blackList } from '../utils/config'
import * as Actions from '../AlfredApp/actions'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import WebIcon from 'michelangelo/dist/Components/Icon'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'

// import Text from 'michelangelo/dist/NativeText'

/* istanbul ignore next */
const PasswordContainer: StyledComponent<{}> = styled.div`
  background-color: ${color.white};
  padding: 8px;
`

const InfoIconContainer: StyledComponent<{}> = styled.div`
  float: right;
  padding-right: 8px;
`

const LoadingContainer: StyledComponent<{}> = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
`

const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 15px;
  padding: 8px;
`

const InfoRow: StyledComponent<{}> = styled.div`
  margin: 0;
  display: block;
`

const InfoContainer: StyledComponent<{}> = styled.div`
  background-color: ${color.info}20;
  border: 1px solid ${color.info};
  border-radius: 6px;
  color: ${color.info};
  padding: 6px 12px;
  margin: 4px 0;
`

const requirementPasswordMessages = defineMessages({
  eightChars: {
    id: 'requirementPassword.eightChars',
    defaultMessage: '8 characters'
  },
  upperCase: {
    id: 'requirementPassword.upperCase',
    defaultMessage: '1 upper case letter'
  },
  lowerCase: {
    id: 'requirementPassword.lowerCase',
    defaultMessage: '1 lower case letter'
  },
  number: {
    id: 'requirementPassword.number',
    defaultMessage: '1 number'
  },
  specialChar: {
    id: 'requirementPassword.specialChar',
    defaultMessage: '1 special character'
  },
  commonPassword: {
    id: 'requirementPassword.commonPassword',
    defaultMessage: 'Not a common password'
  },
  firstName: {
    id: 'requirementPassword.firstName',
    defaultMessage: 'Does not include your first name'
  },
  lastName: {
    id: 'requirementPassword.lastName',
    defaultMessage: 'Does not include your last name'
  },
  email: {
    id: 'requirementPassword.email',
    defaultMessage: 'Does not include your email address'
  },
  accountId: {
    id: 'requirementPassword.accountId',
    defaultMessage: 'Does not include your account id'
  }
})

// For testing purpose added this
PasswordContainer.displayName = 'PasswordContainer'
PasswordHelperCard.displayName = 'PasswordHelperCard'
InfoIconContainer.displayName = 'InfoIconContainer'
type ValidObject = {
  valid: boolean,
  message: string,
  info?: string
}

type RequirementStepsProps = {
  password: string,
  repeatPassword: string,
  onUpdate: (boolean) => void,
  isCheckingPassword: boolean,
  errors: Array<String>,
} & InjectIntlProvidedProps

type RequirementStepsState = {
  metRequirements: Array<ValidObject>,
  validPassword: boolean,
  errors: Array<String>
}

const InstructParagraph: StyledComponent<{}> = styled.h3`
  background-color: ${color.white};
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  align-items: left;
  text-align: left;
  color: ${(props) => props.color ? props.color : color.grey600};
  padding-left: 8px;
`
const LoadingText: StyledComponent<{}> = styled.div`
  padding-left: 8px;
`

class RequirementSteps extends Component<RequirementStepsProps, RequirementStepsState> {
  constructor (props: RequirementStepsProps) {
    super(props)
    const { intl: { formatMessage } } = props
    const defaultRequirements = [
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.eightChars)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.upperCase)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.lowerCase)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.number)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.specialChar),
        info: '~`!@#%^&\\*+=-/\',{}|":;<>[]?'
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.commonPassword)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.firstName)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.lastName)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.email)
      },
      {
        valid: false,
        message: formatMessage(requirementPasswordMessages.accountId)
      }
    ]
    this.state = {
      metRequirements: defaultRequirements,
      validPassword: false,
      isFocus: false,
      errors: []
    }
  }

  static mapDispatchToProps (dispatch: Dispatch<any>) {
    return {
      validatePassword: (data: ValidatePasswordInput) => dispatch(Actions.validatePassword(data)),
      checkChangePassword: (email: string, password: string, repeatPassword: string, subdomain: string) => dispatch(Actions.checkChangePassword(email, password, repeatPassword, subdomain))
    }
  }

  async componentDidUpdate (prevProps: RequirementStepsProps) {
    if (this.props.errors.length > 0 && this.props.password !== '' && this.props.password === this.props.repeatPassword && this.props.isCheckingPassword) {
      const metRequirements = this.state.metRequirements.map((requirement, index) => {
        const requirementNew = { ...requirement }
        requirementNew.valid = true
        if (includes(this.props.errors, requirementNew.message)) { requirementNew.valid = false }
        return requirementNew
      })
      const _valid = every(metRequirements, 'valid')
      this.setState({
        metRequirements,
        validPassword: _valid,
        errors: this.props.errors || []
      })
      this.props.onUpdate(_valid)
    }
  }

  blacklistCheck = (password: string) => {
    if (!password) return false
    const list = blackList.split(',')
    for (const element of list) {
      if (password.indexOf(element) > -1) {
        return false
      }
    }
    return true
  }

  getLoadingIndicator () {
    const { intl: { formatMessage } } = this.props
    if (this.props.isCheckingPassword) {
      return (
        <LoadingContainer>
          <WebIcon color={color.grey600} iconName='spinner' pulse />
          <LoadingText>
            <Text text={formatMessage(messages.verify)} textSize='h5' fontColor={color.grey800} />
          </LoadingText>
        </LoadingContainer>
      )
    }
  }

  getValidator () {
    return (
      <PasswordContainer validPassword={this.state.validPassword}>
        <Row classNames={['is-marginless', 'is-mobile']}>
          <Column classNames={['is-paddingless']}>
            <HeaderContainer>
              <Text align='left' color={color.grey900} text={this.props.intl.formatMessage(messages.newPasswordInstructions)} textSize='h5' />
            </HeaderContainer>
            {
              map(this.state.metRequirements, (metRequirement: ValidObject, index: string) => {
                let icon = 'solidCircle'
                let iconColor = color.grey600
                let textColor = color.grey600
                let valid = false
                if (this.props.password && metRequirement.valid === true && (this.props.password === this.props.repeatPassword) && (this.props.password !== '')) {
                  iconColor = color.success
                  icon = 'check'
                  valid = true
                  textColor = color.success
                } else if (this.props.password && includes(this.props.errors, metRequirement.message)) {
                  iconColor = color.danger
                  icon = 'x'
                  valid = false
                  textColor = color.danger
                }

                let info = null
                if (metRequirement.info) {
                  info = (
                    <InfoRow>
                      <InfoContainer>{metRequirement.info}</InfoContainer>
                    </InfoRow>
                  )
                }

                return (
                  <div key={index}>
                    <Row key={index} classNames={['is-marginless', 'is-mobile', 'is-vcentered']}>
                      <Icon iconName={icon} size={8} color={iconColor} />
                      <InstructParagraph valid={valid} color={textColor}>{metRequirement.message}</InstructParagraph>
                    </Row>
                    {info}
                  </div>
                )
              })
            }
            {this.getLoadingIndicator()}
          </Column>
        </Row>
      </PasswordContainer>
    )
  }

  render () {
    const getValidator = this.getValidator()
    return (
      <Column classNames={['is-paddingless', 'is-12']}>
        {getValidator}
      </Column>
    )
  }
}
export { RequirementSteps as RequirementStepTest }
export default connect(RequirementSteps.mapDispatchToProps)(injectIntl(RequirementSteps))
