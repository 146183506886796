// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Icon } from './Icons'
/** michelangelo components */
import Text from 'michelangelo/dist/Components/NativeText'
import color from 'michelangelo/dist/Components/styles/color'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'

import type { StyledComponent } from 'styled-components'

const ParentContainer: StyledComponent<{}> = styled.div`
  background-color: ${color.white};
  width: 550px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 360px) {
    width: 300px;
  }
`
const Container: StyledComponent<{}> = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  background: rgba(51,51,51,0.65);
  left: 0;
  right: 0;
`

const TopBox: StyledComponent<{}> = styled.div`
  border-bottom: 0.5px solid ${color.lightestGrey};
  width: 550px;
  height: auto;
  padding: 20px 10px;
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 360px) {
    width: 300px;
  }
`

const BottomBox: StyledComponent<{}> = styled.div`
  background-color: ${color.white};
  width: 550px;
  height: auto;
  padding: 20px 10px;
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 360px) {
    width: 300px;
  }
`

const TextBox: StyledComponent<{}> = styled.div`
  color: ${color.lightTextDefault};
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.9px;
  line-height: 25px;
  text-align: left;
  padding: 0 0 32px 0px;
`

// add for testing purpose
ParentContainer.displayName = 'ParentContainer'
Container.displayName = 'Container'
TopBox.displayName = 'TopBox'
BottomBox.displayName = 'BottomBox'
TextBox.displayName = 'TextBox'

type ModalProps = {
  modalTheme: string,
  modalFontColor: string,
  modalText: string,
  icon: string,
  headerText: string,
  buttonAction?: () => void,
  link?: Object,
  buttonText: string
}

type ModalState = {
  active: boolean
}

class Modal extends React.Component<ModalProps, ModalState> {
  constructor (props: ModalProps) {
    super(props)
    this.state = {
      active: true
    }
  }

  render () {
    const modalText = this.props.modalText
    const link = this.props.link ? this.props.link : null
    const string = `${modalText} `
    let body = <TextBox>{modalText}</TextBox>
    /* istanbul ignore else */
    if (link) {
      body = <TextBox>{string}{link}</TextBox>
    }

    return (
      <Container>
        <div className={'modalContainer ' + (this.state.active ? 'active' : '')}>
          <ParentContainer className='center'>
            <TopBox>
              <Column>
                <Icon iconName={this.props.icon} size='2x' fillColor={this.props.modalTheme} />
              </Column>
              <Column>
                <Text align='center' textSize='h3' fontColor={this.props.modalFontColor} text={this.props.headerText} />
              </Column>
            </TopBox>
            <BottomBox>
              <Column>
                {body}
              </Column>
              <Row classNames={['is-marginless', 'is-centered']}>
                <Column classNames={['is-paddingless', 'is-half']}>
                  {/* eslint-disable-next-line react/jsx-handler-names */}
                  <Button outline onClick={this.props.buttonAction} themeColor={color.success} disabled={false} type='submit' ariaLabel={this.props.buttonText} title={this.props.buttonText} />
                </Column>
              </Row>
            </BottomBox>
          </ParentContainer>
        </div>
      </Container>
    )
  }
}

export default Modal
