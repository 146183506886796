/* eslint-disable react/jsx-handler-names */
// @flow

// import React from 'react'
import React, { useRef, useState, useEffect } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Animation, NewButton, Icons, Logo } from './Components/index'
import { messages } from './i18n/messages/index'
import type { StyledComponent } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { isEmpty, isEqual } from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactGA from 'react-ga'
import { defaultRecaptchaId, invisibleRecaptchaId, developerSubdomain, riffraffHost, riffraffProtocol, riffraffPort, env, googleAnalyticsValue } from './utils/config'
import validateDomain from './utils/whiteListHelper'
import qs from 'qs'
import * as Actions from './AlfredApp/actions'

/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Input from 'michelangelo/dist/SharedComponents/Inputs/Input'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import InfoModal from 'michelangelo/dist/SharedComponents/InfoModal'
import WebIcon from 'michelangelo/dist/Components/Icon'

import validator from 'validator'
import isMobile from './utils/isMobile'
import ErrorPage from './ErrorPage'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { GET_ACCOUNT_DETAILS } from './AlfredApp/actions'
import employeeAppLogo from './Images/employeeApp-logo.png'
import { footerMessages } from './Components/Footer'
import axios from 'axios'

// Riffraff
import { ConsolePlainStream } from '@browser-bunyan/console-plain-stream'
import { createLogger, INFO, WARN, ERROR } from 'browser-bunyan'
import { Riffraff } from '@indicium/leonardo'
const url = `${riffraffProtocol}://${riffraffHost}:${riffraffPort}`
const logger = createLogger({
  name: `alfred ${env}`,
  streams: [{
    level: INFO,
    stream: new ConsolePlainStream()
  }, {
    level: WARN,
    stream: new ConsolePlainStream()
  },
  {
    level: ERROR,
    stream: new ConsolePlainStream()
  }]
})
const riffraff = new Riffraff(url, logger)

// export for tests
export const loginMessages = defineMessages({
  forgotPassword: {
    id: 'login.forgotPassword',
    defaultMessage: 'Forgot your password?'
  },
  hello: {
    id: 'login.hello',
    defaultMessage: 'Hello, {firstName}!'
  },
  passwordNotMatch: {
    id: 'login.passwordNotMatch',
    defaultMessage: 'Password does not match with this account'
  },
  welcomeBack: {
    id: 'login.welcomeBack',
    defaultMessage: 'Welcome back {firstName}!'
  },
  success: {
    id: 'login.success',
    defaultMessage: 'Login Success! Redirecting...'
  },
  newUser: {
    id: 'login.newUser',
    defaultMessage: 'New User?'
  },
  register: {
    id: 'login.register',
    defaultMessage: 'Register Here'
  },
  openReg: {
    id: 'login.openReg',
    defaultMessage: 'Open Registration'
  },
  requestNewMfaCode: {
    id: 'login.requestNewMfa',
    defaultMessage: 'Request new code'
  }
})
const ErrorMsg: StyledComponent<{}> = styled.div`
  background-color: ${color.danger}20;
  border: 1px solid ${color.danger};
  border-radius: 6px;
  color: ${color.danger};
  padding: 8px;
  margin-top: 8px;
`

const WelcomeHeading: StyledComponent<{}> = styled.h1`
  color: ${(props) => props.color};
  font-size: 2em;
  display: flex;
  justify-content: center;
`

const ButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: ${(props) => props.isReCaptchaShowing ? '10px' : '10px'};
  // padding-top: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const SSOButtonContainer: StyledComponent<{}> = styled.div`
  margin-top: ${(props) => props.isReCaptchaShowing ? '10%' : '0px'};
  display: flex;
  padding-bottom: 16px;
  width: 100%;
  justify-content: flex-end;
`
const HeaderContainer: StyledComponent<{}> = styled.div`
  margin-top: 8px;
  padding-bottom: 16px;
  display: flex;
`
const InputContainer: StyledComponent<{}> = styled.div`
  padding: 24px 0px 8px 0px;
  input {
    cursor: pointer !important;
  }
`
const ModalBackground: StyledComponent<{}> = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: ${(props) => props.show ? 'block' : 'none'};
`
const ModalContainer: StyledComponent<{}> = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => props.show ? 'block' : 'none'};
`
const ForgotMsg: StyledComponent<{}> = styled.div`
  cursor: pointer;
`
const LinkContainer: StyledComponent<{}> = styled.div`
  cursor: pointer;
`
const AnimationContainer: StyledComponent<{}> = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  display: flex;
  padding-top: 200px;
  margin-top: 3%;
  left: 0;
  right: 0;
  @media (max-width: 750px) {
    margin-top: 5%;
  }
  @media (max-width: 420px) {
    margin-top: 25%;
  }
  @media (max-width: 350px) {
    margin-top: 30%;
  }
`
const TextContainer: StyledComponent<{}> = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  display: flex;
  padding-top: 200px;
  margin-top: 15%;
  left: 0;
  right: 0;
  @media (max-width: 1080px) {
    margin-top: 20%;
  }
  @media (max-width: 750px) {
    margin-top: 35%;
  }
  @media (max-width: 420px) {
    margin-top: 50%;
  }
  @media (max-width: 350px) {
    margin-top: 60%;
  }
`
const AnimationHeaderContainer: StyledComponent<{}> = styled.div`
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 5s; /* Firefox < 16 */
        -ms-animation: fadein 5s; /* Internet Explorer */
         -o-animation: fadein 5s; /* Opera < 12.1 */
            animation: fadein 5s;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
`
const ButtonSpacing: StyledComponent<{}> = styled.div`
  //margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer
`

const TextIcon: StyledComponent<{}> = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`

const ReCaptchaContainer: StyledComponent<{}> = styled.div`
  transform:scale(0.7);
  -webkit-transform:scale(0.7);
  transform-origin: center;
  -webkit-transform-origin:center;
  display: flex;
  justify-content: center;
`

const OrSeparator: StyledComponent<{}> = styled.div`
  display: flex;
  color: ${(props) => props.color ? props.color : '#000'};
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px 0 5px;
  &:before, &:after {
    content: '';
    flex: 1;
    max-width: 18px;
    border-bottom: 1px solid ${(props) => props.color ? props.color : '#000'};
  }
  &:before {
    margin-right: 8px;
  }
  &:after {
    margin-left: 8px;
  }
`

const IconContainer: StyledComponent<{}> = styled.div`
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
`

const GenericHeaderContainer: StyledComponent<{}> = styled.div`
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`

const Spacer: StyledComponent<{}> = styled.div`
  padding-bottom: 95px;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`

const EmployeeAppLogo: StyledComponent<{}> = styled.img`
  max-height: 38px;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: 46px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-top: 86px;
  }
`

const ErrorContainer: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  background: rgba(240, 123, 123, 0.2);
  border: 1px solid #F07B7B;
  border-radius: 6px;
  word-wrap: break-word;
  padding: 10px 12px;
`

const LoadingContainer: StyledComponent<{}> = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
`

ForgotMsg.displayName = 'ForgotMsg'
IconContainer.displayName = 'IconContainer'

const IconColor = '#758192'

if (env === 'production' && googleAnalyticsValue) {
  ReactGA.initialize(googleAnalyticsValue, { debug: false })
}

type LoginProps = {
  onChangeLang: Function
} & InjectIntlProvidedProps

function Login ({ intl: { formatMessage }, onChangeLang }: LoginProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const recaptchaRef = useRef()
  const nodeRef = useRef()
  const [searchParams] = useSearchParams()

  const [corporateId, setCorporateId] = useState((searchParams.has('subdomain') && searchParams.get('subdomain') !== 'generic') ? searchParams.get('subdomain') : '')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [mfaCode, setMfaCode] = useState('')
  const [recaptchaValue, setRecaptchaValue] = useState('')
  const [isValidDomain, setIsValidDomain] = useState(false)
  const [type, setType] = useState('password')
  const [error] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [customInfoData, setCustomInfoData] = useState()
  const [hasEmailInfo, setHasEmailInfo] = useState()
  const [hasPasswordInfo, setHasPasswordInfo] = useState()
  const [loginType, setLoginType] = useState()
  const [isCorporateIdInputValid, setIsCorporateIdInputValid] = useState(true)

  const [showGenericModal, setShowGenericModal] = useState(false)
  const [genericLoading, setGenericLoading] = useState(false)
  const [genericModalData, setGenericModalMessage] = useState('')

  let subdomain = searchParams.get('subdomain')
  let callbackUrl = useSelector(state => state.account.callbackUrl)
  let appVersion = useSelector(state => state.account.appVersion)
  let isGenericFlow = useSelector(state => state.account.isGenericFlow)
  let language = useSelector(state => state.account.language)
  const isAccountFetching = useSelector(state => state.fetching.isAccountFetching)
  const accessToken = useSelector(state => state.accessTokenState.accessToken)
  const userUpdated = useSelector(state => state.user.userUpdated)
  const mfaRequired = useSelector(state => state.user.mfaRequired)
  const fetchingError = useSelector(state => state.errorFetching.isErrorFetchingUser)
  const accountDetails = useSelector(state => state.account.account)
  const showRegistrationLink = useSelector(state => state.account.showRegistrationLink)

  useEffect(() => {
    if (env === 'production' && googleAnalyticsValue) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (corporateId === '' || corporateId === 'generic') {
      setCorporateId('')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!subdomain) {
      const res = { account: null }
      dispatch({ type: GET_ACCOUNT_DETAILS, res })
      return
    }
    const getAccount = async () => {
      if (corporateId === '' || corporateId === 'generic') {
        const res = { account: null }
        dispatch({ type: GET_ACCOUNT_DETAILS, res })
      } else {
        const result = await dispatch(Actions.getAccountDetails(corporateId, false))
        if (result && result.type !== GET_ACCOUNT_DETAILS) {
          setIsCorporateIdInputValid(false)
        }
      }
    }
    getAccount().catch(() => {
      navigate('/')
    })
    // eslint-disable-next-line
  }, [subdomain])

  useEffect(() => {
    const authTypes = accountDetails ? accountDetails.authTypes : {}
    if (!isAccountFetching) {
      let loginType
      if (authTypes.openRegistrationEnabled && authTypes.registrationCodeEnabled) { // if both openRegistrationEnabled and registrationCodeEnabled are enabled, use registrationCodeEnabled (csv) as default
        loginType = 'regCode'
      } else if (authTypes.openRegistrationEnabled) {
        loginType = 'openReg'
      } else {
        loginType = 'regCode'
      }
      setLoginType(loginType)
      const { hasEmailInfo, hasPasswordInfo } = getHasEmailAndPassInfo(loginType)
      setHasEmailInfo(hasEmailInfo)
      setHasPasswordInfo(hasPasswordInfo)
    }
    // eslint-disable-next-line
  }, [isAccountFetching])

  useEffect(() => {
    dispatch(Actions.setAccessToken(''))
    dispatch(Actions.setUserUpdated(false))
    getURLQueryParams()
    // eslint-disable-next-line
  }, [])

  const handleGenericInfoClick = async () => {
    setShowGenericModal(true)
    setGenericLoading(true)
    const { message: { genericLoginOrgIDHelpText } } = await riffraff.getMessage()
    setGenericModalMessage(genericLoginOrgIDHelpText.en)
    setGenericLoading(false)
  }

  const onGenericModalClose = () => {
    closeGenericModal()
  }

  const closeGenericModal = () => {
    setShowGenericModal(false)
  }

  const loadingSection = (
    <LoadingContainer>
      <WebIcon color={color.grey600} iconName='spinner' pulse />
    </LoadingContainer>
  )

  const getHasEmailAndPassInfo = (loginType: string) => {
    let hasEmailInfo = false
    let hasPasswordInfo = false
    const customInfo = accountDetails ? accountDetails.customInfo : {}
    if (customInfo) {
      // check if has email info for selected loginType
      if (customInfo && customInfo[loginType] && customInfo[loginType].email && customInfo[loginType].email.hasCustomInfo) {
        hasEmailInfo = true
      }
      // check if has password info for selected loginType
      if (customInfo && customInfo[loginType] && customInfo[loginType].password && customInfo[loginType].password.hasCustomInfo) {
        hasPasswordInfo = true
      }
    }
    return { hasEmailInfo, hasPasswordInfo }
  }

  const handlePasswordChange = (e: any) => {
    if (e && e.preventDefault) e.preventDefault()
    const password = e.target ? e.target.value : e
    setPassword(password)
  }

  const handleReCaptchaAction = async (value: string) => {
    const recaptchaValue = value || ''
    setRecaptchaValue(recaptchaValue)
  }

  const isInputValid = () => {
    if (accountDetails) {
      const isReCaptchaValid = !defaultRecaptchaId || accountDetails.captchaOption === 'INVISIBLE' || accountDetails.captchaOption === 'DISABLED' ? true : !isEmpty(recaptchaValue)
      return !isEmpty(email) && !isEmpty(password) && isReCaptchaValid
    }
  }

  const handleChangeEmail = (e: any) => {
    const email = e.target ? e.target.value : e
    setEmail(email)
  }

  const handleChangeMfaCode = (e: any) => {
    const mfaCode = e.target ? e.target.value : e
    setMfaCode(mfaCode)
  }

  const showReCaptcha = () => {
    const captchaOption = accountDetails ? accountDetails.captchaOption : ''
    return !defaultRecaptchaId || captchaOption === 'DISABLED' ? false : (!isEmpty(password) && !isEmpty(email)) || captchaOption === 'INVISIBLE'
  }

  const getReCaptcha = () => {
    const captchaOption = accountDetails ? accountDetails.captchaOption : ''

    if (!showReCaptcha()) return null
    let sitekey = defaultRecaptchaId
    let badge = ''
    let captchaSize = 'normal'

    if (captchaOption === 'INVISIBLE') {
      captchaSize = 'invisible'
      badge = 'inline'
      sitekey = invisibleRecaptchaId
    }
    return (
      <ReCaptchaContainer>
        <ReCAPTCHA
          badge={badge}
          ref={recaptchaRef}
          sitekey={sitekey}
          onChange={handleReCaptchaAction}
          size={captchaSize}
        />
      </ReCaptchaContainer>
    )
  }

  const forgotPassword = async (e: any) => {
    e.preventDefault()
    navigate('/forgot-password-email-entry')
  }

  const handleCorporateIdChange = (inputValue: string) => {
    if (inputValue === '' || inputValue === 'generic') {
      setCorporateId('')
    } else {
      setCorporateId(inputValue)
    }
  }

  const handleContinueClick = async (e: any) => {
    e.preventDefault()

    const url = new URL(window.location.href)
    url.searchParams.set('subdomain', corporateId.toLowerCase())
    if (!url.searchParams.has('platform')) {
      url.searchParams.set('platform', 'web')
    }
    if (!url.searchParams.has('redirect')) {
      url.searchParams.set('redirect', 'true')
    }

    // for development call riffraff
    if (env.toLowerCase() === 'dev') {
      const result = await dispatch(Actions.getAccountDetails(corporateId, false))
      if (result && result.type !== GET_ACCOUNT_DETAILS) {
        setIsCorporateIdInputValid(false)
      }
    } else {
      // call the lambda function
      await axios.get(url.toString())
        .then((res) => {
          setIsCorporateIdInputValid(true)
          // follow the lambda redirect link
          window.location.href = res.request.responseURL
        })
        .catch(() => {
          setIsCorporateIdInputValid(false)
        })
    }
  }

  const getCorporateIdErrorMessage = () => {
    let errorMessage = ''

    if (isCorporateIdInputValid === false) {
      errorMessage = formatMessage(messages.organizationNotFound)
    }

    const errorDiv = (
      <ErrorContainer data-cy='errormessagecy'>
        <Column classNames={['is-paddingless']}>
          <Text textSize='h5' align='left' fontColor={color.danger} text={errorMessage} />
        </Column>
      </ErrorContainer>
    )
    return errorMessage ? errorDiv : null
  }

  const getCorporateInputTemplate = () => {
    const { inputTheme } = getTheme()
    if (searchParams.has('no-token')) {
      return null
    }
    return (
      <Row classNames={['is-marginless', 'is-centered']}>
        <Column classNames={['is-paddingless', 'is-12']}>
          <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
            <GenericHeaderContainer data-cy='headercy'>
              <Row classNames={['is-marginless']}>
                <Column classNames={['center']}>
                  <EmployeeAppLogo data-cy='logofootercy' src={employeeAppLogo} alt={formatMessage(footerMessages.appriseLogoAlt)} />
                </Column>
              </Row>
              {isMobile()
                ? <Text align='center' textSize='h3' fontColor={color.lightTextDefault} text={formatMessage(messages.welcome)} />
                : <WelcomeHeading>{ formatMessage(messages.welcome) }</WelcomeHeading>
              }
              <Spacer />
              <Text align='center' textSize='p' fontColor={color.lightTextDefault} text={formatMessage(messages.genericStart)} />
            </GenericHeaderContainer>
            <form onSubmit={handleContinueClick}>
              <InputContainer>
                <Input
                  inputTheme={isCorporateIdInputValid ? inputTheme : color.danger}
                  value={corporateId}
                  label={formatMessage(messages.organizationID)}
                  type='text'
                  onChange={handleCorporateIdChange}
                  iconName='info'
                  onClickIcon={handleGenericInfoClick}
                  autoCapitalize='none'
                  maxLength={254}
                />
              </InputContainer>
              {getCorporateIdErrorMessage()}
            </form>
            <ButtonContainer>
              <Button fullWidth fill themeColor={isEmpty(corporateId) ? color.disabled : color.info} title={formatMessage(messages.continueButton)} onClick={handleContinueClick} disabled={isEmpty(corporateId)} />
            </ButtonContainer>

            <ModalBackground show={showGenericModal} onClick={onGenericModalClose}>
              <ModalContainer show={showGenericModal}>
                {!genericLoading
                  ? <InfoModal
                    body={genericModalData}
                    cancelAction={closeGenericModal}
                    show={showGenericModal}
                    cancelText={formatMessage(messages.close)}
                  />
                  : loadingSection }
              </ModalContainer>
            </ModalBackground>
          </Column>
        </Column>
      </Row>
    )
  }

  const handleOnSubmit = async (e: any) => {
    e.preventDefault()
    // If the captcha is invisible then execute the captcha -> will trigger a call to handleReCaptchaAction if it wasnt executed before
    if (!mfaRequired && recaptchaRef.current && accountDetails.captchaOption === 'INVISIBLE') {
      if (fetchingError) {
        recaptchaRef.current.reset()
      }
      await recaptchaRef.current.executeAsync()
    }
    if (!isEmpty(accountDetails) && isInputValid()) {
      let emailHolder
      if (!validator.isEmail(email)) {
        emailHolder = email
      } else {
        emailHolder = email.toLowerCase()
      }
      const response = await dispatch(Actions.loginUser(password, emailHolder, mfaRequired && mfaCode !== '' ? mfaCode : undefined))
      const status = response && response.status ? response.status : false
      if (status) {
        navigate('/user-data')
      }
    }
  }

  const getURLQueryParams = () => {
    const query = location.search
    const queryParams = qs.parse(query.replace(/^.*\?/, ''))
    // add the #/access to the callback url if present
    if (location.hash) queryParams.callbackUrl = queryParams.callbackUrl + location.hash
    if (queryParams.subdomain) {
      subdomain = queryParams.subdomain
    }

    if (!subdomain && developerSubdomain) {
      subdomain = developerSubdomain
    }
    let bypassSSO = false
    if (queryParams.nativeLogin && queryParams.nativeLogin === 'true') bypassSSO = true
    if (queryParams.callbackUrl) callbackUrl = queryParams.callbackUrl
    setIsValidDomain(validateDomain(callbackUrl, subdomain))
    isGenericFlow = true
    if (queryParams.appVersion) appVersion = queryParams.appVersion
    if (queryParams.language) language = queryParams.language
    if (language) onChangeLang(language)
    dispatch(Actions.setURLQueryParams(callbackUrl, appVersion, bypassSSO, subdomain, isGenericFlow, language))
  }

  const getTheme = () => {
    let inputTheme = color.lightTextDefault
    let buttonTheme = color.disabled
    let disabled = true

    const characterPresent = !isEmpty(password)

    if (characterPresent) {
      inputTheme = color.info
      buttonTheme = color.success
      disabled = false
    }
    return { inputTheme, buttonTheme, disabled }
  }

  const displayPassword = (e: any) => {
    e.preventDefault()
    const oldType = type
    const isTextOrHide = (oldType === 'password')
    const newType = (isTextOrHide) ? 'text' : 'password'
    setType(newType)
  }

  const getBodyAnimation = (accessToken: string) => {
    const animation = <Animation onAnimationFinish={onAnimationFinish} animationType='bubbles' />
    let successClause = true
    // skip the animation if the user has no valid data and redirect to user-data page
    // if needed for only ssoOrNativeRedirect add 'ssoOrNativeRedirect && ssoOrNativeRedirect.enable'
    if (!userUpdated) {
      successClause = false
    }
    if (!isEmpty(accessToken) && successClause) {
      return (
        <Row classNames={['is-marginless', 'is-centered']}>
          <AnimationContainer>
            <Column classNames={['is-paddingless', 'is-12']}>
              <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-8-mobile', 'is-offset-2-mobile']}>
                {animation}
              </Column>
            </Column>
          </AnimationContainer>
          <TextContainer>
            <Column classNames={['is-paddingless', 'is-12']}>
              <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-8-mobile', 'is-offset-2-mobile']}>
                <Row classNames={['is-marginless', 'is-centered']}>
                  <Column classNames={['is-paddingless', 'is-12']}>
                    <AnimationHeaderContainer data-cy='successfulLogincy'>
                      <Text align='center' textSize='h4' text={formatMessage(loginMessages.success)} fontColor={color.lightTextDefault} />
                    </AnimationHeaderContainer>
                  </Column>
                </Row>
              </Column>
            </Column>
          </TextContainer>
        </Row>
      )
    }
  }

  const getErrorMessage = () => {
    let errorMessage = ''
    if (fetchingError) {
      errorMessage = formatMessage(messages.errorMessage)
    }
    const errorDiv = (
      <ErrorMsg>
        <Text textSize='p' align='left' fontColor={color.danger} text={errorMessage} />
      </ErrorMsg>
    )
    return errorMessage ? errorDiv : null
  }

  const getMfaErrorMessage = () => {
    let errorMessage = ''
    if (fetchingError) {
      errorMessage = formatMessage(messages.mfaCodeIncorrect)
    }
    const errorDiv = (
      <ErrorMsg>
        <Text textSize='p' align='left' fontColor={color.danger} text={errorMessage} />
      </ErrorMsg>
    )
    return errorMessage ? errorDiv : null
  }

  const requestNewMfaCode = async (e: any) => {
    e.preventDefault()
    const response = await dispatch(Actions.loginUser(password, email))
    if (response.status) {
      navigate('/user-data')
    }
  }

  const closeMfaValidation = () => {
    setEmail('')
    setPassword('')
    setMfaCode('')
    dispatch(Actions.setMFARequired(false))
  }

  const handleSSOClick = async () => {
    await dispatch(Actions.routeToSSOProvider(accountDetails))
    navigate('/loadingSSO')
  }

  const handleRegistrationClick = (e: any) => {
    e.preventDefault()
    navigate(`/registration?${searchParams.toString()}`)
  }

  const handleEmailInfoClick = () => {
    const customInfo = accountDetails && accountDetails.customInfo ? accountDetails.customInfo : null
    if (customInfo && customInfo[loginType] && customInfo[loginType].email && customInfo[loginType].email.customInfoData) {
      const customInfoData = customInfo[loginType].email.customInfoData
      setShowModal(true)
      setCustomInfoData(customInfoData)
    }
  }

  const handlePasswordInfoClick = () => {
    const customInfo = accountDetails && accountDetails.customInfo ? accountDetails.customInfo : null
    if (customInfo && customInfo[loginType] && customInfo[loginType].password && customInfo[loginType].password.customInfoData) {
      const customInfoData = customInfo[loginType].password.customInfoData
      setShowModal(true)
      setCustomInfoData(customInfoData)
    }
  }

  const onModalClose = (e: any) => {
    if (e) e.stopPropagation()
    if ((e && nodeRef.current && nodeRef.current.contains(e.target)) || isMobile()) return
    closeModal()
  }

  const closeModal = () => {
    setShowModal(false)
    setCustomInfoData('')
  }

  const getBodyButtonAndInput = (accessToken: string) => {
    const { buttonTheme, inputTheme } = getTheme()
    const errorMessage = getErrorMessage()
    const reCaptcha = getReCaptcha()

    if (isEmpty(accessToken) && accountDetails && isValidDomain) {
      const authTypes = accountDetails ? accountDetails.authTypes : {}
      const customTexts = accountDetails ? accountDetails.customTexts : null
      const inputPlaceholder = (customTexts && customTexts.loginRegCodePlaceholder) ? customTexts.loginRegCodePlaceholder : formatMessage(messages.userPlaceholder)
      let ssoEnabled
      let orRender

      if (authTypes.ssoEnabled || authTypes.openRegistrationEnabled) {
        orRender =
          <Row classNames={['is-marginless', 'is-mobile']}>
            <Column classNames={['is-paddingless', 'is-full', 'is-centered']}>
              <OrSeparator color={color.grey400}>
                <Text testID='or_separator' align='center' textSize='p' fontColor={color.grey400} text={formatMessage(messages.or)} />
              </OrSeparator>
            </Column>
          </Row>
      }
      if (authTypes.ssoEnabled && !isEqual(accountDetails.SSOType, 'NONE')) {
        let ssoButtonText = formatMessage(messages.sso)
        if (customTexts && customTexts.btnSingleSignOn) ssoButtonText = customTexts.btnSingleSignOn
        ssoEnabled =
          <Row classNames={['is-marginless', 'is-mobile']}>
            <SSOButtonContainer>
              <Column classNames={['is-paddingless', 'is-full']}>
                <Button fullWidth outline themeColor={color.info} type='button' onClick={handleSSOClick} title={ssoButtonText} />
              </Column>
            </SSOButtonContainer>
          </Row>
      }

      return (
        <div>
          <form onSubmit={handleOnSubmit}>
            <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
              <HeaderContainer data-cy='headercy'>
                <Text align='center' textSize='h3' fontColor={color.lightTextDefault} text={formatMessage(messages.getStarted)} />
              </HeaderContainer>
            </Row>
            {showRegistrationLink === true && (
              <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
                <ButtonSpacing onClick={handleRegistrationClick}>
                  <Text align='center' textSize='p' fontColor={color.grey800} text={formatMessage(loginMessages.newUser)} />
                  <TextIcon classNames={['is-paddingless']}>
                    <Text align='center' textSize='p' fontColor={color.info} cursor text={formatMessage(loginMessages.register)} />
                  </TextIcon>
                </ButtonSpacing>
              </Row>
            )}
            <InputContainer>
              <Input
                inputTheme={inputTheme}
                value={email}
                label={inputPlaceholder}
                type='email'
                onChange={handleChangeEmail}
                iconName={hasEmailInfo ? 'info' : null}
                onClickIcon={handleEmailInfoClick}
                hasIcon={hasEmailInfo}
                iconFillColor={IconColor}
              />
            </InputContainer>
            <InputContainer>
              <Input
                inputTheme={inputTheme}
                value={password}
                label={formatMessage(messages.password)}
                onChange={handlePasswordChange}
                onClickIcon={displayPassword}
                type={type === 'password' ? 'password' : 'text'}
                iconName={type === 'password' ? 'eyeClose' : 'eyeOpen'}
                hasIcon
                hasExtraIcon={hasPasswordInfo}
                extraIconName='info'
                onExtraIconClick={handlePasswordInfoClick}
                iconFillColor={IconColor}
              />
            </InputContainer>

            <ForgotMsg data-cy='forgotMsg' onClick={forgotPassword}>
              <Text className='forgot-password' align='left' textSize='p' fontColor={color.info} fontWeight='bold' text={formatMessage(loginMessages.forgotPassword)} />
            </ForgotMsg>
            {errorMessage}
            {reCaptcha}
            <Row classNames={['is-marginless', 'is-mobile']}>
              <ButtonContainer isReCaptchaShowing={reCaptcha}>
                <Column classNames={['is-paddingless', 'is-full']}>
                  <NewButton pill type='submit' fill disabled={!isInputValid()} themeColor={buttonTheme} title={formatMessage(messages.login)} />
                </Column>
              </ButtonContainer>
            </Row>
            {orRender}
            {ssoEnabled}
          </form>
        </div>
      )
    } else {
      return null
    }
  }

  const getMFAValidation = (accessToken: string) => {
    const { buttonTheme, inputTheme } = getTheme()
    const errorMessage = getMfaErrorMessage()

    if (isEmpty(accessToken) && accountDetails && isValidDomain) {
      return (
        <form onSubmit={handleOnSubmit}>
          <Row classNames={['is-marginless', 'is-mobile', 'is-centered']}>
            <HeaderContainer data-cy='headercy'>
              <Text align='center' textSize='h3' fontColor={color.lightTextDefault} text={formatMessage(messages.getVerified)} />
            </HeaderContainer>
          </Row>
          <IconContainer onClick={closeMfaValidation}>
            <Icons fillColor={color.default} size='2x' iconName='close' />
          </IconContainer>
          <InputContainer>
            <Input
              inputTheme={inputTheme}
              value={mfaCode}
              label={formatMessage(messages.oneTimeCode)}
              type='number'
              onChange={handleChangeMfaCode}
            />
          </InputContainer>
          <LinkContainer data-cy='newMfa' onClick={requestNewMfaCode}>
            <Text className='request-new-mfa' align='left' textSize='p' fontColor={color.info} fontWeight='bold' text={formatMessage(loginMessages.requestNewMfaCode)} />
          </LinkContainer>
          {errorMessage}
          <Row classNames={['is-marginless', 'is-mobile']}>
            <ButtonContainer>
              <Column classNames={['is-paddingless', 'is-full']}>
                <NewButton pill type='submit' fill themeColor={buttonTheme} title={formatMessage(messages.customerCareSubmit)} />
              </Column>
            </ButtonContainer>
          </Row>
        </form>
      )
    } else {
      return null
    }
  }

  const onAnimationFinish = () => {
    // FRANK!!!
    // push to privacy policy for acceptance check
    dispatch(Actions.goingToPrivacyPolicy(true))
    navigate('/privacy')
  }

  const bodyAnimation = getBodyAnimation(accessToken)
  let bodyButtonAndInput
  if (error) {
    return (
      <ErrorPage />
    )
  }
  if (!mfaRequired) bodyButtonAndInput = getBodyButtonAndInput(accessToken)
  else bodyButtonAndInput = getMFAValidation(accessToken)

  return accountDetails
    ? <Row classNames={['is-marginless', 'is-centered']}>
        <Column classNames={['is-paddingless', 'is-12']}>
          <Logo />
          <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
            {bodyAnimation}
            {bodyButtonAndInput}
          </Column>
        </Column>
        <ModalBackground show={showModal} onClick={onModalClose}>
          <ModalContainer show={showModal} ref={nodeRef}>
            <InfoModal
              body={customInfoData}
              cancelAction={closeModal}
              cancelText={formatMessage(messages.close)}
              show={showModal}
            />
          </ModalContainer>
        </ModalBackground>
      </Row>
    : getCorporateInputTemplate()
}

export default injectIntl(Login)
export { Login as UnconnectedLogin }
