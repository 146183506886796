// @flow

import React from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'
import { Offline } from 'react-detect-offline'
import { messages } from '../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import Text from 'michelangelo/dist/Components/NativeText'
import color from 'michelangelo/dist/Components/styles/color'

const ErrorMsgContainer: StyledComponent<{}> = styled.div`
  top: 0;
  justify-content: center;
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 5;
`
const ErrorMsg: StyledComponent<{}> = styled.div`
  color: ${color.danger};
  word-wrap: break-word;
  padding: 15px;
  width: 325px;
  height: auto;
  border: 2px solid ${color.danger};
  background-color: ${color.white};
`

// add for testing purpose
ErrorMsgContainer.displayName = 'ErrorMsgContainer'
ErrorMsg.displayName = 'ErrorMsg'

export const OfflineMessage = (props: InjectIntlProvidedProps) => {
  const { intl: { formatMessage } } = props
  return (
    <div>
      <Offline
        polling={{
          enabled: true,
          urls: ['https://heartbeat1.indiciummobile.com',
            'https://heartbeat2.indiciummobile.com',
            'https://heartbeat3.indiciummobile.com']
        }}
      >
        <ErrorMsgContainer>
          <ErrorMsg data-cy='errormessagecy'>
            <Text textSize='h4' align='center' fontColor={color.danger} text={formatMessage(messages.connectionMessage)} />
          </ErrorMsg>
        </ErrorMsgContainer>
      </Offline>
    </div>
  )
}

export default injectIntl(OfflineMessage)
export { OfflineMessage as UnwrappedOfflineMessage }
