// @flow
export const env: string = process.env.NODE_ENV || 'dev'
export const riffraffHost = process.env.REACT_APP_RIFFRAFF_HOST || 'riffraff.beta.indiciummobile.com'
export const riffraffProtocol = process.env.REACT_APP_RIFFRAFF_PROTOCOL || 'https'
export const riffraffPort = process.env.REACT_APP_RIFFRAFF_PORT || '443'
export const higginsDomain = process.env.REACT_APP_HIGGINS_DOMAIN || '*'
export const luciusDomain = process.env.REACT_APP_LUCIUS_DOMAIN || 'admin.theemployeeapp.com'
export const whiteListDomains = process.env.REACT_APP_WHITELIST_DOMAINS || 'localhost'
export const developerSubdomain = process.env.REACT_APP_DEV_SUBDOMAIN
export const winterGreenURL = process.env.REACT_APP_WINTERGREEN_URL || 'http://wintergreen.theemployeeapp.com:8080'
export const defaultRecaptchaId = process.env.REACT_APP_DEFAULT_RECAPTCHA_KEY
export const invisibleRecaptchaId = process.env.REACT_APP_INVISIBLE_RECAPTCHA_KEY
export const luciusSubdomain = process.env.REACT_APP_LUCIUS_SUBDOMAIN || 'admin'
export const colemanHost = process.env.REACT_APP_COLEMAN_HOST || 'graphql.beta.theemployeeapp.com'
export const colemanProtocol = process.env.REACT_APP_COLEMAN_PROTOCOL || 'https'
export const colemanPort = process.env.REACT_APP_COLEMAN_PORT || '443'
export const genericSubdomain = process.env.REACT_APP_GENERIC_SUBDOMAIN || 'generic.beta'
export const blackList = process.env.REACT_APP_BLACKLIST || 'apprise,employeeapp,theemployeeapp'
export const iframeWhitelist = process.env.REACT_APP_IFRAME_WHITELIST || []
export const googleAnalyticsValue = process.env.REACT_APP_GA
